import axios, { AxiosResponse } from "axios";
import { RoleAssignableRolesDto } from "src/dtos/HumanResources/Role.dto";
import { GetAuthorisation } from "src/Utils/authUtils";

const apiBaseUrl = process.env.REACT_APP_API_BASE_URL + "/RoleAssignment";
const debug = process.env.REACT_APP_DEBUG;

export default class RoleAssignmentService {
    static async GetAssignments(): Promise<RoleAssignableRolesDto[]> {
        try {
            const authorization = await GetAuthorisation();
            const response: AxiosResponse<RoleAssignableRolesDto[]> = await axios.get<RoleAssignableRolesDto[]>(`${apiBaseUrl}/GetAssignments`, {
                headers: {
                    Authorization: authorization
                },
            });
            return response.data
        } catch (error: any) {
            debug && console.log(error)
            throw error;
        }
    }

    static async UpdateAssignments(data: RoleAssignableRolesDto[]): Promise<AxiosResponse> {
        try {
            const authorization = await GetAuthorisation();
            const response: AxiosResponse = await axios.put<AxiosResponse>(`${apiBaseUrl}/UpdateAssignments`, data, {
                headers: {
                    Authorization: authorization
                }
            });
            return response;
        } catch (error: any) {
            debug && console.log(error)
            throw error;
        }
    }
}