import { TabContext, TabList, TabPanel } from '@mui/lab'
import { Box, Tab, Typography } from '@mui/material'
import React from 'react'
import { useSearchParams } from 'react-router-dom';
import { MessageItem } from 'src/components/errorHandlingSnackbar';
import { FormikProps } from 'formik';
import TeamMemberCompletedTraining from './Components/TeamMemberCompletedTraining';
import AchievedCompetencies from './Components/AchievedCompetencies';
import { TeamMember } from 'src/dtos/HumanResources/TeamMember/TeamMember.dto';
import TrainingFacilitated from './Components/TrainingFacilitated';

type Props = {
    formik: FormikProps<TeamMember>
    setMessageItem: React.Dispatch<React.SetStateAction<MessageItem>>
    loading: boolean;
}
function TmTraining({formik, setMessageItem, loading}: Props) {
    const [searchParams, setSearchParams] = useSearchParams()
    const defaultTab = "CompletedTraining"
    const [openTab, setOpenTab] = React.useState(searchParams.get("nestedTab") ?? defaultTab);
    

    const handleTabChange = (event: React.SyntheticEvent, tabValue: string) => {
        setOpenTab(tabValue);
        if(tabValue === defaultTab){
            searchParams.delete("nestedTab")
        }else {
            searchParams.set("nestedTab", tabValue)
        }
        setSearchParams(searchParams)
    };

    return (
        <>
            <TabContext value={openTab}>
                <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                    <TabList onChange={handleTabChange}>
                        <Tab label="Completed Training" value="CompletedTraining"></Tab>
                        <Tab label="Achieved Competencies" value="AchievedCompetencies"></Tab>
                        <Tab label="Training Facilitated" value="TrainingFacilitated"></Tab>
                    </TabList>
                </Box>
                    <TabPanel value="CompletedTraining">
                        <Typography variant='h5'>Completed Training</Typography>
                        <TeamMemberCompletedTraining
                            id={formik.values.id}
                            setMessageItem={setMessageItem}
                        />
                    </TabPanel>
                    <TabPanel value="AchievedCompetencies">
                        <Typography variant='h5'>Achieved Competencies</Typography>
                        <AchievedCompetencies
                            id={formik.values.id}
                            setMessageItem={setMessageItem}
                        />
                    </TabPanel>
                    <TabPanel value="TrainingFacilitated">
                        <Typography variant='h5'>Training Facilitated</Typography>
                        <TrainingFacilitated
                            id={formik.values.id}
                            setMessageItem={setMessageItem}
                        />
                    </TabPanel>
            </TabContext>
        </>
    )
}

export default TmTraining