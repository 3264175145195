import { Box, Divider, InputBase, Paper, Skeleton, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import ErrorHandlingSnackbar, { MessageItem } from 'src/components/errorHandlingSnackbar'
import { TrainingMemoAcceptanceDto } from 'src/dtos/Training/TrainingMemo.dto'
import TrainingMemoService from 'src/Services/Training/TrainingMemoService'
import { guidRegex } from 'src/Utils/helperFunc'
import AcceptanceRow from './Components/AcceptanceRow'
import TrainingMemoMemorandum from './Components/TrainingMemoMemorandum'
import textContent from 'src/config/textContent'
import SearchIcon from '@mui/icons-material/Search';


function TrainingMemo() {
    const [messageItem, setMessageItem] = useState<MessageItem>({})
    const [acceptances, setAcceptances] = useState<TrainingMemoAcceptanceDto[]>([])
    const [searchTerm, setSearchTerm] = useState("")

    const {id} = useParams();
    const queryClient = useQueryClient(); 

    //#region queries
    const trainingMemoQuery = useQuery({
        queryKey: ["TrainingMemo.Get", id],
        queryFn: async () => {
            if(id !== undefined && guidRegex.test(id))
                return await TrainingMemoService.Get(id)
            return TrainingMemoService.GetDefaultValues()
        },
        initialData: TrainingMemoService.GetDefaultValues()
    })
    useEffect(() => {   
        if(trainingMemoQuery.data){
            setAcceptances(trainingMemoQuery.data.acceptances)
        }
    }, [trainingMemoQuery.data])

    useEffect(() => {
        if(trainingMemoQuery.isError){
            setMessageItem({error: trainingMemoQuery.error})
        }
        
    }, [trainingMemoQuery.error, trainingMemoQuery.isError])

    //#endregion

    const updateAcceptance = (index: number, newAcceptance: TrainingMemoAcceptanceDto) => {
        const updatedAcceptances = [...acceptances]
            
        updatedAcceptances[index] = newAcceptance

        setAcceptances(updatedAcceptances)
        queryClient.setQueryData(["TrainingMemo.Get", id], {...trainingMemoQuery.data, acceptances: updatedAcceptances})
        
    }
    
    if(trainingMemoQuery.isLoading || trainingMemoQuery.data == null){
        return (
            <Stack sx={{padding:"1rem"}}>
                <Skeleton animation="wave" sx={{width: "80%", display: "flex", height: "4rem"}}/>
                <div>
                    <Skeleton animation="wave" sx={{width:"20rem", height:"2.5rem"}}/>
                    <Skeleton animation="wave" sx={{width:"25rem", height:"2rem"}}/>
                </div>
                <div>
                    <Skeleton animation="wave" sx={{width:"20rem", height:"2.5rem"}}/>
                    <Skeleton animation="wave" sx={{width:"25rem", height:"2rem"}}/>
                </div>
                <div>
                    <Skeleton animation="wave" sx={{width:"20rem", height:"2.5rem"}}/>
                    <Skeleton animation="wave" sx={{width:"25rem", height:"2rem"}}/>
                </div>
                <div>
                    <Skeleton animation="wave" sx={{width:"20rem", height:"2.5rem"}}/>
                    <Skeleton animation="wave" sx={{width:"25rem", height:"15rem"}}/>
                </div>
            </Stack>
        )
    }
    const trainingMemo = trainingMemoQuery.data

    if(trainingMemo.dateIssued == null){
        return (
            <Typography>Training Memo has not been issued!</Typography>
        )
    }
    return (
        <>
            <Box sx={{padding: "1rem"}}>
                <TrainingMemoMemorandum
                    trainingMemo={trainingMemo}
                />
                <Divider sx={{borderBottomWidth: 3, borderColor:"black", marginTop:"1rem", marginBottom:"1rem" }}/>
                <Typography>
                    {textContent.trainingMemo.acceptanceClause}
                </Typography>
                <Paper
                    component="form"
                    sx={{p: '2px 4px', marginBottom:"1rem", marginTop:"1rem", display: 'flex', alignItems: 'center', width:"100%" }}
                >
                    <SearchIcon color="disabled"/>
                    <InputBase
                        sx={{ml: 1, flex: 1}}
                        placeholder='Search'
                        value={searchTerm}
                        onChange={e => setSearchTerm(e.target.value)}
                    />
    
                </Paper>
                <TableContainer component={Paper}>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    Team Member
                                </TableCell>
                                <TableCell>
                                    Signature
                                </TableCell>
                                <TableCell>
                                    Time Signed
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {id && acceptances.filter(a => a.teamMember.label.toLocaleLowerCase().includes(searchTerm.toLowerCase())).map((acceptance, index) => (
                                <AcceptanceRow
                                    key={index}
                                    id={id}
                                    acceptance={acceptance}
                                    updateAcceptance={(newAcceptance: TrainingMemoAcceptanceDto) => updateAcceptance(index, newAcceptance)}
                                />
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
            <ErrorHandlingSnackbar messageItem={messageItem}/>
        </>
    )
}

export default TrainingMemo