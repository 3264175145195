import { TableRow, TableCell, Stack } from '@mui/material';
import React from 'react'
import { dateTimeOptions } from 'src/config/constants';
import { LearningDocumentPoint } from 'src/dtos/Training/LearningDocument.dto';
import { LearningDocumentPointResponse } from 'src/dtos/Training/LearningDocumentResponse.dto';
import { PointIcon } from 'src/pages/User Site/TrainingDocuments/TrainingDocumentComponents/Point/Components/PointIcon';
import { EmailToNameString } from 'src/Utils/helperFunc';

type Props = {
    index: number;
    point: LearningDocumentPoint;
    pointResponse: LearningDocumentPointResponse;
    allIconlessPoints?: boolean;
}
function TallyCounterPoint({index, point, pointResponse, allIconlessPoints}:Props) {
    return (
        <TableRow>
            <TableCell>
                <Stack direction={"row"} sx={{display:"flex", alignItems:"center"}}>
                    {allIconlessPoints !== true && (
                        <PointIcon pointType={point.type}/>
                    )}
                    <div>
                        {`${index+1}. ${point.label}`} <br/>
                        <div style={{marginLeft:"1rem"}}>
                            <strong>Required Tally: </strong>{point.tallyNumber ?? 0} <br/>
                            <strong>Actual Tally: </strong>{pointResponse.tallyValue ?? 0}
                        </div>    
                    </div>
                </Stack>

            </TableCell>
            <TableCell>
                {pointResponse.checked ? "Yes" : "No"}
            </TableCell>
            <TableCell>
                {EmailToNameString(pointResponse.answeredBy)}
            </TableCell>
            <TableCell>
                {pointResponse.timeAnswered && new Date(pointResponse.timeAnswered).toLocaleDateString(undefined, dateTimeOptions)}
            </TableCell>
        </TableRow>    
    )
}

export default TallyCounterPoint