import { ListItem, ListItemIcon, ButtonBase, Checkbox, Table, TableHead, TableRow, TableCell, TableBody, Stack, Typography } from "@mui/material";
import { FormikProps } from "formik";
import { LearningDocumentPoint, LearningDocumentPointType } from "src/dtos/Training/LearningDocument.dto";
import { LearningDocumentPointResponse, LearningDocumentResponseDto } from "src/dtos/Training/LearningDocumentResponse.dto";
import { createUnorderedListMarkup } from "./CreateUnorderedListMarkup";
import { PointIcon } from "./PointIcon";
import React from 'react'
import { GetCurrentUser } from "src/Utils/authUtils";
import { EmailToNameString } from "src/Utils/helperFunc";
import { dateTimeOptions } from "src/config/constants";


type BaseProps = {
    index: number;
    point: LearningDocumentPoint
    allIconlessPoints?: boolean
    SaveStatusIcon?: React.ReactNode;
    showHeader: Boolean
}

type DisplayProps = BaseProps & {
    type: "DisplayOnly"
}

type ActiveProps = BaseProps & {
    type: "Active"
    pointResponse: LearningDocumentPointResponse;
    formikString: string;
    formik: FormikProps<LearningDocumentResponseDto>;
    pointLocked: boolean;
    SaveStatusIcon: React.ReactNode;
    updatePoint: (newValues: LearningDocumentPointResponse) => void;
}

type Props = DisplayProps | ActiveProps;
function TroubleShootingPoint (props: Props) {
   
    const {point, showHeader, allIconlessPoints, SaveStatusIcon} = props

    const pointDisabled = props.type === "Active" && props.pointResponse.answeredById ? !(props.pointResponse.answeredById === GetCurrentUser().id) && props.pointResponse.checked === true : false

    const handlePointClick = () => {
        if(props.type === "Active"){
            const {pointResponse, pointLocked, updatePoint} = props

            if (pointLocked || pointDisabled){
                return
            }
            updatePoint({...pointResponse, checked: !pointResponse.checked, timeAnswered: new Date()})
        }    
    }

    return (
        <>
            <ListItem
                secondaryAction={
                    <Stack spacing={1} direction="row" sx={{alignItems:"center"}}>
                        {pointDisabled && (
                            <Stack spacing={1} sx={{textAlign:"center"}}>
                                <Typography variant="caption">{props.type === "Active" && props.pointResponse.answeredBy && EmailToNameString(props.pointResponse.answeredBy)}</Typography>
                                <Typography variant="caption">{props.type === "Active" && props.pointResponse.timeAnswered && new Date(props.pointResponse.timeAnswered).toLocaleDateString(undefined, dateTimeOptions)}</Typography>
                            </Stack>
                        )}
                        {SaveStatusIcon}
                    </Stack>

                }
            >
                {allIconlessPoints !== true && (
                    <ListItemIcon>
                        <PointIcon pointType={LearningDocumentPointType.troubleshooting}/>
                    </ListItemIcon>
                )}
            <ButtonBase className='pointHover' sx={{width: "100%", left:"-1rem", paddingLeft:"1rem"}} onClick={handlePointClick}>
                <ListItemIcon>
                    <Checkbox
                        disabled={pointDisabled}
                        edge="start"
                        checked={props.type === "Active" ? props.pointResponse.checked : false}
                        tabIndex={-1}
                        disableRipple
                        />
                </ListItemIcon>
                <Table padding="none">
                    {showHeader && (
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{borderRight:"1px solid #e0e0e0", width: "25%"}}> 
                                    <span style={{paddingLeft:"1rem", userSelect: "none"}}>Issue</span>
                                </TableCell>
                                <TableCell>
                                    <span style={{paddingLeft:"1rem", userSelect: "none"}}>Solution</span>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                    )}
                    <TableBody>
                        <TableRow>
                            <TableCell sx={{borderRight:"1px solid #e0e0e0", width: "25%"}}>
                                <div style={{userSelect: "none"}}>{`${props.index+1}. ${point.label}`}</div>
                            </TableCell>
                            <TableCell>
                                <div style={{userSelect: "none"}} dangerouslySetInnerHTML={createUnorderedListMarkup(point.unorderedList)}/>
                            </TableCell>
                        </TableRow>

                    </TableBody>
                </Table>

            </ButtonBase>
            </ListItem>
        </>
    )
}

export default TroubleShootingPoint