import axios, { AxiosResponse } from "axios";
import { ListResponseDto } from "src/dtos/ListResponse.dto";
import { TrainingMemoAcceptanceDto, TrainingMemoDto, TrainingMemoType } from "src/dtos/Training/TrainingMemo.dto";
import { GetAuthorisation } from "src/Utils/authUtils";
import { ListParameters, SortDirection } from "../ListParameters";
import { NameDto } from "src/dtos/NameDto.dto";
import { FileMetadata } from "src/dtos/FileMetadata.dto";

const apiBaseUrl = process.env.REACT_APP_API_BASE_URL + "/TrainingMemo";
const debug = process.env.REACT_APP_DEBUG;

const emptyData: ListResponseDto = {
    data: [],
    totalRecords: 0
}

export default class TrainingMemoService {
    static GetDefaultValues() {
        const defaultVals: TrainingMemoDto = {
            id: "00000000-0000-0000-0000-000000000000",
            label: "",
            type: TrainingMemoType.ProceduralChange,
            changeDetail: "",
            affectedTrainingGroups: [],
            acceptances: [],
            totalSignatures: 0,
            totalTeamMembers: 0,
            enabled: true
        }
        return defaultVals;
    }

    static async Get(id: string): Promise<TrainingMemoDto> {
        try {
            const authorization = await GetAuthorisation();
            const response: AxiosResponse<TrainingMemoDto> = await axios.get<TrainingMemoDto>(`${apiBaseUrl}/Get/${id}`, {
                headers: {
                    Authorization: authorization
                }
            });
            return response.data;
        } catch (error: any) {
            debug && console.log(error)
            throw error;
        }
    }

    static async GetList(listParam?: ListParameters, searchTerm?: string, fromDate?: Date, toDate?: Date): Promise<ListResponseDto> {
        try {
            const authorization = await GetAuthorisation();

            const response: AxiosResponse<ListResponseDto> = await axios.get<ListResponseDto>(`${apiBaseUrl}/GetList`, {
                headers: {
                    Authorization: authorization
                },
                params: {
                    page: listParam?.page,
                    pageSize: listParam?.pageSize,
                    sortBy : listParam?.sortBy,
                    sortDirection: listParam?.sortDirection === SortDirection.Ascending ? 0 : listParam?.sortDirection === SortDirection.Descending ? 1 : undefined,
                    searchTerm: searchTerm,
                    fromDate: fromDate,
                    toDate: toDate
                }
            });
            return response.data;
        } catch(error: any) {
            if(error.response !== undefined) {
                if(error.response.status != null && error.response.status === 404) {
                    return emptyData
                }
            }
            debug && console.log(error)
            throw error
        }
    }

    static async GetOutstanding(listParam?: ListParameters, searchTerm?: string): Promise<ListResponseDto> {
        try {
            const authorization = await GetAuthorisation();

            const response: AxiosResponse<ListResponseDto> = await axios.get<ListResponseDto>(`${apiBaseUrl}/GetOutstanding`, {
                headers: {
                    Authorization: authorization
                },
                params: {
                    page: listParam?.page,
                    pageSize: listParam?.pageSize,
                    sortBy : listParam?.sortBy,
                    sortDirection: listParam?.sortDirection === SortDirection.Ascending ? 0 : listParam?.sortDirection === SortDirection.Descending ? 1 : undefined,
                    searchTerm: searchTerm
                }
            });
            return response.data;
        } catch(error: any) {
            if(error.response !== undefined) {
                if(error.response.status != null && error.response.status === 404) {
                    return emptyData
                }
            }
            debug && console.log(error)
            throw error
        }
    }


    static async Create(data: TrainingMemoDto): Promise<AxiosResponse> {
        try {
            const authorization = await GetAuthorisation();
            const response: AxiosResponse = await axios.post<AxiosResponse>(`${apiBaseUrl}/Create`,data,{
                headers: {
                    Authorization: authorization
                }
            });
            return response
        } catch(error: any) {
            debug && console.log(error)
            throw error;
        }
    }
    
    static async Update(id: string, data: TrainingMemoDto): Promise<AxiosResponse> {
        try {
            const authorization = await GetAuthorisation();
            const response: AxiosResponse = await axios.put<AxiosResponse>(`${apiBaseUrl}/Update/${id}`, data, {
                headers: {
                    Authorization: authorization
                }
            });
            return response;
        } catch (error: any) {
            debug && console.log(error)
            throw error;
        }
    }

    static async UpdateTrainingGroups(id: string, trainingGroupParents: NameDto[]): Promise<AxiosResponse<TrainingMemoAcceptanceDto[]>> {
        try {
            const authorization = await GetAuthorisation();
            const response: AxiosResponse = await axios.put<AxiosResponse>(`${apiBaseUrl}/UpdateTrainingGroups/${id}`, trainingGroupParents, {
                headers: {
                    Authorization: authorization
                }
            });
            return response;
        } catch (error: any) {
            debug && console.log(error)
            throw error;
        }
    }

    static async AddSOPReference(trainingMemoId: string, file: File): Promise<AxiosResponse<FileMetadata>> {
        try {
            const authorization = await GetAuthorisation();

            const formData = new FormData();
            formData.append("file", file);

            const response: AxiosResponse = await axios.post<AxiosResponse>(`${apiBaseUrl}/AddSOPReference/${trainingMemoId}`,formData,{
                headers: {
                    Authorization: authorization
                }
            });
            return response
        } catch(error: any) {
            debug && console.log(error)
            throw error;
        }
    }

    static async DeleteSOPReference(trainingMemoId: string, fileId: string): Promise<AxiosResponse> {
        try {
            const authorization = await GetAuthorisation();

            const response: AxiosResponse = await axios.delete<AxiosResponse>(`${apiBaseUrl}/DeleteSOPReference/${trainingMemoId}`,{
                headers: {
                    Authorization: authorization
                }, 
                params: {
                    fileId: fileId
                }
            });
            return response
        } catch(error: any) {
            debug && console.log(error)
            throw error;
        }
    }

    static async Issue(id: string): Promise<AxiosResponse<TrainingMemoDto>> {
        try {
            const authorization = await GetAuthorisation();
            const response: AxiosResponse = await axios.put<AxiosResponse>(`${apiBaseUrl}/Issue/${id}`, null, {
                headers: {
                    Authorization: authorization
                }
            });
            return response;
        } catch (error: any) {
            debug && console.log(error)
            throw error;
        }
    }

    static async UpdateAcceptance(id: string, updateAcceptance: TrainingMemoAcceptanceDto): Promise<AxiosResponse<TrainingMemoAcceptanceDto>> {
        try {
            const authorization = await GetAuthorisation();
            const response: AxiosResponse = await axios.put<AxiosResponse>(`${apiBaseUrl}/UpdateAcceptance/${id}`, updateAcceptance, {
                headers: {
                    Authorization: authorization
                }
            });
            return response;
        } catch (error: any) {
            debug && console.log(error)
            throw error;
        }
    }
}