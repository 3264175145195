import { BaseModel } from "../BaseModel.dto";
import { Expiry } from "../Checklists/Expiry.dto";
import { ChecklistVersion } from "../Checklists/MaintenanceChecklist.dto";
import { FileMetadata } from "../FileMetadata.dto";
import { NameDto } from "../NameDto.dto";
export enum LearningDocumentType {
    trainingDocumentNTM,
    trainingDocumentRTM,
    competencyDocument,
    orientation
}

export const LearningDocumentTypeString: string[] = [
    "New Team Member Training Document",
    "Return Team Member Training Document",
    "Competency Document",
    "Orientation"
]

export interface LearningDocument extends BaseModel {
    id: string;
    label: string;
    description?: string;
    expiry: Expiry;
    departmentId?: string;
    department?: NameDto | null;
    publishedVersionId?: string;
    type: LearningDocumentType;
    checklistVersions?: LearningDocumentVersion[];
}

export interface LearningDocumentVersion extends ChecklistVersion {
    initialSectionContent?: string;
    preparationChecklist?: LearningDocumentSubtopic;
    topics: LearningDocumentTopic[];

    learningDocumentId: string;
}

export interface LearningDocumentTopic {
    id: number;
    label: string;
    estimateCompletionMinutes?: number | "";
    sortOrder: number;
    previousTopicId?: number;
    previousTopic?: LearningDocumentTopic;

    subtopics: LearningDocumentSubtopic[];

    learningDocumentVersionId?: string;
}

export interface LearningDocumentSubtopic {
    id: number;
    label: string;
    sortOrder: number;
    previousSubtopicId?: number;
    previousSubtopic?: LearningDocumentSubtopic;

    points: LearningDocumentPoint[];

    learningDocumentTopicId?: number;
}

export enum LearningDocumentPointType { 
    standard,
    question,
    trainerAction,
    traineeDemo,
    previousIncident,
    tallyCounter,
    video,
    troubleshooting,
    image
}

export const PointTypeString : string[] =[
    "Standard",
    "Question",
    "Trainer Action",
    "Trainee Demo",
    "Previous Incident",
    "Tally Counter",
    "Video",
    "Troubleshooting",
    "Image"
]


export interface LearningDocumentPoint {
    id: number;
    label: string;
    sortOrder: number;
    type: LearningDocumentPointType;
    tallyNumber?: number; //Used for minimum tally number
    embeddedIFrame?: string; //Used for embedded videos and images
    file?: FileMetadata;
    unorderedList?: string; //used for examples and troubleshooting solutions
    previousPointId?: number;
    previousPoint?: LearningDocumentPoint;

    subpoints: LearningDocumentSubpoint[];

    learningDocumentSubtopicId?: number;
}

export interface LearningDocumentSubpoint {
    id: number;
    label: string;
    sortOrder: number;
    previousSubpointId?: number;
    previousSubpoint?: LearningDocumentSubpoint;

    learningDocumentPointId?: number;
}