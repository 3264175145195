import axios, { AxiosResponse } from "axios";
import { GetAuthorisation } from "src/Utils/authUtils";
import { Role } from "src/dtos/HumanResources/Role.dto";
import { NameDto } from "src/dtos/NameDto.dto";

const apiBaseUrl = process.env.REACT_APP_API_BASE_URL + "/Role";
const debug = process.env.REACT_APP_DEBUG;

export default class RoleService {
    static async GetNamesOLD(): Promise<Role[]>{
        try {
            const authorization = await GetAuthorisation();
            const response: AxiosResponse<Role[]> = await axios.get<Role[]>(`${apiBaseUrl}/GetNames`, {
                headers: {
                    Authorization: authorization
                },
            });
            return response.data
        } catch (error: any) {
            debug && console.log(error)
            throw error;
        }
    }
    
    static async GetNames(): Promise<NameDto[]>{
        try {
            const authorization = await GetAuthorisation();
            const response: AxiosResponse<NameDto[]> = await axios.get<NameDto[]>(`${apiBaseUrl}/GetNames`, {
                headers: {
                    Authorization: authorization
                },
            });
            return response.data
        } catch (error: any) {
            debug && console.log(error)
            throw error;
        }
    }

}