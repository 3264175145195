import { ChipColor } from "src/config/constants";
import { InspectionChecklistSection, QuestionDto, QuestionType, SectionRepeatType } from "src/dtos/AwTypes";
import { InspectedComponentDto, InspectedComponentStatus, InspectionChecklistQuestionResponseDto, InspectionChecklistResponseDto, InspectionChecklistSectionResponseDto } from "src/dtos/Checklists/AttractionInspections/ChecklistResponse.dto";
import { NameNumberDto } from "src/dtos/NameDto.dto";
import { Status } from "src/dtos/Statuses";


export function GetSectionVisible(section: InspectionChecklistSection, selectedComponents: NameNumberDto[]) : boolean {
    let sectionVisible = true
    if(section == null)
        return sectionVisible
    if(section.sectionRepeatType === SectionRepeatType.UserSelected && section.repeatingComponentSet){
        sectionVisible = selectedComponents.some(e => e.id === section.repeatingComponentSet?.id);
    } 

    return sectionVisible
}

export function GetSectionStatusColour(sectionStatus: Status): ChipColor {

    if (sectionStatus === Status.Passed)
        return "success";
    if (sectionStatus === Status.Inprogress)
        return "primary";
    if (sectionStatus === Status.Unanswered)
        return "primary";
    if (sectionStatus === Status.AwaitingJournalEntry)
        return "secondary"
    if (sectionStatus === Status.Failed)
        return "error"
    if (sectionStatus === Status.ComponentFailed)
        return "warning"

    return "default"

}

export function GetQuestionVisible(question: QuestionDto, selectedComponents: NameNumberDto[]) : boolean {
    if(question.repeatOnComponentSetUserSelected === true && question.relatedComponent && question.relatedComponent.children){
        return selectedComponents.some(sc => sc.id === question.relatedComponent?.id);
    } else {
        return true;
    }
}

export function GetQuestionStatus(questionResponse: InspectionChecklistQuestionResponseDto, questionContent: QuestionDto, inspectedComponents: InspectedComponentDto[], selectedComponents: NameNumberDto[]) {
    
    //Handle Alert type question
    if(questionContent == null || questionContent.type === QuestionType.Alert){
        return Status.NA
    }

    //Handle YesOrNo Type question
    if(questionContent.type === QuestionType.YesOrNo){
        if(questionResponse.answer === "Yes")
            return Status.Passed
        
        if(questionResponse.answer === "No"){
            if(questionResponse.impactsOperation == null)
                return Status.Inprogress
            if(questionResponse.relatedJournalEntryId == null)
                return Status.AwaitingJournalEntry

            if(questionResponse.impactsOperation === false)
                return Status.Passed
            
            if(questionContent.relatedComponent && questionContent.relatedComponent.requiresTagging)
                return Status.ComponentFailed

            return Status.Failed
        }

        if(questionContent.relatedComponentId && inspectedComponents.some(ic => ic.status === InspectedComponentStatus.failed && ic.component.id === questionContent.relatedComponentId)) {
            return Status.ComponentFailed
        }

        if(questionContent.repeatOnComponentSetUserSelected === true
            && questionContent.relatedComponentId
            && !selectedComponents.some(sc => sc.id === questionContent.relatedComponentId)
        ){
            return Status.NotInspected
        }

        return Status.Unanswered
    }

    //Handle inputNumber type question
    if(questionContent.type === QuestionType.Number){

        if(questionResponse.answer != null){
            if(!isNaN(parseFloat(questionResponse.answer)) && 
                questionContent.minAcceptableValue &&
                questionContent.maxAcceptableValue &&
                parseFloat(questionResponse.answer) >= questionContent.minAcceptableValue &&
                parseFloat(questionResponse.answer) <= questionContent.maxAcceptableValue
            )
                return Status.Passed

            if(questionResponse.impactsOperation == null)
                return Status.Inprogress
            if(questionResponse.relatedJournalEntryId == null)
                return Status.AwaitingJournalEntry
            if(questionResponse.impactsOperation === false)
                return Status.Passed

            if(questionContent.relatedComponent && questionContent.relatedComponent.requiresTagging)
                return Status.ComponentFailed

            return Status.Failed
        }
        
        if(questionContent.relatedComponentId && inspectedComponents.some(ic => ic.status === InspectedComponentStatus.failed && ic.component.id === questionContent.relatedComponentId)) {
            return Status.ComponentFailed
        }

        if(questionContent.repeatOnComponentSetUserSelected === true
            && questionContent.relatedComponentId
            && !selectedComponents.some(sc => sc.id === questionContent.relatedComponentId)
        ){
            return Status.NotInspected
        }
        return Status.Unanswered
    }

    return Status.NA
}

export function GetSectionStatus(sectionResponse: InspectionChecklistSectionResponseDto, sectionContent: InspectionChecklistSection, inspectedComponents: InspectedComponentDto[], selectedComponents: NameNumberDto[] ) {
    const questionStatuses = sectionResponse.questionResponses.map((qr, index) => GetQuestionStatus(qr, sectionContent.questions[index], inspectedComponents, selectedComponents));
    const totalQuestions = questionStatuses.filter(qs => qs !== Status.NA && qs !== Status.NotInspected).length;
    const passedQuestions = questionStatuses.filter(qs => qs === Status.Passed).length;
    const unansweredQuestions = questionStatuses.filter(qs => qs === Status.Unanswered).length;
    const hasComponentFailedQuestions = questionStatuses.some(qs => qs === Status.ComponentFailed);
    const isSectionFailed = questionStatuses.some(qs => qs === Status.Failed);
    const hasAwaitingJournalEntry = questionStatuses.some(qs => qs === Status.AwaitingJournalEntry);

    if (sectionContent.sectionRepeatType === SectionRepeatType.UserSelected
        && sectionContent.repeatingComponentSet 
        && !selectedComponents.some(sc => sc.id === sectionContent.repeatingComponentSet?.id)
    ){
        return Status.NotInspected
    }    

    if(unansweredQuestions === totalQuestions && totalQuestions !== 0)
        return Status.Unanswered

    if(isSectionFailed)
        return Status.Failed

    if(hasAwaitingJournalEntry)
        return Status.AwaitingJournalEntry

    if(hasComponentFailedQuestions)
        return Status.ComponentFailed

    if(sectionContent.sectionRepeatType === SectionRepeatType.Grouped && sectionResponse.groupedSectionComponentResponses){
        //requires a journal entry if all the questions are answered and a component is not passed is missing a journal entry
        const requireJournalEntry = sectionResponse.groupedSectionComponentResponses.some(gscr => gscr.passedAllQuestions !== true && gscr.journalEntryId == null)
                                    && passedQuestions === totalQuestions
        const totalPassedComponents = sectionResponse.groupedSectionComponentResponses.filter(gscr => gscr.passedAllQuestions === true).length
        
        if(sectionResponse.groupedPassedComponentsQty !== totalPassedComponents)
            return Status.Inprogress

        if(requireJournalEntry)
            return Status.AwaitingJournalEntry
    }

    if(passedQuestions === totalQuestions)
        return Status.Passed

    return Status.Inprogress
}

export function GetInspectionStatus(inspectionResponse: InspectionChecklistResponseDto, sectionContents: InspectionChecklistSection[]) {
    const sectionStatuses = inspectionResponse.sectionResponses.map((sr, index) => GetSectionStatus(sr, sectionContents[index], inspectionResponse.inspectedComponents, inspectionResponse.selectedComponents ?? []))

    const completedStatuses = [Status.Passed, Status.Failed]

    if(completedStatuses.includes(inspectionResponse.status))
        return inspectionResponse.status

    const totalSections = sectionStatuses.filter(ss => ss !== Status.NotInspected).length;
    const passedSections = sectionStatuses.filter(ss => ss === Status.Passed).length;
    const unansweredSections = sectionStatuses.filter(ss => ss === Status.Unanswered).length;
    const componentFailedSections = sectionStatuses.filter(ss => ss === Status.ComponentFailed).length;
    const isInspectionFailed = sectionStatuses.some(ss => ss === Status.Failed);
    const hasAwaitingJournalEntry = sectionStatuses.some(ss => ss === Status.AwaitingJournalEntry);
    
    if(unansweredSections === totalSections)
        return Status.Unanswered

    if(hasAwaitingJournalEntry)
        return Status.AwaitingJournalEntry

    if((passedSections + componentFailedSections) === totalSections && inspectionResponse.completedTime == null)
        return Status.AwaitingSubmission //passed but not submitted

    if(isInspectionFailed && inspectionResponse.completedTime == null)
        return Status.AwaitingSubmission //Failed but not submitted
    
    return Status.Inprogress

}
