import { LoadingButton } from '@mui/lab';
import { Accordion, AccordionDetails, AccordionSummary, Autocomplete, Checkbox, Divider, FormControlLabel, Stack, TextField, Typography } from '@mui/material'
import React from 'react'
import QuestionTitle from 'src/components/QuestionTitle/QuestionTitle';
import { InspectionChecklistSection } from 'src/dtos/AwTypes';
import { InspectionChecklistResponseDto } from 'src/dtos/Checklists/AttractionInspections/ChecklistResponse.dto';
import { Status } from 'src/dtos/Statuses';
import { GetInspectionStatus, GetSectionStatus } from '../CalculateStatusesHelper';
import { useMutation, useQuery } from '@tanstack/react-query';
import InspectionChecklistResponseService from 'src/Services/Checklists/Inspections/ChecklistResponseService';
import { FormikProps } from 'formik';
import { NameDto } from 'src/dtos/NameDto.dto';

type Props = {
    formik: FormikProps<InspectionChecklistResponseDto>
    inspectionResponse: InspectionChecklistResponseDto
    sectionContents: InspectionChecklistSection[]
    checklistName: string;
    submitInspection?: () => void;
    loading: boolean;
    demoMode?: boolean;
}
function FinalSignOff({formik, checklistName, inspectionResponse, sectionContents, submitInspection, demoMode, loading}: Props) {
    const sectionStatuses = inspectionResponse.sectionResponses.map((sr, index) => GetSectionStatus(sr, sectionContents[index], inspectionResponse.inspectedComponents, inspectionResponse.selectedComponents ?? []))
    const totalSections = sectionStatuses.filter(ss => ss !== Status.NotInspected).length;
    const passedSections = sectionStatuses.filter(ss => ss === Status.Passed).length;
    const componentFailedSections = sectionStatuses.filter(ss => ss === Status.ComponentFailed).length;

    let submitAsStatus = Status.Failed

    if((passedSections + componentFailedSections) === totalSections)
        submitAsStatus = Status.Passed

    const checklistStatus = GetInspectionStatus(inspectionResponse, sectionContents)

    const possibleTraineesQuery = useQuery({
        queryKey: ["GetPossibleTrainees"],
        queryFn: async () => {
            return await InspectionChecklistResponseService.GetPossibleTrainees();
        }
    })

    const updateTraineeMutation = useMutation({
        mutationKey: ["UpdateTrainee", inspectionResponse.id],
        mutationFn: async (trainee: NameDto | null) => {
            return await InspectionChecklistResponseService.UpdateTrainee(inspectionResponse.id, trainee);
        }
    })

    const handleTraineeUpdate = (trainee: NameDto | null) => {
        formik.setFieldValue("trainee", trainee)

        if(!demoMode)
            updateTraineeMutation.mutate(trainee)
    }

    const handleSubmitClick = () => {
        
        //submit as failed/passed
        if(submitInspection){
            submitInspection();
        }
    }

    return (
    <Accordion>
        <AccordionSummary>
            <Typography variant='h5'>Final Sign Off</Typography>
        </AccordionSummary>
        <Divider/>
        <AccordionDetails>
            <Stack>
                <QuestionTitle title="Who did you train during this inspection?"/>
                <Autocomplete
                    id="trainee"
                    autoHighlight
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    getOptionLabel={(option) => (option.label ? option.label : "")}
                    options={possibleTraineesQuery.data ?? []}
                    value={inspectionResponse.trainee}
                    onChange={(_, value) => {
                        handleTraineeUpdate(value)
                    }}
                    sx={{width:"25rem"}}
                    size="small"
                    renderInput={(params) => 
                        <TextField {...params}/>
                    }
                />
                <QuestionTitle
                    title={`I certify that this checklist, ${checklistName}, has been answered to the best of my knowledge and abilities.`}
                    required
                />
                <FormControlLabel control={<Checkbox/>} label="I agree" />

                {checklistStatus !== Status.AwaitingSubmission && checklistStatus !== Status.Passed && checklistStatus !== Status.Failed && (
                    <Typography>This inspection is incomplete and therefore can only be submitted as failed.</Typography>
                )}
                <LoadingButton onClick={handleSubmitClick} loading={loading} variant='contained' color={submitAsStatus === Status.Passed ? "success" : "error"}>{`Submit as ${submitAsStatus === Status.Passed ? "Passed" : "Failed"}`}</LoadingButton>
            </Stack>
        </AccordionDetails>
    </Accordion>
    )
}

export default FinalSignOff