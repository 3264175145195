import { Stack, TextField, Autocomplete } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { FormikProps } from 'formik';
import React, { useEffect } from 'react'
import { MessageItem } from 'src/components/errorHandlingSnackbar';
import ExpirySelector from 'src/components/ExpirySelector/ExpirySelector';
import QuestionTitle from 'src/components/QuestionTitle/QuestionTitle';
import { InspectionChecklistDto } from 'src/dtos/AwTypes';
import AttractionService from 'src/Services/Resources/AttractionService';
import DepartmentService from 'src/Services/Resources/DepartmentService';

type Props = {
    id?: string;
    formik: FormikProps<InspectionChecklistDto>
    setMessageItem: React.Dispatch<React.SetStateAction<MessageItem>>
}
function InspectionChecklistForm({id, formik, setMessageItem}:Props) {

    //#region Queries
    const attractionNamesQuery = useQuery({
        queryKey: ["Attraction.GetNames"],
        queryFn: async () => {
            return await AttractionService.GetNames();
        },
        initialData: [],
    });

    const departmentNamesQuery = useQuery({
        queryKey: ["Department.GetNames"],
        queryFn: async () => {
            return await DepartmentService.GetNames();
        },
        initialData: [],
    });

    useEffect(() => {
        if (attractionNamesQuery.isError)
            setMessageItem({ error: attractionNamesQuery.error });
        if (departmentNamesQuery.isError)
            setMessageItem({ error: departmentNamesQuery.error });
    }, [attractionNamesQuery.error, attractionNamesQuery.isError, departmentNamesQuery.error, departmentNamesQuery.isError, setMessageItem])
    
    //#endregion
    
    return (
        <Stack spacing={2}>
            <div>
                <QuestionTitle required title="Name" />
                <TextField
                    id="label"
                    name="label"
                    value={formik.values.label}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                        formik.touched.label &&
                        Boolean(formik.errors.label)
                    }
                    helperText={
                        formik.touched.label && formik.errors.label
                    }
                    variant="outlined"
                    size="small"
                    sx={{ width: "20rem" }}
                />
            </div>
            <div>
                <QuestionTitle title="Description" />
                <TextField
                    id="description"
                    name="description"
                    value={formik.values.description}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                        formik.touched.description &&
                        Boolean(formik.errors.description)
                    }
                    helperText={
                        formik.touched.description &&
                        formik.errors.description
                    }
                    variant="outlined"
                    size="small"
                    multiline
                    minRows={2}
                    maxRows={3}
                    sx={{ width: "20rem" }}
                />
            </div>
            <div>
                <QuestionTitle required title="Department" />
                <Autocomplete
                    disablePortal
                    id="department"
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    getOptionLabel={(option) => option.label ? option.label : ""}
                    options={departmentNamesQuery.data}
                    size="small"
                    sx={{ width: "20rem" }}
                    value={formik.values.department}
                    onChange={(_, value: any) => {
                        formik.setFieldValue("department", value);
                    }}
                    onBlur={formik.handleBlur}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            name="department.label"
                            error={
                                formik.touched.department &&
                                Boolean(formik.errors.department)
                            }
                            helperText={
                                formik.touched.department &&
                                formik.errors.department
                            }
                        />
                    )}
                />
            </div>
            <div>
                <QuestionTitle required title="Attraction" infoText={id !== "new" ? "You cannot change the attraction" : ""} />
                <Autocomplete
                    disablePortal
                    id="attraction"
                    isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                    }
                    getOptionLabel={(option) =>
                        option.label ? option.label : ""
                    }
                    options={attractionNamesQuery.data}
                    size="small"
                    sx={{ width: "20rem" }}
                    value={formik.values.attraction}
                    onChange={(_, value: any) => {
                        formik.setFieldValue("attraction", value);
                    }}
                    onBlur={formik.handleBlur}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            name="attraction.label"
                            error={
                                formik.touched.attraction &&
                                Boolean(formik.errors.attraction)
                            }
                            helperText={
                                formik.touched.attraction &&
                                formik.errors.attraction
                            }
                        />
                    )}
                    disabled={id !== "new"}
                />
            </div>
            <div>
                <QuestionTitle required title="Expiry Policy" />
                <ExpirySelector formik={formik} />
            </div>
            <div>
                <QuestionTitle title="Expected Duration (seconds)" />
                <TextField
                    id="expectedDurationSeconds"
                    name="expectedDurationSeconds"
                    value={formik.values.expectedDurationSeconds ?? ""}
                    onChange={(event) => {
                        const value = event.target.value;
                        formik.setFieldValue("expectedDurationSeconds", value === "" ? null : value);
                    }}
                    onBlur={formik.handleBlur}
                    error={
                        formik.touched.expectedDurationSeconds &&
                        Boolean(formik.errors.expectedDurationSeconds)
                    }
                    helperText={
                        formik.touched.expectedDurationSeconds &&
                        formik.errors.expectedDurationSeconds
                    }
                    variant="outlined"
                    size="small"
                    type="number"
                    sx={{ width: "20rem" }}
                />
            </div>
        </Stack>
    )
}

export default InspectionChecklistForm