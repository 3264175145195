import { Divider, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import React from 'react'
import { dateTimeOptions } from 'src/config/constants'
import textContent from 'src/config/textContent'
import { TrainingMemoDto } from 'src/dtos/Training/TrainingMemo.dto'
import TrainingMemoMemorandum from 'src/pages/User Site/Training/TrainingMemos/Components/TrainingMemoMemorandum'

type Props = {
    trainingMemo: TrainingMemoDto
}
function MemoTeamAcceptance({trainingMemo}: Props) {
    return (
        <>
            {trainingMemo.dateIssued == null ? (
                <Typography>Training Memo has not been issued yet!</Typography>
            ) : (
                <>
                    <TrainingMemoMemorandum
                        trainingMemo={trainingMemo}
                    />
                    <Divider sx={{borderBottomWidth: 3, borderColor:"black", marginTop:"1rem", marginBottom:"1rem" }}/>
                    <Typography>
                        {textContent.trainingMemo.acceptanceClause}
                    </Typography>
                    <TableContainer component={Paper}>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        Team Member
                                    </TableCell>
                                    <TableCell>
                                        Signature
                                    </TableCell>
                                    <TableCell>
                                        Time Signed
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {trainingMemo.acceptances.map((acceptance, index) => (
                                    <TableRow key={index}>
                                        <TableCell>
                                            {acceptance.teamMember.label}
                                        </TableCell>
                                        <TableCell>
                                            {acceptance.signature && 
                                                <img 
                                                    style={{width:"5rem"}}
                                                    src={acceptance.signature}
                                                    alt={`${acceptance.signature} Signature`}
                                                />
                                            }
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant='body2' sx={{minWidth:"4rem"}}>
                                                {acceptance.timeSigned && new Date(acceptance.timeSigned).toLocaleDateString(undefined, dateTimeOptions)}
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </>
            )}
        </>
    )
}

export default MemoTeamAcceptance