import axios, { AxiosResponse } from "axios";
import { GetAuthorisation } from "src/Utils/authUtils";

const apiBaseUrl = process.env.REACT_APP_API_BASE_URL + "/PDF";
const debug = process.env.REACT_APP_DEBUG;

export default class PDFService {

    static async GetInspectionChecklistPDF(checklistId: string): Promise<Blob> {
        try {
            const authorization = await GetAuthorisation();
            const response: AxiosResponse = await axios.get(`${apiBaseUrl}/GetInspectionChecklistPDF/${checklistId}`, {
                headers: {
                    Authorization: authorization
                },
                responseType: "blob"
            });

            return response.data;

        } catch (error: any) {
            debug && console.log(error)
            throw error;
        }
    } 
}