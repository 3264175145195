import { GetAuthorisation } from "src/Utils/authUtils";
import { GroupedSectionComponentResponse, InspectedComponentDto, InspectedComponentStatus, InspectionChecklistQuestionResponseDto, InspectionChecklistResponseDto, InspectionChecklistSectionResponseDto } from "src/dtos/Checklists/AttractionInspections/ChecklistResponse.dto";
import { Status } from "src/dtos/Statuses";
import { ListParameters, SortDirection } from "../../ListParameters";
import { ListResponseDto } from "src/dtos/ListResponse.dto";
import { NameDto, NameNumberDto } from "src/dtos/NameDto.dto";
import { AttractionInspectionsStatusDto } from "src/dtos/Checklists/AttractionInspections/AttractionInspectionsStatus.dto";
import { JournalEntryDto } from "src/dtos/Checklists/AttractionInspections/Journal.dto";
import axios, { AxiosResponse } from "axios";

const apiBaseUrl = process.env.REACT_APP_API_BASE_URL + "/InspectionChecklistResponse";
const debug = process.env.REACT_APP_DEBUG;

const emptyData: ListResponseDto = {
    data: [],
    totalRecords: 0
}

export default class InspectionChecklistResponseService {
    static GetDefaultValues(){
        const defaultVals: InspectionChecklistResponseDto = {
            id: "00000000-0000-0000-0000-000000000000",
            inspectionChecklistVersionId: "00000000-0000-0000-0000-000000000000",
            status: Status.Inprogress,
            sectionResponses: [
                {
                    id: 1,
                    status: Status.Unanswered,
                    questionResponses: [
                        {
                            id: 1,
                            status: Status.Unanswered,
                            answer: null,
                            issueResolved: null,
                            failureCause: ""
                        }
                    ]
                }
            ],
            inspectedComponents: []
        }
        return defaultVals;
    }
    static async GetInitialValues(versionId: string){
        try {
            const authorization = await GetAuthorisation();
            const response: AxiosResponse<InspectionChecklistResponseDto> = await axios.get<InspectionChecklistResponseDto>(`${apiBaseUrl}/GetNew/${versionId}`, {
                headers: {
                    Authorization: authorization
                },
            });
            return response.data;
        } catch (error: any) {
            debug && console.log(error);
            throw error;
        }
    }

    static async GetJournalEntries(responseId: string){
        try {
            const authorization = await GetAuthorisation();
            const response: AxiosResponse<JournalEntryDto[]> = await axios.get<JournalEntryDto[]>(`${apiBaseUrl}/GetJournalEntries/${responseId}`, {
                headers: {
                    Authorization: authorization
                },
            });
            return response.data;
        } catch (error: any) {
            debug && console.log(error);
            throw error;
        }
    }

    static async GetPossibleTrainees(): Promise<NameDto[]> {
        try {
            const authorization = await GetAuthorisation();
            const response: AxiosResponse<NameDto[]> = await axios.get<NameDto[]>(`${apiBaseUrl}/GetPossibleTrainees`, {
                headers: {
                    Authorization: authorization
                }
            });
            return response.data;
        } catch (error: any) {
            debug && console.log(error)
            throw error;
        }
    }

    static async StartInspection(inspectionId: string){
        try {
            const authorization = await GetAuthorisation();
            const response: AxiosResponse<string> = await axios.post<string>(`${apiBaseUrl}/StartInspection/${inspectionId}`, 
            null,
            {
                headers: {
                    Authorization: authorization
                },
            });
            return response;
        } catch (error: any) {
            debug && console.log(error);
            throw error;
        }
    }

    static async GetCompletedInspections(listParam?: ListParameters, searchTerm?: string){
        try {
            const authorization = await GetAuthorisation();
            const response: AxiosResponse<ListResponseDto> = await axios.get<ListResponseDto>(`${apiBaseUrl}/GetCompletedInspections`, {
                params: {
                    page: listParam?.page,
                    pageSize: listParam?.pageSize,
                    sortBy : listParam?.sortBy,
                    sortDirection: listParam?.sortDirection === SortDirection.Ascending ? 0 : listParam?.sortDirection === SortDirection.Descending ? 1 : undefined,
                    searchTerm: searchTerm,              
                },
                headers: {
                    Authorization: authorization
                }
            });
            return response.data;
        } catch (error: any) {
            if(error.response !== undefined){
                if(error.response.status != null && error.response.status === 404){
                    return emptyData
                }
            }
            debug && console.log(error)
            throw error;
        }
    }

    static async GetAttractionInspections(searchTerm?: string): Promise<AttractionInspectionsStatusDto[]> {
        try {
            const authorization = await GetAuthorisation();
            const response: AxiosResponse<AttractionInspectionsStatusDto[]> = await axios.get<AttractionInspectionsStatusDto[]>(`${apiBaseUrl}/GetAttractionInspections`, {
                params: {
                    searchTerm: searchTerm,              
                },
                headers: {
                    Authorization: authorization
                }
            });
            return response.data;
        } catch (error: any) {
            debug && console.log(error)
            throw error;
        }
    }

    static async GetAttractionInspectionsOverview(searchTerm?: string): Promise<AttractionInspectionsStatusDto[]> {
        try {
            const authorization = await GetAuthorisation();
            const response: AxiosResponse<AttractionInspectionsStatusDto[]> = await axios.get<AttractionInspectionsStatusDto[]>(`${apiBaseUrl}/GetAttractionInspectionsOverview`, {
                params: {
                    searchTerm: searchTerm,              
                },
                headers: {
                    Authorization: authorization
                }
            });
            return response.data;
        } catch (error: any) {
            debug && console.log(error)
            throw error;
        }
    }    

    static async Get(id: string){
        try {
            const authorization = await GetAuthorisation();
            const response: AxiosResponse<InspectionChecklistResponseDto> = await axios.get<InspectionChecklistResponseDto>(`${apiBaseUrl}/Get/${id}`, {
                headers: {
                    Authorization: authorization
                },
            });
            return response.data;
        } catch(error: any) {
            debug && console.log(error);
            throw error;
        }
    }

    static async GetCompleted(id: string){
        try {
            const authorization = await GetAuthorisation();
            const response: AxiosResponse<InspectionChecklistResponseDto> = await axios.get<InspectionChecklistResponseDto>(`${apiBaseUrl}/GetCompleted/${id}`, {
                headers: {
                    Authorization: authorization
                },
            });
            return response.data;
        } catch(error: any) {
            debug && console.log(error);
            throw error;
        }
    }

    static async UpdateQuestion(questionId: number, newQuestionResponse: InspectionChecklistQuestionResponseDto){
        try {
            const authorization = await GetAuthorisation();
            const response: AxiosResponse<InspectionChecklistQuestionResponseDto> = await axios.put<InspectionChecklistQuestionResponseDto>(`${apiBaseUrl}/UpdateQuestion`, newQuestionResponse, {
                headers: {
                    Authorization: authorization
                },
                params: {
                    questionId: questionId
                },
            });
            return response
        } catch(error: any) {
            debug && console.log(error)
            throw error;
        }
    }

    static async UpdateSection(sectionId: number, newSectionResponse: InspectionChecklistSectionResponseDto){
        try {
            const authorization = await GetAuthorisation();
            const response: AxiosResponse<InspectionChecklistSectionResponseDto> = await axios.put<InspectionChecklistSectionResponseDto>(`${apiBaseUrl}/UpdateSection`, newSectionResponse, {
                headers: {
                    Authorization: authorization
                },
                params: {
                    sectionId: sectionId
                },
            });
            return response
        } catch(error: any) {
            debug && console.log(error)
            throw error;
        }
    }
    
    static async UpdateSelectedComponents(responseId: string, updateSelectedComponents: NameNumberDto[]){
        try {
            const authorization = await GetAuthorisation();
            const response: AxiosResponse<NameNumberDto[]> = await axios.put<NameNumberDto[]>(`${apiBaseUrl}/UpdateSelectedComponents`, updateSelectedComponents, {
                headers: {
                    Authorization: authorization
                },
                params: {
                    responseId: responseId
                }
            });
            return response;
        } catch(error: any){
            debug && console.log(error)
            throw error;
        }
    }

    static async UpdateInspectedComponent(responseId: string, componentId: number, status: InspectedComponentStatus){
        try {
            const authorization = await GetAuthorisation();
            const response: AxiosResponse<InspectedComponentDto[]> = await axios.put<InspectedComponentDto[]>(`${apiBaseUrl}/UpdateInspectedComponent`, null, {
                headers: {
                    Authorization: authorization
                },
                params: {
                    responseId: responseId,
                    componentId: componentId,
                    status: status
                }
            });
            return response;
        } catch(error: any){
            debug && console.log(error)
            throw error;
        }
    }

    static async UpdateGroupedSectionComponentResponse(componentResponse: GroupedSectionComponentResponse){
        try {
            const authorization = await GetAuthorisation();
            const response: AxiosResponse<GroupedSectionComponentResponse> = await axios.put<GroupedSectionComponentResponse>(`${apiBaseUrl}/UpdateGroupedSectionComponentResponse`, componentResponse, {
                headers: {
                    Authorization: authorization
                }
            });
            return response;
        } catch(error: any){
            debug && console.log(error)
            throw error;
        }
    }

    static async UpdateGroupedPassedComponentsQty(sectionResponseId: number, newQty: number){
        try {
            const authorization = await GetAuthorisation();
            const response: AxiosResponse<number> = await axios.put<number>(`${apiBaseUrl}/UpdateGroupedPassedComponentsQty`, null, {
                headers: {
                    Authorization: authorization
                },
                params: {
                    sectionResponseId: sectionResponseId,
                    newQty: newQty,
                }
            });
            return response;
        } catch(error: any){
            debug && console.log(error)
            throw error;
        }
    }

    static async UpdateTrainee(responseId: string, trainee: NameDto | null){
        try {
            const authorization = await GetAuthorisation();
            const response: AxiosResponse = await axios.put(`${apiBaseUrl}/UpdateTrainee/${responseId}`, trainee, {
                headers: {
                    Authorization: authorization
                },
            });
            return response;
        } catch (error: any) {
            debug && console.log(error)
            throw error;
        }
    }

    static async Submit(id: string, completedTime: Date) {
        try {
            const authorization = await GetAuthorisation();
            const response: AxiosResponse = await axios.put(`${apiBaseUrl}/Submit/${id}`, null, {
                headers: {
                    Authorization: authorization
                },
                params: {
                    completedTime: completedTime
                }
            });
            return response;
        } catch (error: any) {
            debug && console.log(error)
            throw error;
        }
    }

    static async DeleteResponse(id: string) {
        try {
            const authorization = await GetAuthorisation();
            const response: AxiosResponse = await axios.delete<AxiosResponse>(`${apiBaseUrl}/DeleteResponse/${id}`, {
                headers: {
                    Authorization: authorization
                },
                params: {
                    id: id
                }
            });
            return response
        } catch (error: any) {
            debug && console.log(error)
            throw error;
        }
    }


}