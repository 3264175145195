import { Accordion, AccordionDetails, AccordionSummary, Autocomplete, Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControlLabel, IconButton, Paper, Stack, Switch, TextField, Tooltip, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import './styles.scss'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import QuestionTitle from 'src/components/QuestionTitle/QuestionTitle';
import { useFormik } from 'formik';
import * as yup from 'yup'
import AttractionService from 'src/Services/Resources/AttractionService';
import ErrorHandlingSnackbar, { MessageItem } from 'src/components/errorHandlingSnackbar';
import LocationService from 'src/Services/Resources/LocationService';
import ChildEntityForm from './ChildEntity/ChildEntityForm';
import { LoadingButton } from '@mui/lab';
import { ExpandMore } from '@mui/icons-material';
import { dateOptions } from 'src/config/constants';
import { AttractionDto, AttractionTypeList, AttractionTypeString } from 'src/dtos/Resources/Attraction.dto';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';


type Props = {
}

const validationSchema = yup.object({
        label: yup
        .string()
        .max(50, 'Attraction Name cannot be more than 50 characters')
        .required('Attraction Name is required'),
    desciption: yup
        .string()
        .max(255, "Attraction Descrition cannot be more than 255 characters")
        .notRequired(),
    residingArea: yup.object().shape({
        id: yup
            .string()
            .required(),
        label: yup
            .string()
            .required()
    }).required("Residing Area is required"),
    type: yup
        .string()
        .required("Attraction Type is required"),
    deviceMinimumCapacity: yup
        .number().typeError("Capacity must be a whole number")
        .min(0, "Capacity must be a positive number")
        .integer("Capacity must be a whole number")
        .notRequired(),
    deviceMaximumCapacity: yup
        .number().typeError("Capacity must be a whole number")
        .min(0, "Capacity must be a positive number")
        .integer("Capacity must be a whole number")
        .notRequired(),
    deviceCapacityLabel: yup
        .string()
        .max(50, "Capacity Label cannot be more than 50 characters")
        .notRequired(),
    components: yup.array()
        .of(
            yup.object().shape({
                id: yup
                    .number(),
                label: yup
                    .string()
                    .required("Child Label is required"),
                children: yup.array()
                    .of(
                        yup.object().shape({
                            id: yup
                                .number(),
                            label: yup
                                .string()
                                .required("Child Label is required"),
                        })
                    )
            })
        )
        
})

function AttractionForm(props: Props) {
    const {id} = useParams();
    const [messageItem, setMessageItem] = useState<MessageItem>({successMessage: undefined, error: undefined})
    const [openAlertDialog, setOpenAlertDialog] = useState(false);
    const navigate = useNavigate()
    const queryClient = useQueryClient();

    const urlIdRegex = new RegExp(`/${id}$`)
    const guidRegex = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/; //check if a string is a guid

    const handleStatusChange = () => {
        setOpenAlertDialog(true);
    }

    const handleBackClick = () => {
        //remove id param from end of the url
        navigate({ pathname: window.location.pathname.replace(urlIdRegex, '') });
    }

    //#region queries
    const areasQuery = useQuery({
        queryKey: ["Locations.GetAreas"],
        queryFn: async () => {
            return await LocationService.GetAreas();
        }
    })

    const attractionQuery = useQuery({
        queryKey: ["Attractions.Get", id],
        queryFn: async () => {
            if(id !== undefined && guidRegex.test(id))
                return await AttractionService.Get(id)
            return AttractionService.GetDefaultValues()
        },
        initialData: AttractionService.GetDefaultValues()
    })

    useEffect(() => {
        if(areasQuery.isError)
            setMessageItem({error: areasQuery.error})
        if(attractionQuery.isError)
            setMessageItem({error: attractionQuery.error})
    }, [areasQuery.error, areasQuery.isError, attractionQuery.error, attractionQuery.isError])
    
    const createMutation = useMutation({
        mutationFn: (values: AttractionDto) => {
            return AttractionService.Create(values)
        },
        onSuccess: (data) => {
            setMessageItem({successMessage: "Attraction Updated Successfully!"})

            navigate({pathname: window.location.pathname.replace(urlIdRegex, `/${data.data.id}`)})
        },
        onError: (error) => {
            setMessageItem({error: error})
        }
    })

    const updateMutation = useMutation({
        mutationFn: ({id, values}: {id: string, values: AttractionDto}) => {
            return AttractionService.Update(id, values)
        },
        onSuccess: (data) => {
            setMessageItem({successMessage: "Attraction Updated Successfully!"})
            //update query data to newly updated values
            queryClient.setQueryData(["Attractions.Get", id], data.data)
        }
    })

    const enableMutation = useMutation({
        mutationFn: (id: string) => {
            return AttractionService.Enable(id)
        },
        onSuccess: () => {
            setMessageItem({successMessage: "Attraction Enabled Successfully!"})
        },
        onError: (error) => {
            setMessageItem({error: error})
        }
    })

    const disableMutation = useMutation({
        mutationFn: (id: string) => {
            return AttractionService.Disable(id)
        },
        onSuccess: () => {
            setMessageItem({successMessage: "Attraction Disable Successfully!"})
        },
        onError: (error) => {
            setMessageItem({error: error})
        }
    })
    //#endregion


    const save = async (values: AttractionDto) => {
        if(id === "new"){
            //create
            createMutation.mutate(values)
            
        } else if (id !== undefined && id){
            //update
            updateMutation.mutate({id, values})
        }
    }

    const saveToggleEnable = async () => {
        if(id === undefined || !guidRegex.test(id))
            return

        if(!formik.values.enabled)
            enableMutation.mutate(id)
        else 
            disableMutation.mutate(id)
                  
    }

    function AlertDialog() {
        const handleClick = () => {
          formik.setFieldValue("enabled", !formik.values.enabled);
          saveToggleEnable();
          setOpenAlertDialog(false);
        }
        return (
          <Dialog
            open={openAlertDialog}
            onClose={()=> setOpenAlertDialog(false)}
          >
            <DialogTitle>
            {formik.values.enabled ? 'Deactivate' : 'Activate'} Attraction?
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                Are you sure you want to {formik.values.enabled ? 'deactivate' : 'activate'} {formik.values.label}?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setOpenAlertDialog(false)}>Cancel</Button>
              <Button onClick={handleClick}>{formik.values.enabled ? 'Deactivate' : 'Activate'}</Button>
            </DialogActions>
          </Dialog>
        )
    }   

    const handleDocURLChange = (e: React.ChangeEvent<any>) => {
        const value = e.target.value.trim()
        const validURLRegex = /^https:\/\/.+\.sharepoint\.com\/.+$/
        if(validURLRegex.test(value)){
            formik.setFieldValue("documentationUrl", value)
        } else {
            formik.setFieldValue("documentationUrl", "")
        }
    }

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: attractionQuery.data,
        validationSchema: validationSchema,
        onSubmit: (values) => {
          save(values);
        }
      })
      
    return (
    <>
        <Paper sx={{display:"inline-block"}}>
            <div className='header'>
                {id === 'new' ? "New" : "Edit"} Attraction
                <Tooltip title="Go Back">
                    <IconButton onClick={handleBackClick}><ArrowBackIcon fontSize='large'/></IconButton>
                </Tooltip>
                
            </div>
            <form onSubmit={formik.handleSubmit}>
                <Box>
                        <Stack sx={{padding:"2rem"}} spacing={1}>
                            <Typography variant='h5'>General Information</Typography>
                            <div>
                                <QuestionTitle
                                    title="Attraction Name"
                                    required={true}
                                />
                                <TextField
                                    id="label"
                                    name="label"
                                    value={formik.values.label}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched.label && Boolean(formik.errors.label)}
                                    helperText={formik.touched.label && formik.errors.label}
                                    variant='outlined'
                                    size="small"
                                    sx={{width: "20rem"}}
                                />
                            </div>
                            <div>
                                <QuestionTitle title="Description"/>
                                <TextField 
                                    id="description"
                                    name="description"
                                    value={formik.values.description}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched.description && Boolean(formik.errors.description)}
                                    helperText={formik.touched.description && formik.errors.description}
                                    multiline 
                                    maxRows={4} 
                                    variant="outlined" 
                                    size="small" 
                                    sx={{width: "20rem"}}/>
                            </div>
                            <div>
                                <QuestionTitle
                                    title="Residing Area"
                                    required={true}
                                />
                                <Autocomplete
                                    disablePortal
                                    id="residingArea"
                                    isOptionEqualToValue={(option, value) => option.id === value.id}
                                    getOptionLabel={(option) => option.label ? option.label: ""}
                                    options={areasQuery.data ?? []}
                                    size="small"
                                    sx={{width: "20rem"}}
                                    value={formik.values.residingArea ? {id: formik.values.residingArea.id, label: formik.values.residingArea.label} : null}
                                    onChange={(_, value:any) => 
                                        formik.setFieldValue("residingArea", value ? { id: value.id, label: value.label } : null)
                                    }
                                    onBlur={formik.handleBlur}                                       
                                    renderInput={(params) => 
                                        <TextField {...params}
                                            name="residingArea"
                                            error={formik.touched.residingArea && Boolean(formik.errors.residingArea)}
                                            helperText={formik.touched.residingArea && formik.errors.residingArea}    
                                        />
                                    }
                                />
                            </div>
                            <div>
                                <QuestionTitle
                                    title="Attraction Type"
                                    required={true}
                                />
                                <Autocomplete
                                    disablePortal
                                    id="type"
                                    options={AttractionTypeList}
                                    getOptionLabel={(option) => AttractionTypeString[option]}
                                    disableClearable
                                    size="small"
                                    sx={{width: "20rem"}}
                                    value={formik.values.type}
                                    onChange={(_, value) => 
                                        formik.setFieldValue("type", value)
                                    }
                                    renderInput={(params) => 
                                        <TextField {...params}
                                            name="type"
                                            error={formik.touched.type && Boolean(formik.errors.type)}
                                            helperText={formik.touched.type && formik.errors.type}    
                                        />
                                    }
                                />
                            </div>
                            <Stack direction="row" spacing={3}>
                                <div>
                                    <QuestionTitle title="Minimum Capacity" width='4rem'/>
                                    <TextField
                                        id="deviceMinimumCapacity"
                                        name="deviceMinimumCapacity"
                                        value={formik.values.deviceMinimumCapacity}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        error={formik.touched.deviceMinimumCapacity && Boolean(formik.errors.deviceMinimumCapacity)}
                                        helperText={formik.touched.deviceMinimumCapacity && formik.errors.deviceMinimumCapacity}
                                        variant='outlined'
                                        size="small"
                                        sx={{width: "4rem"}}
                                    />
                                </div>
                                <div>
                                    <QuestionTitle title="Maximum Capacity" width='4rem'/>
                                    <TextField
                                        id="deviceMaximumCapacity"
                                        name="deviceMaximumCapacity"
                                        value={formik.values.deviceMaximumCapacity}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        error={formik.touched.deviceMaximumCapacity && Boolean(formik.errors.deviceMaximumCapacity)}
                                        helperText={formik.touched.deviceMaximumCapacity && formik.errors.deviceMaximumCapacity}
                                        variant='outlined'
                                        size="small"
                                        sx={{width: "4rem"}}
                                    />
                                </div>
                                <div style={{ marginTop:'auto'}}>
                                    <QuestionTitle title="Capacity Name" width='7rem'/>
                                    <TextField
                                        id="deviceCapacityLabel"
                                        name="deviceCapacityLabel"
                                        value={formik.values.deviceCapacityLabel}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        error={formik.touched.deviceCapacityLabel && Boolean(formik.errors.deviceCapacityLabel)}
                                        helperText={formik.touched.deviceCapacityLabel && formik.errors.deviceCapacityLabel}
                                        variant='outlined'
                                        size="small"
                                        sx={{width: "7rem"}}
                                    />
                                </div>
                            </Stack>
                            <div>
                                <QuestionTitle
                                    title="Documentation URL"
                                    infoText="Only accepts URLs from sharepoint"
                                />
                                <TextField
                                    id="documentationUrl"
                                    name="documentationUrl"
                                    value={formik.values.documentationUrl}
                                    onChange={handleDocURLChange}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched.documentationUrl && Boolean(formik.errors.documentationUrl)}
                                    helperText={formik.touched.documentationUrl && formik.errors.documentationUrl}
                                    variant='outlined'
                                    size="small"
                                    sx={{width: "20rem"}}
                                />
                            </div>
                            {id !== 'new' && (
                                <div>
                                    <QuestionTitle title="Attraction Status"/>
                                    <FormControlLabel 
                                        control={
                                        <Switch 
                                            checked={formik.values.enabled}
                                            onChange={handleStatusChange}  
                                        />
                                        }
                                        label={formik.values.enabled ? "Active" : "Inactive"}
                                    />
                                </div> 
                            )}
                        </Stack>

                        <Box sx={{padding:"2rem"}}>
                            <Typography variant='h5'>Ride Components</Typography>
                            <ChildEntityForm formikProps={formik}/>
                        </Box>
                </Box>
                <div className='footer' style={{display: "flex", justifyContent: "space-between", gap:"1rem"}}>
                {id !== 'new' && (
                <div>
                    <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMore/>}
                        id="additional-info"
                    >
                        <Typography>Modification Details</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        {formik.values.createdBy && (
                        <Typography>
                            Created By: {formik.values.createdBy}
                        </Typography>
                        )}
                        {formik.values.createdOn && (
                        <Typography>
                            Created On: {new Date(formik.values.createdOn).toLocaleTimeString(undefined, dateOptions)}
                        </Typography>
                        )}
                        {formik.values.modifiedBy && (
                        <Typography>
                            Modified By: {formik.values.modifiedBy}
                        </Typography>
                        )}
                        {formik.values.modifiedOn && (
                        <Typography>
                            Modified On: {new Date(formik.values.modifiedOn).toLocaleTimeString(undefined, dateOptions)}
                        </Typography>
                        )}
                    </AccordionDetails>
                    
                    </Accordion>
                    
                </div>
                )}
                    <div style={{flexGrow: 1, textAlign: "right"}}>
                        <LoadingButton  type='submit' variant="contained" color='primary' size="large" loading={createMutation.isPending || updateMutation.isPending}><span>Save</span></LoadingButton>
                    </div>
                </div>

            </form>
        </Paper>
        <AlertDialog/>
        <ErrorHandlingSnackbar
            messageItem={messageItem}
        />
    </>
    
    )
}

export default AttractionForm