import { TabContext, TabList, TabPanel } from '@mui/lab'
import { Box, Paper, Stack, Tab, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import TmGeneral from './components/TmGeneral';
import { useFormik } from 'formik';
import * as yup from "yup";
import { guidRegex } from 'src/Utils/helperFunc';
import { useMutation, useQuery } from '@tanstack/react-query';
import ErrorHandlingSnackbar, { MessageItem } from 'src/components/errorHandlingSnackbar';
import TeamMemberService from 'src/Services/HumanResources/TeamMemberService';
import TmTraining from './components/TmTraining.tsx/TmTraining';
import TmRoles from './components/TmRoles/TmRoles';
import { TeamMember } from 'src/dtos/HumanResources/TeamMember/TeamMember.dto';


const validationSchema = yup.object({
    prefferedFirstName: yup.string().max(255, "Preferred First Name cannot exceed 255 characters").required("Preferred First Name is a required field"),
    firstName: yup.string().max(255, "First Name cannot exceed 255 characters").required("First Name is a required field"),
    lastName: yup.string().max(255, "Last Name cannot exceed 255 characters").required("Last Name is a required field"),
    dob: yup.date().max(new Date(), "Date of Birth cannot be in the future.").required("Date of Birth is a required field"),
    mobile: yup.string().matches(/^(?:\+?(61))? ?(?:\((?=.*\)))?(0?[2-57-8])\)? ?(\d\d(?:[- ](?=\d{3})|(?!\d\d[- ]?\d[- ]))\d\d[- ]?\d[- ]?\d{3})$/, "Invalid format, please use an Australian Mobile Number"),
    email: yup.string().email("Must be a valid email").required("Email is a required field"),
    microsoftAccountId: yup.string().matches(guidRegex, "Must be a GUID").notRequired(),
    talentLMSAccountId: yup.string().notRequired(),
  })


function TeamMemberCreator() {
    const [searchParams, setSearchParams] = useSearchParams()
    const defaultTab = "General"
    const [openTab, setOpenTab] = React.useState(searchParams.get("openTab") ?? defaultTab);
    const [messageItem, setMessageItem] = useState<MessageItem>({})
    const {id} = useParams();
    const urlIdRegex = new RegExp(`/${id}$`)
    const navigate = useNavigate()

    const handleTabChange = (_event: React.SyntheticEvent, tabValue: string) => {
        setOpenTab(tabValue);
        if(tabValue === defaultTab){
            searchParams.delete("openTab")
        } else {
            searchParams.set("openTab", tabValue)
        }
        searchParams.delete("nestedTab")
        setSearchParams(searchParams)
    };


    //#region queries
    const teamMemberQuery = useQuery({
        queryKey: ["teamMember", id],
        queryFn: async () => {
            if(id !== undefined && guidRegex.test(id)) {
                return await TeamMemberService.Get(id)
            }
            return null
        }
    })

    //Handle Query errors
    useEffect(() => {
        if(teamMemberQuery.isError){
            setMessageItem({error: teamMemberQuery.error})
        }
    }, [teamMemberQuery.error, teamMemberQuery.isError])

    const createMutation = useMutation({
        mutationFn: (values: TeamMember) => {
            return TeamMemberService.Create(values)
        },
        onSuccess: (data) => {
            setMessageItem({successMessage: "Team Member created successfully!"})

            //update URL
            navigate({pathname: window.location.pathname.replace(urlIdRegex, `/${data.data.id}`)})
        },
        onError: (error) => {
            setMessageItem({error: error})
        }
    })

    const updateMutation = useMutation({
        mutationFn: ({id, values}: {id: string, values: TeamMember}) => {
            return TeamMemberService.Update(id, values)
        },
        onSuccess: (data) => {
            setMessageItem({successMessage: "Team Member updated successfully!"})
            formik.setValues(data.data)
        },
        onError: (error) => {
            setMessageItem({error: error})
        }
    })

    //#endregion

    const save = async (values: TeamMember) => {
        if(id === "new"){
            //Create
            createMutation.mutate(values)
        } else if(id !== undefined && guidRegex.test(id)){
            //update
            updateMutation.mutate({id, values})
        }
    }

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: teamMemberQuery.data ?? TeamMemberService.GetDefaultValues(),
        validationSchema: validationSchema,
        validateOnBlur: true,
        onSubmit: (values) => {
          save(values);
        }
    })

    const loading = createMutation.isPending || updateMutation.isPending

    return (
    <>
        <Stack spacing={2}>
            <Paper sx={{padding:"1rem"}}>
                <Typography variant="h5">{`${formik.values.prefferedFirstName ?? "New"} ${formik.values.lastName ?? "TeamMember"}`}</Typography>
            </Paper>
            <Paper>
                <TabContext value={openTab}>
                    <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                        <TabList onChange={handleTabChange}>
                            <Tab label="General" value="General"></Tab>
                            <Tab label="AW Hub Roles" value="AWHubRoles" disabled={id==="new"}></Tab>
                            <Tab label="Training" value="Training" disabled={id==="new"}></Tab>
                        </TabList>
                    </Box>
                    <TabPanel value="General">
                        <TmGeneral
                            id={id}
                            formik={formik}
                            setMessageItem={setMessageItem}
                            loading={loading}
                        />
                    </TabPanel>
                    <TabPanel value="AWHubRoles" sx={{padding:"0"}}>
                        {id && id !== "new" &&
                            <TmRoles
                                teamMemberId={id}
                                setMessageItem={setMessageItem}
                            />
                        }
                    </TabPanel>
                    <TabPanel value="Training" sx={{padding:"0"}}>
                        <TmTraining
                            formik={formik}
                            setMessageItem={setMessageItem}
                            loading={loading}
                        />
                    </TabPanel>

                </TabContext>
            </Paper>

        </Stack>
        <ErrorHandlingSnackbar messageItem={messageItem}/>
    </>
    )
}

export default TeamMemberCreator