import { Stack, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import React from 'react'
import { dateTimeOptions } from 'src/config/constants';
import { LearningDocumentPoint,  } from 'src/dtos/Training/LearningDocument.dto';
import { LearningDocumentPointResponse } from 'src/dtos/Training/LearningDocumentResponse.dto';
import { createUnorderedListMarkup } from 'src/pages/User Site/TrainingDocuments/TrainingDocumentComponents/Point/Components/CreateUnorderedListMarkup';
import { PointIcon } from 'src/pages/User Site/TrainingDocuments/TrainingDocumentComponents/Point/Components/PointIcon';
import { EmailToNameString } from 'src/Utils/helperFunc';

type Props = {
    index: number;
    point: LearningDocumentPoint;
    pointResponse: LearningDocumentPointResponse;
    showHeader: boolean;
    allIconlessPoints?: boolean;
}
function TroubleShootingPoint({index, point, pointResponse, showHeader, allIconlessPoints}: Props) {
    return (
        <TableRow>
            <TableCell>
                <Stack direction={"row"} sx={{display:"flex", alignItems:"center"}}>
                    {allIconlessPoints !== true && (
                        <PointIcon pointType={point.type}/>
                    )}
                    <Table padding="none">
                        {showHeader && (
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={{borderRight:"1px solid #e0e0e0", width: "25%"}}> 
                                        <span style={{paddingLeft:"1rem", userSelect: "none"}}>Issue</span>
                                    </TableCell>
                                    <TableCell>
                                        <span style={{paddingLeft:"1rem", userSelect: "none"}}>Solution</span>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                        )}
                        <TableBody>
                            <TableRow>
                                <TableCell sx={{borderRight:"1px solid #e0e0e0", width: "25%", borderBottom:"unset"}} >
                                    <div style={{userSelect: "none"}}>{`${index+1}. ${point.label}`}</div>
                                </TableCell>
                                <TableCell sx={{borderBottom:"unset"}}>
                                    <div style={{userSelect: "none"}} dangerouslySetInnerHTML={createUnorderedListMarkup(point.unorderedList)}/>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </Stack>
            </TableCell>
            <TableCell>
                    {pointResponse.checked ? "Yes" : "No"}
                </TableCell>
                <TableCell>
                    {EmailToNameString(pointResponse.answeredBy)}
                </TableCell>
                <TableCell>
                    {pointResponse.timeAnswered && new Date(pointResponse.timeAnswered).toLocaleDateString(undefined, dateTimeOptions)}
                </TableCell>
        </TableRow>
    )
}

export default TroubleShootingPoint