import { TableCell, TableRow } from '@mui/material';
import React from 'react'
import { dateTimeOptions } from 'src/config/constants';
import { LearningDocumentSubpoint } from 'src/dtos/Training/LearningDocument.dto';
import { LearningDocumentSubpointResponse } from 'src/dtos/Training/LearningDocumentResponse.dto';
import { EmailToNameString } from 'src/Utils/helperFunc';

type Props = {
    pointIndex: number;
    index: number;
    subpoint: LearningDocumentSubpoint;
    subpointResponse: LearningDocumentSubpointResponse;
    allIconless?: boolean;
}
function Subpoint({pointIndex, index, subpoint, subpointResponse, allIconless}:Props) {
    return (
        <TableRow>
            <TableCell sx={{paddingLeft:`${allIconless === false ? "8.25rem" : "4rem"}`}}>
                {`${pointIndex+1}.${index+1}. ${subpoint.label}`}
            </TableCell>
            <TableCell>
                {subpointResponse.checked ? "Yes" : "No"}
            </TableCell>
            <TableCell>
                {EmailToNameString(subpointResponse.answeredBy)}
            </TableCell>
            <TableCell>
                {subpointResponse.timeAnswered && new Date(subpointResponse.timeAnswered).toLocaleDateString(undefined, dateTimeOptions)}
            </TableCell>
        </TableRow>
    )
}

export default Subpoint