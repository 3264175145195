import { LoadingButton } from '@mui/lab'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, TextField } from '@mui/material'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import React, { useState } from 'react'
import { MessageItem } from 'src/components/errorHandlingSnackbar'
import { CommentDto } from 'src/dtos/Comment.dto'
import TrainingManualEvidenceService from 'src/Services/Training/ManualEvidenceService'

type Props = {
    id: string
    setMessageItem: React.Dispatch<React.SetStateAction<MessageItem>>
}
function MoreEvidenceButton({id, setMessageItem}:Props) {
    const [open, setOpen] = useState(false)
    const [evidenceRequiredMessage, setEvidenceRequiredReason] = useState("")
    const queryClient = useQueryClient()

    const moreEvidenceRequiredMutation = useMutation({
        mutationFn: (id: string) => {
            return TrainingManualEvidenceService.RejectMoreEvidenceRequired(id)
        },
        onSuccess: (data) => {
            setMessageItem({successMessage: "Rejected - More evidence required successfully!"})
            //update query data to newly update values
            queryClient.setQueryData(["TrainingManualEvidence.Get", id], data.data)
        },
        onError: (error) => {
            setMessageItem({error: error})
        }
    })

    const addEvidenceRequiredComment = useMutation({
        mutationFn: ({id, comment}:{id: string, comment: CommentDto}) => {
            return TrainingManualEvidenceService.AddComment(id, comment)
        },
        onSuccess: () => {
            setMessageItem({successMessage: "Rejected - More evidence required successfully!"})
            
            queryClient.invalidateQueries({queryKey: ["comments", `manualEvidence_${id}`,], exact: false})
            handleClose()
            moreEvidenceRequiredMutation.mutate(id)
        },
        onError: (error) => {
            setMessageItem({error: error})
        }
    })

    const handleClose = () => {
        setOpen(false)
    }

    const handleButtonClick = () => {
        setOpen(true)
    }

    const handleRejectClick = () => {
        if(evidenceRequiredMessage.length === 0){
            setMessageItem({errorMessage: "Evidence Required field is required"})
            return
        }
        const comment: CommentDto = {
            id: "00000000-0000-0000-0000-000000000000",
            description: `Rejected More Evidence Required: ${evidenceRequiredMessage}`,
            enabled: true
        }
        addEvidenceRequiredComment.mutate({id, comment})
            
    }
    return (
        <div>
             <LoadingButton
                variant="contained"
                color="warning"
                onClick={handleButtonClick}
                loading={moreEvidenceRequiredMutation.isPending}
            >
                Reject - More Evidence Required
            </LoadingButton>
            <Dialog
                open={open}
                onClose={handleClose}
            >
                <DialogTitle>
                    Reject - More Evidence Required
                </DialogTitle>
                <DialogContent>
                    <Stack>
                        Evidence required to be approved:
                        <TextField
                            size="small"
                            multiline
                            value={evidenceRequiredMessage}
                            onChange={(e) => setEvidenceRequiredReason(e.target.value)}
                            rows={2}
                            sx={{width:"34rem"}}
                        />
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>
                       Cancel 
                    </Button>
                    <LoadingButton 
                        variant='contained'
                        onClick={handleRejectClick} 
                        loading={moreEvidenceRequiredMutation.isPending}   
                    >
                        Reject
                    </LoadingButton>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default MoreEvidenceButton