import axios, { AxiosResponse } from "axios";
import { GetAuthorisation } from "src/Utils/authUtils";
import { ListResponseDto } from "src/dtos/ListResponse.dto";
import { ListParameters, SortDirection } from "../ListParameters";
import { NameDto } from "src/dtos/NameDto.dto";
import qs from "qs";
import { TeamMemberRole } from "src/dtos/HumanResources/Role.dto";
import { TeamMemberCompletedTraining } from "src/dtos/HumanResources/TeamMember/TeamMemberTraining.dto";
import { TeamMemberAchievedCompetenciesDto } from "src/dtos/HumanResources/TeamMember/TeamMemberAchievedCompetenciesDto.dto";
import { TeamMember, MicrosoftUser } from "src/dtos/HumanResources/TeamMember/TeamMember.dto";
import { TeamMemberTrainingGroup, IntakeTypeDto } from "src/dtos/HumanResources/TeamMember/TeamMemberTrainingGroup.dto";
import { TeamMemberTrainingsFacilitatedDto } from "src/dtos/HumanResources/TeamMember/TeamMemberTrainingFacilitatedDto.dto";

const apiBaseUrl = process.env.REACT_APP_API_BASE_URL + "/TeamMember";
const debug = process.env.REACT_APP_DEBUG;

const emptyData: ListResponseDto = {
    data: [],
    totalRecords: 0
}

export default class TeamMemberService {
    static GetDefaultValues(){
        const defaultValues: TeamMember = {
            id:"00000000-0000-0000-0000-000000000000",
            firstName:"",
            lastName: "",
            email: "",
            mobile: "",
            departmentId: "",
            dietaryRequirements: [],
            trainingGroups: [],
            resourceAccesses: [],
            employmentPeriods: [],
            enabled: true
        }
        return defaultValues;
    }

    static async GetClaims(): Promise<any> {
        try {
            const authorization = await GetAuthorisation();
            const response: AxiosResponse = await axios.get(`${apiBaseUrl}/Claims`, {
                headers: {
                    Authorization: authorization
                }
            })
            return response.data
        } catch (error: any) {
            debug && console.log(error)
            throw error;
        }
    }
    
    static async GetMicrosoftUsers(): Promise<MicrosoftUser[]> {
        try {
            const authorization = await GetAuthorisation();
            const response: AxiosResponse = await axios.get(`${apiBaseUrl}/getMicrosoftUsers`, {
                headers: {
                    Authorization: authorization
                }
            })
            return response.data
        } catch (error: any) {
            debug && console.log(error)
            throw error;
        }
    }

    static async Get(id: string): Promise<TeamMember> {
        try {
            const authorization = await GetAuthorisation();
            const response: AxiosResponse<TeamMember> = await axios.get<TeamMember>(`${apiBaseUrl}/get/${id}`, {headers: {Authorization: authorization}});
            return response.data;
        } catch (error: any) {
            debug && console.log(error)
            throw error;
        }
    }

    static async GetList(listParam?: ListParameters, searchTerm?: string, includeDisabled?: boolean): Promise<ListResponseDto> {
        try {
            const authorization = await GetAuthorisation();

            const response: AxiosResponse<ListResponseDto> = await axios.get<ListResponseDto>(`${apiBaseUrl}/GetList`, {
                headers: {
                    Authorization: authorization
                },
                params: {
                    page: listParam?.page,
                    pageSize: listParam?.pageSize,
                    sortBy : listParam?.sortBy,
                    sortDirection: listParam?.sortDirection === SortDirection.Ascending ? 0 : listParam?.sortDirection === SortDirection.Descending ? 1 : undefined,
                    searchTerm: searchTerm,
                    includeDisabled: includeDisabled  
                }
            });
            return response.data;
        } catch(error: any) {
            if(error.response !== undefined) {
                if(error.response.status != null && error.response.status === 404) {
                    return emptyData
                }
            }
            debug && console.log(error)
            throw error
        }
    }

    static async GetNames(departments?: NameDto[], parentTrainingGroups?: NameDto[], justTrainersAssessors?: boolean): Promise<NameDto[]> {
        try {
            const authorization = await GetAuthorisation();
            const response: AxiosResponse<NameDto[]> = await axios.get<NameDto[]>(`${apiBaseUrl}/GetNames`, {
                headers: {
                    Authorization: authorization
                },
                params: {
                    departmentIds: departments?.map((dep) => dep.id),
                    parentTrainingGroupIds: parentTrainingGroups?.map((ptg) => ptg.id),
                    justTrainersAssessors: justTrainersAssessors
                },
                paramsSerializer: params => {
                    return qs.stringify(params, {arrayFormat: 'repeat'});
                }
            });
            return response.data
        } catch(error: any) {
            debug && console.log(error)
            throw error;
        }
    }

    static async GetTrainableTeamMembers(): Promise<NameDto[]> {
        try {
            const authorization = await GetAuthorisation();
            const response: AxiosResponse<NameDto[]> = await axios.get<NameDto[]>(`${apiBaseUrl}/GetTrainableTeamMembers`, {
                headers: {
                    Authorization: authorization
                }
            });
            return response.data
        } catch(error: any) {
            debug && console.log(error)
            throw error;
        }
    }

    static async GetTrainersTrainingGroups(teamMembers?: NameDto[], departments?: NameDto[]): Promise<TeamMemberTrainingGroup[]> {
        try {
            const authorization = await GetAuthorisation();
            const response: AxiosResponse<TeamMemberTrainingGroup[]> = await axios.get<TeamMemberTrainingGroup[]>(`${apiBaseUrl}/GetTrainersTrainingGroups`, {
                headers: {
                    Authorization: authorization
                },
                params: {
                    teamMemberIds: teamMembers?.map((tm) => tm.id),
                    departmentIds: departments?.map((dep) => dep.id)
                },
                paramsSerializer: params => {
                    return qs.stringify(params, {arrayFormat: 'repeat'});
                }
            });
            return response.data
        } catch(error: any) {
            debug && console.log(error)
            throw error;
        }
    }

    static async GetTraineesTrainingGroups(teamMembers?: NameDto[], departments?: NameDto[], intakes?: IntakeTypeDto[]): Promise<TeamMemberTrainingGroup[]> {
        try {
            const authorization = await GetAuthorisation();
            const response: AxiosResponse<TeamMemberTrainingGroup[]> = await axios.get<TeamMemberTrainingGroup[]>(`${apiBaseUrl}/GetTraineesTrainingGroups`, {
                headers: {
                    Authorization: authorization
                },
                params: {
                    teamMemberIds: teamMembers?.map((tm) => tm.id),
                    departmentIds: departments?.map((ptg) => ptg.id),
                    intakes: intakes
                },
                paramsSerializer: params => {
                    return qs.stringify(params, {arrayFormat: 'repeat'});
                }
            });
            return response.data
        } catch(error: any) {
            debug && console.log(error)
            throw error;
        }
    }

    static async GetRoles(id: string, includeDisabled?: boolean): Promise<TeamMemberRole[]>{
        try {
            const authorization = await GetAuthorisation();
            const response: AxiosResponse<TeamMemberRole[]> = await axios.get<TeamMemberRole[]>(`${apiBaseUrl}/GetRoles/${id}`, {
                headers: {
                    Authorization: authorization
                },
                params: {
                    includeDisabled: includeDisabled
                }
            });
            return response.data
        } catch (error: any) {
            debug && console.log(error)
            throw error;
        }
    }

    static async GetDepartmentRoles(id: string, includeDisabled?: boolean): Promise<TeamMemberRole[]>{
        try {
            const authorization = await GetAuthorisation();
            const response: AxiosResponse<TeamMemberRole[]> = await axios.get<TeamMemberRole[]>(`${apiBaseUrl}/GetDepartmentRoles/${id}`, {
                headers: {
                    Authorization: authorization
                },
                params: {
                    includeDisabled: includeDisabled
                }
            });
            return response.data
        } catch (error: any) {
            debug && console.log(error)
            throw error;
        }
    }

    static async GetCompletedTraining(id: string, financialYear: string): Promise<TeamMemberCompletedTraining> {
        try {
            const authorization = await GetAuthorisation();
            const response: AxiosResponse<TeamMemberCompletedTraining> = await axios.get<TeamMemberCompletedTraining>(`${apiBaseUrl}/GetCompletedTraining/${id}`, {
                headers: {
                    Authorization: authorization
                },
                params: {
                    financialYear: financialYear
                }
            });
            return response.data
        } catch (error: any) {
            debug && console.log(error)
            throw error;
        }
    }

    static async GetAchievedCompetencies(id: string, financialYear: string): Promise<TeamMemberAchievedCompetenciesDto> {
        try {
            const authorization = await GetAuthorisation();
            const response: AxiosResponse<TeamMemberAchievedCompetenciesDto> = await axios.get<TeamMemberAchievedCompetenciesDto>(`${apiBaseUrl}/GetAchievedCompetencies/${id}`, {
                headers: {
                    Authorization: authorization
                },
                params: {
                    financialYear: financialYear
                }
            });
            return response.data
        } catch (error: any) {
            debug && console.log(error)
            throw error;
        }
    }

    static async GetTrainingFacilitated(id: string, financialYear: string): Promise<TeamMemberTrainingsFacilitatedDto> {
        try {
            const authorization = await GetAuthorisation();
            const response: AxiosResponse<TeamMemberTrainingsFacilitatedDto> = await axios.get<TeamMemberTrainingsFacilitatedDto>(`${apiBaseUrl}/GetTrainingFacilitated/${id}`, {
                headers: {
                    Authorization: authorization
                },
                params: {
                    financialYear: financialYear
                }
            });
            return response.data
        } catch (error: any) {
            debug && console.log(error)
            throw error;
        }
    }

    static async Create(data: TeamMember): Promise<AxiosResponse> {
        try {
            const authorization = await GetAuthorisation();
            const response: AxiosResponse = await axios.post<AxiosResponse>(`${apiBaseUrl}/Create`,data,{
                headers: {
                    Authorization: authorization
                }
            });
            return response
        } catch(error: any) {
            debug && console.log(error)
            throw error;
        }
    }

    static async Update(id: string, data: TeamMember): Promise<AxiosResponse> {
        try {
            const authorization = await GetAuthorisation();
            const response: AxiosResponse = await axios.put<AxiosResponse>(`${apiBaseUrl}/Update/${id}`, data, {
                headers: {
                    Authorization: authorization
                }
            });
            return response;
        } catch (error: any) {
            debug && console.log(error)
            throw error;
        }
    }

    static async AddTrainerTrainingGroup(id: string, trainingGroupIds: string[]): Promise<TeamMemberTrainingGroup> {
        try {
            const authorization = await GetAuthorisation();
            const response: AxiosResponse<TeamMemberTrainingGroup> = await axios.put<TeamMemberTrainingGroup>(`${apiBaseUrl}/AddTrainerTrainingGroup/${id}`, trainingGroupIds, {
                headers: {
                    Authorization: authorization
                },
            });
            return response.data;
        } catch (error: any) {
            debug && console.log(error)
            throw error;
        }
    }

    static async RemoveTrainerTrainingGroup(id: string, trainingGroupIds: string[]): Promise<TeamMemberTrainingGroup> {
        try {
            const authorization = await GetAuthorisation();
            const response: AxiosResponse = await axios.put<AxiosResponse<TeamMemberTrainingGroup>>(`${apiBaseUrl}/RemoveTrainerTrainingGroup/${id}`, trainingGroupIds, {
                headers: {
                    Authorization: authorization
                },
            });
            return response.data;
        } catch (error: any) {
            debug && console.log(error)
            throw error;
        }
    }

    static async EnableRole(id: string, roleId: string): Promise<AxiosResponse<TeamMember>> {
        try {
            const authorization = await GetAuthorisation(); 
            const response: AxiosResponse = await axios.put(`${apiBaseUrl}/EnableRole/${id}`, null, {
                headers: {
                    Authorization: authorization
                },
                params: {
                    roleId: roleId
                }
            });
            return response
        } catch (error: any) {
            debug && console.log(error)
            throw error;
        }
    }

    static async DisableRole(id: string, roleId: string): Promise<AxiosResponse<TeamMember>> {
        try {
            const authorization = await GetAuthorisation(); 
            const response: AxiosResponse = await axios.put(`${apiBaseUrl}/DisableRole/${id}`, null, {
                headers: {
                    Authorization: authorization
                },
                params: {
                    roleId: roleId
                }
            });
            return response
        } catch (error: any) {
            debug && console.log(error)
            throw error;
        }
    }

    static async EnableDepartmentRole(id: string, departmentId: string): Promise<AxiosResponse<TeamMember>> {
        try {
            const authorization = await GetAuthorisation(); 
            const response: AxiosResponse = await axios.put(`${apiBaseUrl}/EnableDepartmentRole/${id}`, null, {
                headers: {
                    Authorization: authorization
                },
                params: {
                    departmentId: departmentId
                }
            });
            return response
        } catch (error: any) {
            debug && console.log(error)
            throw error;
        }
    }

    static async DisableDepartmentRole(id: string, departmentId: string): Promise<AxiosResponse<TeamMember>> {
        try {
            const authorization = await GetAuthorisation(); 
            const response: AxiosResponse = await axios.put(`${apiBaseUrl}/DisableDepartmentRole/${id}`, null, {
                headers: {
                    Authorization: authorization
                },
                params: {
                    departmentId: departmentId
                }
            });
            return response
        } catch (error: any) {
            debug && console.log(error)
            throw error;
        }
    }

    static async AddTraineeTrainingGroup(id: string, parentTrainingGroupId: string): Promise<TeamMemberTrainingGroup> {
        try {
            const authorization = await GetAuthorisation();
            const response: AxiosResponse<TeamMemberTrainingGroup> = await axios.put<TeamMemberTrainingGroup>(`${apiBaseUrl}/AddTraineeTrainingGroup/${id}`, null, {
                headers: {
                    Authorization: authorization
                },
                params: {
                    parentTrainingGroupId: parentTrainingGroupId
                }
            });
            return response.data;
        } catch (error: any) {
            debug && console.log(error)
            throw error;
        }
    }

    static async RemoveTraineeTrainingGroup(id: string, parentTrainingGroupId: string): Promise<TeamMemberTrainingGroup> {
        try {
            const authorization = await GetAuthorisation();
            const response: AxiosResponse = await axios.put<AxiosResponse<TeamMemberTrainingGroup>>(`${apiBaseUrl}/RemoveTraineeTrainingGroup/${id}`, null, {
                headers: {
                    Authorization: authorization
                },
                params: {
                    parentTrainingGroupId: parentTrainingGroupId
                }
            });
            return response.data;
        } catch (error: any) {
            debug && console.log(error)
            throw error;
        }
    }

    static async Enable(id: string): Promise<AxiosResponse<TeamMember>> {
        try {
            const authorization = await GetAuthorisation(); 
            const response: AxiosResponse = await axios.put(`${apiBaseUrl}/Enable/${id}`, null, {
                headers: {
                    Authorization: authorization
                },
            });
            return response
        } catch (error: any) {
            debug && console.log(error)
            throw error;
        }
    }

    static async Disable(id: string): Promise<AxiosResponse<TeamMember>> {
        try {
            const authorization = await GetAuthorisation(); 
            const response: AxiosResponse = await axios.put(`${apiBaseUrl}/Disable/${id}`, null, {
                headers: {
                    Authorization: authorization
                },
            });
            return response
        } catch (error: any) {
            debug && console.log(error)
            throw error;
        }
    }
}