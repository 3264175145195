import axios, { AxiosResponse } from "axios";
import { ListParameters, SortDirection } from "src/Services/ListParameters";
import { GetAuthorisation } from "src/Utils/authUtils";
import { LearningDocumentPoint, LearningDocumentPointType, LearningDocumentSubtopic, LearningDocumentSubpoint, LearningDocumentTopic, LearningDocumentVersion } from "src/dtos/Training/LearningDocument.dto";
import { ListResponseDto } from "src/dtos/ListResponse.dto";
import { ChecklistVersionName } from "src/dtos/Checklists/MaintenanceChecklist.dto";
import { Status } from "src/dtos/Statuses";
import { FileMetadata } from "src/dtos/FileMetadata.dto";

const apiBaseUrl = process.env.REACT_APP_API_BASE_URL + "/LearningDocumentVersion";
const debug = process.env.REACT_APP_DEBUG;

const emptyData: ListResponseDto = {
    data: [],
    totalRecords: 0
}

export default class LearningDocumentVersionService {
    static GetDefaultVersionValues(){
        const defaultVersionValues: LearningDocumentVersion = {
            id: "00000000-0000-0000-0000-000000000000",
            versionLabel: "",
            versionDescription: "",
            status: Status.Draft,
            forceWorkflow: true,
            enabled: true,
            learningDocumentId: "00000000-0000-0000-0000-000000000000",
            topics: []
        }
        return defaultVersionValues;
    }

    static GetDefaultTopicValues(): LearningDocumentTopic {
        const defaultTopicValues: LearningDocumentTopic = {
            id: 0,
            label: "",
            estimateCompletionMinutes: undefined,
            sortOrder: 0,
            subtopics: [this.GetDefaultSubTopicValues()]
        }
        return defaultTopicValues;
    }

    static GetDefaultSubTopicValues(): LearningDocumentSubtopic {
        const defaultSubTopicValues: LearningDocumentSubtopic = {
            id: 0,
            label: "",
            sortOrder: 0,
            points: [this.GetDefaultPointValue()]
        }
        return defaultSubTopicValues;
    }

    static GetDefaultPointValue(): LearningDocumentPoint {
        const defaultPointValue: LearningDocumentPoint = {
            id: 0,
            label: "",
            sortOrder: 0,
            type: LearningDocumentPointType.standard,
            subpoints: []
        }
        return defaultPointValue;
    }

    static GetDefaultSubpointValue(): LearningDocumentSubpoint {
        const defaultSubpointValue: LearningDocumentSubpoint = {
            id: 0,
            label: "",
            sortOrder: 0
        }
        return defaultSubpointValue;
    }

    
    static async Get(id: string) : Promise<LearningDocumentVersion> {
        try {
            const authorization = await GetAuthorisation();
            const response: AxiosResponse<LearningDocumentVersion> = await axios.get<LearningDocumentVersion>(`${apiBaseUrl}/get/${id}`, {
                headers: {
                    Authorization: authorization
                }
            })
            return response.data;
        } catch (error: any) {
            debug && console.log(error);
            throw error;
        }
    }

    static async GetList(learningDocumentId: string, listParam?: ListParameters, searchTerm?: string, status?: Status): Promise<ListResponseDto>{
        try {
            const authorization = await GetAuthorisation();
            const response: AxiosResponse<ListResponseDto> = await axios.get<ListResponseDto>(`${apiBaseUrl}/GetList/${learningDocumentId}`, {
                headers: {
                    Authorization: authorization
                },
                params: {
                    page: listParam?.page,
                    pageSize: listParam?.pageSize,
                    sortBy : listParam?.sortBy,
                    sortDirection: listParam?.sortDirection === SortDirection.Ascending ? 0 : listParam?.sortDirection === SortDirection.Descending ? 1 : undefined,
                    searchTerm: searchTerm,
                    learningDocumentId: learningDocumentId,
                    status: status,                
                }
            });
            return response.data;
        } catch (error: any) {
            if(error.response !== undefined){
                if(error.response.status != null && error.response.status === 404){
                    return emptyData
                }
            }
            debug && console.log(error)
            throw error;
        }
    }
    
    static async GetNames(learningDocumentId: string) : Promise<ChecklistVersionName[]> {
        try {
            const authorization = await GetAuthorisation();
            const response: AxiosResponse<ChecklistVersionName[]> = await axios.get<ChecklistVersionName[]>(`${apiBaseUrl}/GetNames/${learningDocumentId}`, {
                headers: {
                    Authorization: authorization
                },
            });
            return response.data
        } catch (error: any) {
            debug && console.log(error)
            throw error;
        }
        
    }
    
    static async GetCopy(versionId: string): Promise<LearningDocumentVersion>{
        try {
            const authorization = await GetAuthorisation();
            const resposne: AxiosResponse<LearningDocumentVersion> = await axios.get<LearningDocumentVersion>(`${apiBaseUrl}/GetCopy/${versionId}`, {
                headers: {
                    Authorization: authorization
                },
            });
            return resposne.data;
        } catch (error: any) {
            debug && console.log(error)
            throw error;
        }
    }

    static async Publish(id: string) : Promise<AxiosResponse> {
        try {
            const authorization = await GetAuthorisation();
            const response: AxiosResponse = await axios.put<AxiosResponse>(`${apiBaseUrl}/Publish/${id}`, null, {
                headers: {
                    Authorization: authorization
                },
            });
            return response
        } catch (error: any) {
            debug && console.log(error)
            throw error;
        }
    }

    static async Create(learningDocumentVersionData: LearningDocumentVersion): Promise<AxiosResponse> {
        try {
            const authorization = await GetAuthorisation();
            const response: AxiosResponse = await axios.post<AxiosResponse>(`${apiBaseUrl}/Create`, learningDocumentVersionData, {
                headers: {
                    Authorization: authorization
                },
            });
            return response
        } catch (error: any) {
            debug && console.log(error)
            throw error;
        }
    }

    static async Update(id: string, learningDocumentVersionData: LearningDocumentVersion): Promise<AxiosResponse> {
        try {
            const authorization = await GetAuthorisation();
            const response: AxiosResponse = await axios.put<AxiosResponse>(`${apiBaseUrl}/Update/${id}`, learningDocumentVersionData, {
                headers: {
                    Authorization: authorization
                },
            });
            return response
        } catch (error: any) {
            debug && console.log(error);
            throw error;
        }
    }

    static async AddPointFile(id: string, pointId: number, file: File): Promise<AxiosResponse<FileMetadata>> {
        try {
            const authorization = await GetAuthorisation();

            const formData = new FormData();
            formData.append("file", file);
            
            const response: AxiosResponse = await axios.post<AxiosResponse>(`${apiBaseUrl}/AddPointFile/${id}`, formData, {
                headers: {
                    Authorization: authorization,
                    'Content-Type': 'multipart/form-data'
                },
                params: {
                    pointId: pointId
                }
            });
            return response
        } catch (error: any) {
            debug && console.log(error);
            throw error;
        }
    }

    static async DeletePointFile(id: string, pointId: number): Promise<AxiosResponse> {
        try {
            const authorization = await GetAuthorisation();
            const response: AxiosResponse = await axios.delete<AxiosResponse>(`${apiBaseUrl}/DeletePointFile/${id}`, {
                headers: {
                    Authorization: authorization
                },
                params: {
                    pointId: pointId
                }
            });
            return response
        } catch (error: any) {
            debug && console.log(error);
            throw error;
        }
    }
}