import { Stack, FormControl, InputLabel, Select, MenuItem, Typography, SelectChangeEvent, Table, TableHead, TableBody, TableRow, TableCell, IconButton } from '@mui/material'
import { useMutation, useQuery } from '@tanstack/react-query'
import { useFormik } from 'formik'
import React, { useEffect } from 'react'
import Attachments from 'src/components/Attachments/Attachments'
import EditableLabel from 'src/components/EditableLabel/EditableLabel'
import { MessageItem } from 'src/components/errorHandlingSnackbar'
import { JournalEntryType, JournalEntryTypeString, JournalEntryStatus, JournalEntryStatusString, JournalEntryDto } from 'src/dtos/Checklists/AttractionInspections/Journal.dto'
import { EmailToNameString, guidRegex } from 'src/Utils/helperFunc'
import * as yup from 'yup'
import DuplicateEntry from './DuplicateEntry'
import IssueOrFaultRecordEntry from './IssueOrFaultRecordEntry'
import OutOfServiceEntry from './OutOfServiceEntry'
import ServiceTaskEntry from './ServiceTaskEntry'
import JournalEntryService, { journalEntryTypesWithStatus } from 'src/Services/Checklists/Inspections/AttractionJournalEntryService'
import { NameDto } from 'src/dtos/NameDto.dto'
import { Link, useLocation } from 'react-router-dom'
import { LoadingButton } from '@mui/lab'
import { dateOptions } from 'src/config/constants'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ThirdPartyAuditEntry from './ThirdPartyAuditEntry'
import Comments from 'src/components/Comments/Comments'

type Props = {
    id: string;
    validationSchema: yup.AnySchema
    setMessageItem: React.Dispatch<React.SetStateAction<MessageItem>>
    attractionName: NameDto
    backLink: string;
    onBackClick?: () => void;
    demoMode?: boolean;
}
function JournalEntryEdit({id, validationSchema, setMessageItem, attractionName, backLink, onBackClick, demoMode}: Props) {
    const location = useLocation()

    //#region queries
    const entryQuery = useQuery({
        queryKey: ["attractionJournalEntry", id],
        queryFn: async () => {
            if(guidRegex.test(id)){
                return await JournalEntryService.Get(id)
            }
            return null
        }
    })

    const updateMutation = useMutation({
        mutationFn: ({id, values}: {id: string, values: JournalEntryDto}) => {
            return JournalEntryService.Update(id, values)
        },
        onSuccess: (data) => {
            setMessageItem({successMessage: "Entry updated successfully!"})
            formik.setValues(data.data)
        },
        onError: (error) => {
            setMessageItem({error: error})
        }
    })

    

    //Handle query errors

    useEffect(() => {
        if(entryQuery.isError){
            setMessageItem({error: entryQuery.error})
        }
    }, [entryQuery.error, entryQuery.isError, setMessageItem])
    //#endregion

    const formik = useFormik({
        enableReinitialize: true,
        validationSchema: validationSchema,
        initialValues: entryQuery.data ?? JournalEntryService.GetDefaultValues(),
        onSubmit: (values) => {
            if(!demoMode)
                updateMutation.mutate({id: id, values: values})
        }
    })

    const handleSaveClick = () => {
        formik.handleSubmit();
    }

    const handleTypeChange = (event: SelectChangeEvent<JournalEntryType>) => {
        const newType: JournalEntryType = event.target.value as JournalEntryType
        
        if(journalEntryTypesWithStatus.includes(newType)){
            formik.setFieldValue("status", JournalEntryStatus.outstanding)
        } else {
            formik.setFieldValue("status", JournalEntryStatus.NA)
        }
        formik.handleChange(event)
    }
    return (
    <>
        <Stack direction={"row"} spacing={2} sx={{paddingTop:"1rem", paddingLeft:"1rem"}}>
            <div>
                <Link to={backLink}>
                    <IconButton onClick={() => onBackClick && onBackClick()}>
                        <ArrowBackIcon fontSize='large'/>
                    </IconButton>
                </Link>
            </div>
            <div>
                <Link to={location.pathname.replace(`/${id}`, "")} onClick={() => onBackClick && onBackClick()}>
                    <Typography sx={{textAlign:"center"}} variant='h5'>{attractionName.label}</Typography>        
                </Link>
            </div>
            <div>
                <Typography variant='h5'> {"/"}</Typography>
            </div>
            <div style={{marginLeft:"1rem"}}>
                <Link to={location.pathname}>
                    <Typography sx={{textAlign:"center"}} variant='h5'>{formik.values.entryId}</Typography>        
                </Link>
            </div>
        </Stack>
        <Stack sx={{padding:"1rem"}} spacing={1}>
        <div style={{width:"100%"}}>
            <EditableLabel 
                value={formik.values.label}
                setValue={(newValue: string) => formik.setFieldValue("label", newValue)}
                onBlur={() => {formik.setFieldTouched('label', true, false)}}
                placeholder={"Click here to enter Entry Summary"}
                typographyProps={{
                    variant:"h5"
                }}
                helperText={formik.touched.label && formik.errors.label}
                error={formik.touched.label && Boolean(formik.errors.label)}                
            />
        </div>
        <Stack direction={"row"} spacing={1}>
            <FormControl fullWidth>
                <InputLabel id="entry-type-label">Type</InputLabel>
                <Select
                    id="type"
                    name="type"
                    labelId="entry-type-label"
                    label="Type"
                    value={formik.values.type}
                    onChange={handleTypeChange}
                    onBlur={formik.handleBlur}
                    size={"small"}
                >
                    <MenuItem value={JournalEntryType.serviceTask}>{JournalEntryTypeString[JournalEntryType.serviceTask]}</MenuItem>
                    <MenuItem value={JournalEntryType.issueFaultRecord}>{JournalEntryTypeString[JournalEntryType.issueFaultRecord]}</MenuItem>
                    <MenuItem value={JournalEntryType.outOfService}>{JournalEntryTypeString[JournalEntryType.outOfService]}</MenuItem>
                    <MenuItem value={JournalEntryType.duplicateEntry}>{JournalEntryTypeString[JournalEntryType.duplicateEntry]}</MenuItem>
                    <MenuItem value={JournalEntryType.ThirdPartyAuditFinding}>{JournalEntryTypeString[JournalEntryType.ThirdPartyAuditFinding]}</MenuItem>

                </Select>
            </FormControl>
            {journalEntryTypesWithStatus.includes(formik.values.type) && (
                <FormControl fullWidth>
                    <InputLabel id="entry-status-label">Status</InputLabel>
                    <Select
                        id="status"
                        name="status"
                        labelId="entry-status-label"
                        label="Status"
                        value={formik.values.status}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        size={"small"}
                    >
                        <MenuItem value={JournalEntryStatus.outstanding}>{JournalEntryStatusString[JournalEntryStatus.outstanding]}</MenuItem>
                        <MenuItem value={JournalEntryStatus.completed}>{JournalEntryStatusString[JournalEntryStatus.completed]}</MenuItem>
                    </Select>
                </FormControl>        
            )}
        </Stack>
        {formik.values.relatedInspectionQuestionLabel && (
            <>
                <Typography>
                    {`Reported From Question: `}
                    <i>{formik.values.relatedInspectionQuestionLabel}</i>
                </Typography>
            </>
        )}
        
        {formik.values.type === JournalEntryType.serviceTask && (
            <ServiceTaskEntry
                formik={formik}
                attractionId={attractionName.id}
            />
        )}
        {formik.values.type === JournalEntryType.issueFaultRecord && (
            <IssueOrFaultRecordEntry
                formik={formik}
            />
        )}
        {formik.values.type === JournalEntryType.outOfService && (
            <OutOfServiceEntry
                formik={formik}
                attractionId={attractionName.id}
            />
        )}
        {formik.values.type === JournalEntryType.duplicateEntry && (
            <DuplicateEntry
                formik={formik}
                attractionId={attractionName.id}
            />
        )}
        {formik.values.type === JournalEntryType.ThirdPartyAuditFinding && (
            <ThirdPartyAuditEntry
                formik={formik}
            />
        )}

        <span style={{display:"flex", flexDirection: "row", gap:"1rem", justifyContent: "end", padding:"1rem", paddingTop:"0"}}>
            <LoadingButton 
                variant='contained' 
                disabled={!formik.dirty || demoMode} 
                onClick={handleSaveClick}
                loading={updateMutation.isPending}
            >
                Save
            </LoadingButton>
        </span>
        
        <Typography variant='h6'>Details</Typography>
        <Table size="small" sx={{ width:"fit-content" }}>
            <TableHead>
                <TableRow>
                    <TableCell>Action</TableCell>
                    <TableCell>Person</TableCell>
                    <TableCell>Time</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {formik.values.createdBy && (
                    <TableRow>
                        <TableCell>
                            Reported
                        </TableCell>
                        <TableCell>
                            {EmailToNameString(formik.values.createdBy??"")}
                        </TableCell>
                        <TableCell>
                            {formik.values.createdOn && new Date(formik.values.createdOn).toLocaleTimeString(undefined, dateOptions)}
                        </TableCell>
                    </TableRow>
                )}
                {formik.values.modifiedBy && (
                    <TableRow>
                        <TableCell>
                            Modified
                        </TableCell>
                        <TableCell>
                            {EmailToNameString(formik.values.modifiedBy??"")}
                        </TableCell>
                        <TableCell>
                            {formik.values.modifiedOn && new Date(formik.values.modifiedOn).toLocaleTimeString(undefined, dateOptions)}
                        </TableCell>
                    </TableRow>
                )}
                {formik.values.resolvedBy && (
                    <TableRow>
                        <TableCell>
                            Resolved
                        </TableCell>
                        <TableCell>
                            {EmailToNameString(formik.values.resolvedBy??"")}
                        </TableCell>
                        <TableCell>
                            {formik.values.resolvedOn && new Date(formik.values.resolvedOn).toLocaleTimeString(undefined, dateOptions)}
                        </TableCell>
                    </TableRow>
                )}
            </TableBody>
        </Table>

        
        <Typography variant='h6'>Attachments</Typography>
        <Attachments 
            setMessageItem={setMessageItem} 
            listIdentifier={formik.values.entryId}
            callService={(page: number, pageSize: number) => JournalEntryService.GetListAttachments(id, page, pageSize)} 
            addFileService={(file: File) => JournalEntryService.AddFile(id, file)}
            deleteFileService={(fileId: string) => JournalEntryService.DeleteFile(id, fileId)}
        />

        <Typography variant='h6'>Comments</Typography>
        <Comments
            queryKey={`journalEntry_${id}`}
            addCommentService={(comment) => JournalEntryService.AddComment(id, comment)}
            editCommentService={JournalEntryService.UpdateComment}
            getCommentsService={(listParams) => JournalEntryService.GetListComments(id, listParams)}
            setMessageItem={setMessageItem}
        />

    </Stack>
    </>
  )
}

export default JournalEntryEdit