import { ChildEntity } from "../AwTypes";
import { Status } from "../Statuses";
import { BaseModel } from "../BaseModel.dto";
import { Expiry } from "./Expiry.dto";
import { NameDto } from "../NameDto.dto";

export enum QuestionType {
    YesOrNo = 0,
    Alert = 1,
    Number = 2
}

export interface InspectionChecklistDto extends BaseModel {
    id: string;
    label: string;
    description?: string;
    expiry: Expiry;
    department?: NameDto | null;
    publishedVersionId?: string;
    attraction?: NameDto | null;
    expectedDurationSeconds?: number;
    documentationUrl?: string; //is actually from asset not saved on inspection
}

export interface ChecklistVersion extends BaseModel {
    id: string;
    versionLabel: string;
    versionDescription: string;
    status: Status;
    datePublished?: Date;
    publishedBy?: string;
    forceWorkflow: Boolean; //Allow sections to be completed out of order
}

export interface ChecklistVersionName {
    id: string;
    versionLabel: string;
}

export enum VersionRepeatType {
    AlwaysRepeat,
    UserSelected,
    UserSelectedOneOnly
}
export const VersionRepeatTypeString: string[] = [
    "Always Repeat",
    "User Selected",
    "User Selected One Only"
]
export interface InspectionChecklistVersion extends ChecklistVersion{
    repeatingComponentSetId?: number;
    repeatingComponentSet?: ChildEntity | null;
    repeatType: VersionRepeatType | null;
    sections: InspectionChecklistSection[];
    inspectionChecklistId: string;

    userSelectedComponent?: ChildEntity | null; //Component that is user selected within this inspection
}

export enum SectionRepeatType {
    AlwaysRepeat,
    UserSelected,
    Grouped
}

export const SectionRepeatTypeString: string[] = [
    "Always Repeat",
    "User Selected",
    "Grouped"
];

export interface ChecklistSection {
    id: number;
    label: string;
    sublabel?: string;
    sortOrder?: number;
}
export interface InspectionChecklistSection extends ChecklistSection {
    repeatingComponentSetId?: number | null;
    repeatingComponentSet?: ChildEntity | null;
    sectionRepeatType: SectionRepeatType | null;
    previousSectionId?: number;
    previousSection?: InspectionChecklistSection;
    questions: QuestionDto[];

    inspectionChecklistVersionId?: string;
}

export interface ChecklistQuestion {
    id: number;
    label: string;
    number?: number;
    additionalInformation?: string;
    type: QuestionType;
    minAcceptableValue?: number;
    maxAcceptableValue?: number;
    inputUnits?: string;
    sortOrder?: number;
}

export interface QuestionDto extends ChecklistQuestion {
    relatedComponentId?: number;
    relatedComponent?: ChildEntity | null;
    repeatOnComponentSet?: boolean;
    repeatOnComponentSetUserSelected?: boolean;
    previousQuestionId?: number;
    previousQuestion?: QuestionDto;

    inspectionChecklistSectionId?: number;
}
  

export interface VersionDto {
    id?: number;
    label: string;
    description?: string;
    status: Status.Published | Status.Draft | Status.Outdated;
    publishedDate?: Date;
}