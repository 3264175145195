import axios, { AxiosResponse } from "axios";
import { GetAuthorisation } from "src/Utils/authUtils";
import { NameDto } from "src/dtos/NameDto.dto";
import { DepartmentDto } from "src/dtos/Resources/Department.dto";

const apiBaseUrl = process.env.REACT_APP_API_BASE_URL + "/department";
const debug = process.env.REACT_APP_DEBUG;

export default class DepartmentService {
    static GetDefaultValues(){
        const defaultValues: DepartmentDto = {
            id: "",
            label: "",
            enabled: true
        }
        return defaultValues;
    }

    static async GetNames(): Promise<NameDto[]> {
        try {
            const authorization = await GetAuthorisation();
            const response: AxiosResponse<NameDto[]> = await axios.get<NameDto[]>(`${apiBaseUrl}/GetNames`, {
                headers: {
                    Authorization: authorization
                }
            });
            return response.data;
        } catch (error: any) {
            debug && console.log(error)
            throw error;
        }
    }
}