import React, { useEffect, useState } from 'react'
import RolesList from './Components/RolesList'
import { DragDropContext, DragStart, DropResult } from 'react-beautiful-dnd'
import { NameDto } from 'src/dtos/NameDto.dto'
import { reorder } from '../../Training/LearningDocuments/LearningDocumentCreator/Components/PointsCreator/helper'
import AssignableRolesList from './Components/AssignableRolesList'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import RoleService from 'src/Services/HumanResources/RoleService'
import { Autocomplete, Paper, Stack, TextField, Typography } from '@mui/material'
import RoleAssignmentService from 'src/Services/HumanResources/RoleAssignmentService'
import { useFormik } from 'formik'
import { LoadingButton } from '@mui/lab'
import { RoleAssignableRolesDto } from 'src/dtos/HumanResources/Role.dto'
import ErrorHandlingSnackbar, { MessageItem } from 'src/components/errorHandlingSnackbar'



function RoleAssignments() {
    const [draggingItem, setDraggingItem] = useState<NameDto | null>(null)
    const [grantingRolesFilter, setGrantingRolesFilter] = useState<NameDto[]>([])
    const [messageItem, setMessageItem] = useState<MessageItem>({})

    const queryClient = useQueryClient()

    const roleNamesQuery = useQuery({
        queryKey: ["Role.GetNames"],
        queryFn: async () => {
            return await RoleService.GetNames()
        }
    })

    const roleAssignmentsQuery = useQuery({
        queryKey: ["RoleAssignment.GetAssignments"],
        queryFn: async () => {
            return await RoleAssignmentService.GetAssignments()
        }
    })

    //Handle query errors
    useEffect(() => {
        if(roleNamesQuery.isError)
            setMessageItem({error: roleNamesQuery.error})
        if(roleAssignmentsQuery.isError)
            setMessageItem({error: roleAssignmentsQuery.error})
        
    }, [roleAssignmentsQuery.error, roleAssignmentsQuery.isError, roleNamesQuery.error, roleNamesQuery.isError])


    const updateMutation = useMutation({
        mutationFn: (values: RoleAssignableRolesDto[]) => {
            return RoleAssignmentService.UpdateAssignments(values)
        },
        onSuccess: (data) => {
            setMessageItem({successMessage: "Role Assignments updated successfully!"})
            queryClient.setQueryData(["RoleAssignment.GetAssignments"], data.data)
        },
        onError: (error) => {
            setMessageItem({error: error})
        }
    })

    const formik = useFormik({
        initialValues: roleAssignmentsQuery.data ?? [],
        enableReinitialize: true,
        onSubmit: (values) => {
            updateMutation.mutate(values)
        }
    })
    
    const allRoles = roleNamesQuery.data ?? [];

    const onDragStart = (start: DragStart, provided: any) => {
        const {source} = start;
        const sourceList = source.droppableId === "AssignableRoles" 
            ? allRoles 
            : formik.values.find(rar => rar.grantingRole.id === source.droppableId)?.assignableRoles || [];

        setDraggingItem(sourceList[source.index]);
    }

    const onDragEnd = (result: DropResult) => {
        setDraggingItem(null);

        const { destination, source } = result;
        // dropped outside the list
        if (!destination) return;  

        if(source.droppableId === "AssignableRoles" && source.droppableId === destination.droppableId){
            return
        }

        const sourceListIndex = formik.values.findIndex(rar => rar.grantingRole.id === source.droppableId)
        const nextListIndex = formik.values.findIndex(rar => rar.grantingRole.id === destination.droppableId)

        const current: NameDto[] =  source.droppableId === "AssignableRoles" ? allRoles : [...formik.values[sourceListIndex].assignableRoles];
        const next: NameDto[] = destination.droppableId === "AssignableRoles" ? allRoles : [...formik.values[nextListIndex].assignableRoles]
        const target: NameDto = source.droppableId === "AssignableRoles" ? allRoles[source.index] : current[source.index]

       
        //moving to same list
        if(source.droppableId === destination.droppableId){
            const reordered: NameDto[] = reorder(
                current,
                source.index,
                destination.index
            );

            formik.setFieldValue(`[${sourceListIndex}].assignableRoles`, reordered)
            return
        }
        
        //moving to different list 

        //remove from original if not assignableRoles
        if(source.droppableId !== "AssignableRoles")
            current.splice(source.index, 1);
        //insert into next if not assignableRoles
        if(destination.droppableId !== "AssignableRoles")
            next.splice(destination.index, 0, target)

        formik.setFieldValue(`[${sourceListIndex}].assignableRoles`, current)
        formik.setFieldValue(`[${nextListIndex}].assignableRoles`, next)

    }

    const handleRemoveRole = (grantingRoleId: string, roleId: string) => {
        const updatedRoles = formik.values.map(rar => 
            rar.grantingRole.id === grantingRoleId ? 
                {...rar, 
                    assignableRoles: rar.assignableRoles.filter(ar => ar.id !== roleId)
                }
            : rar
        );
        formik.setValues(updatedRoles)
    }
    return (
        <div>
            <DragDropContext 
                onDragStart={onDragStart}
                onDragEnd={onDragEnd}
            >
                <div style={{display:"flex", flexDirection:"row", gap:"1rem"}}>
                    <AssignableRolesList
                        droppableId={"AssignableRoles"}
                        roles={allRoles}
                    />
                    <Paper sx={{height:"fit-content", width:"calc(100% - 16rem)"}}>
                        <Stack direction={"row"} spacing={2} sx={{paddingLeft:"1rem", paddingTop:"1rem", paddingRight:"1rem"}}>
                            <Typography variant='h5' sx={{textWrap:"nowrap"}}>
                                Granting Roles
                            </Typography>
                            <Autocomplete
                                id="GrantingRoles"
                                options={allRoles}
                                multiple
                                limitTags={3}
                                value={grantingRolesFilter}
                                isOptionEqualToValue={(option, value) => option.id === value.id}
                                getOptionLabel={(option) => option.label ? option.label: ""}
                                onChange={(e, value) => setGrantingRolesFilter(value)}
                                size="small"
                                sx={{width:"100%"}}
                                renderInput={(params) => 
                                    <TextField 
                                        {...params} 
                                        label="Granting Roles"
                                    />
                                }
                            />
                            <div>
                                <LoadingButton 
                                    variant='contained' 
                                    onClick={() => formik.handleSubmit()}
                                    loading={updateMutation.isPending}
                                    disabled={!formik.dirty}
                                >
                                    Save
                                </LoadingButton>
                            </div>
                        </Stack>
                        <Stack direction={"row"} spacing={1} sx={{overflowX:"scroll", height:"fit-content", marginBottom:"6px", padding:"0.5rem 0.5rem 6px 0.5rem", }}>
                            {formik.values.filter(rar => grantingRolesFilter.length === 0 || grantingRolesFilter.map(grf => grf.id).includes(rar.grantingRole.id)).map((roleAssignableRoles) => (
                                <RolesList
                                    key={roleAssignableRoles.grantingRole.id}
                                    title={roleAssignableRoles.grantingRole.label}
                                    droppableId={roleAssignableRoles.grantingRole.id}
                                    roles={roleAssignableRoles.assignableRoles}
                                    draggingRole={draggingItem}
                                    removeRole={(roleId: string) => handleRemoveRole(roleAssignableRoles.grantingRole.id, roleId)}
                                />
                            ))}

                        </Stack>
                    </Paper>
                </div>

                
            </DragDropContext>
            <ErrorHandlingSnackbar messageItem={messageItem}/>
        </div>
    )
}

export default RoleAssignments