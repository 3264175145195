import { loginRequest } from "src/config/authConfig";
import {msalInstance} from "../index";
import { EndSessionRequest } from "@azure/msal-browser";

let cachedBearerToken: string | null = null;
let tokenExpiryTime: number | null = null;

const basicAuthToken = sessionStorage.getItem("basicAuthToken")

export const GetAuthorisation = async () => {

    if(basicAuthToken){
        return `Basic ${basicAuthToken}`
    }

    const account = msalInstance.getActiveAccount();
    if(!account){
        throw Error("No active account! Verify a user has been signed in and setActiveAccount has been called.");
    }

    const now = Math.floor(Date.now() / 1000); // Current time in seconds

    // Reuse token if it's still valid
    if (cachedBearerToken && tokenExpiryTime && now < tokenExpiryTime) {
        return cachedBearerToken;
    }

    const response = await msalInstance.acquireTokenSilent({
        ...loginRequest, 
        account: account
    });

    const bearer = `Bearer ${response.accessToken}`;
    return bearer;
};

export const RefreshToken = async () => {
    const account = msalInstance.getActiveAccount();
    if(!account){
        throw Error("No active account! Verify a user has been signed in and setActiveAccount has been called.");
    }

    await msalInstance.acquireTokenSilent({
        ...loginRequest, 
        account: account
    });
}

export const GetCurrentName = () => {
    if(basicAuthToken){
        const decoded = atob(basicAuthToken);
        const [username] = decoded.split(":"); // Extract username from "username:password"
        return username;
    }
    const account = msalInstance.getActiveAccount();
    if(!account){
        throw Error("No active account! Verify a user has been signed in and setActiveAccount has been called.");
    }
    return account.name ?? account.username;
}

export const GetCurrentUsername = () => {
    if(basicAuthToken){
        const decoded = atob(basicAuthToken);
        const [username] = decoded.split(":"); // Extract username from "username:password"
        return username;
    }
    const account = msalInstance.getActiveAccount();
    if(!account){
        throw Error("No active account! Verify a user has been signed in and setActiveAccount has been called.");
    }

    return account.username;
}

export const GetCurrentUser = () => {
    const account = msalInstance.getActiveAccount();

    if(!account){
        throw Error("No active account! Verify a user has been signed in and setActiveAccount has been called.");
    }

    return {name: account.name ?? account.username, id: account.localAccountId}
}

export const LogoutAllUsers = async (navigate: (path: string) => void) => {
    try {
        const accounts = msalInstance.getAllAccounts();

        //clear basic auth login
        sessionStorage.removeItem("basicAuthToken")

        if (accounts.length === 0) {
            console.log("No users are currently signed in.");
            navigate("");
            return;
        }
        
        for (const account of accounts) {
            const endSessionRequest: EndSessionRequest = {
                account: account,
                logoutHint: account.idTokenClaims?.login_hint, // optional, helps with promptless logout
            };

            // Attempt to log out the current user
            await msalInstance.logoutRedirect(endSessionRequest);
            console.log(`User with account ${account.username} logged out successfully`);
        }
        
        // Log out successful
        console.log('All users logged out successfully');
    } catch (error) {
        // Handle logout error
        console.error('Error logging out user:', error);
    }
  }
