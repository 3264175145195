import { TeamMember } from "../HumanResources/TeamMember/TeamMember.dto";

export interface AttendanceSheetResponse {
    id: string;
    attendees: AttendanceSheetAttendee[];
}

export enum AttendeeType {
    Trainer, 
    Trainee
}

export interface AttendanceSheetAttendee {
    id: number;
    teamMemberId: string;
    teamMember: TeamMember;
    type: AttendeeType;
    attended?: boolean;
    signature?: string;
    timeSigned?: Date;
    readyForAssessment?: boolean;
    notes?: string;
}