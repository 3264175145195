import React, { useEffect, useState } from 'react';
//import './styles.scss'
import { Autocomplete, Box, Button, FormControlLabel, IconButton, Modal, Switch, TextField, Typography } from '@mui/material';
import TableCell from '@mui/material/TableCell';
import EditIcon from '@mui/icons-material/Edit';
import LocationForm from './LocationForm/LocationForm';
import LocationService from 'src/Services/Resources/LocationService';
import ErrorHandlingSnackbar, { MessageItem } from 'src/components/errorHandlingSnackbar';
import colorConfigs from 'src/config/colorConfigs';
import { StyledTableRow } from 'src/components/Datatable/StyledTableRow';
import { ListParameters } from 'src/Services/ListParameters';
import Datatable from 'src/components/Datatable/Datatable';
import { TableHeader } from 'src/components/Datatable/TableHeader';
import { LocationType, LocationDto, LocationTypeIndexToString, locationTypesList } from 'src/dtos/AwTypes';
import { ListResponseDto } from 'src/dtos/ListResponse.dto';
import { NameDto } from 'src/dtos/NameDto.dto';


const defaultValues = LocationService.GetDefaultValues();

const Locations = () => {
  const tableHeaders: TableHeader[] = [
    {label: '', field: "statusIndicator", width: "10px", sortable: false},
    {label: "Residing Location", field: "primaryLocation", width: "12rem", sortable: true},
    {label: "Name", field: "label", width: "12rem", sortable: true},
    {label: "Description", field: "description", width: "16rem", sortable: false},
    {label: "Type", field: "type", width: "8rem", sortable: true},
    {label: '', field: "edit", width: "3rem", sortable: false}
  ]
  type filterOptionsType = {
    type?: LocationType,
    parentLocation?: {id: string, label: string},
    includeDisabled?: boolean
  }
  const [formOpen, setFormOpen] = useState(false);
  const [filterOptions, setFilterOptions] = useState<filterOptionsType>({type: undefined, parentLocation: undefined, includeDisabled: false})
  const [editLocationId, setEditLocationId] = useState<string>();
  const [formValues, setFormValues] = useState<LocationDto>(defaultValues);
  const [messageItem, setMessageItem] = useState<MessageItem>({successMessage: undefined, error: undefined})
  const [toggleRefresh, setToggleRefresh] = useState(false);
  const handleFormOpen = () => {
    setFormOpen(true);
  }
  const handleFormClose = () => {
      setFormOpen(false);
      setEditLocationId(undefined);
      setFormValues(defaultValues);
      setToggleRefresh((prevState) => !prevState)
    };

  useEffect(() => {
    if (editLocationId !== undefined && formValues !== defaultValues) {
      handleFormOpen();
    }
  }, [editLocationId, formValues]);

  useEffect(() => {
    const fetchData = async () => {
      try{
        if(editLocationId){
          const data = await LocationService.Get(editLocationId)
          if(data.type !== undefined){
            data.type = LocationTypeIndexToString[parseInt(data.type.toString())]
          }
          setFormValues(data)
        }
      } catch (error: any) {
        setMessageItem({error: error})
      }
    }
    fetchData();
  },[editLocationId])
  

  const initialListParams: ListParameters = {
    page: 1,
    pageSize: 30
  }

  const fetchLocations = async (listParams: ListParameters, searchTerm?: string): Promise<ListResponseDto> => {
      const data = await LocationService.GetList(listParams, searchTerm, filterOptions.type, filterOptions.parentLocation?.id, filterOptions.includeDisabled);
      return data;
  };  

  function renderTableRow ({data, index}: {data: LocationDto, index:number}) {
    const handleEdit = (e:any, id: string) => {
      e.stopPropagation();
      setEditLocationId(id);
    }    

    const locationTypeString: string = (data && data.type !== undefined && !isNaN(parseInt(data.type))) ? LocationTypeIndexToString[parseInt(data.type)]: "";
    return (
        <StyledTableRow
        hover
        onClick={(e)=> handleEdit(e, data.id)}
        key={`LocationsRow_${index}`}
        sx={{cursor: 'pointer'}}
        >
          <TableCell sx={{minWidth: `${tableHeaders[0].width} !important`, maxWidth: `${tableHeaders[0].width} !important`, padding: '0', bgcolor:data.enabled ? colorConfigs.datatable.active: colorConfigs.datatable.inActive}}></TableCell>
          <TableCell sx={{minWidth: `${tableHeaders[1].width} !important`, maxWidth: `${tableHeaders[1].width} !important`}}>{data.parentLocation?.label}</TableCell>
          <TableCell sx={{minWidth: `${tableHeaders[2].width} !important`, maxWidth: `${tableHeaders[2].width} !important`}}>{data.label}</TableCell>
          <TableCell sx={{minWidth: `${tableHeaders[3].width} !important`, maxWidth: `${tableHeaders[3].width} !important`}}>{data.description}</TableCell>
          <TableCell sx={{minWidth: `${tableHeaders[4].width} !important`, maxWidth: `${tableHeaders[4].width} !important`}}>{locationTypeString}</TableCell>
          <TableCell sx={{minWidth: `${tableHeaders[5].width} !important`, maxWidth: `${tableHeaders[5].width} !important`, paddingLeft: "0rem"}}><IconButton onClick={(e)=> handleEdit(e, data.id)}><EditIcon fontSize='small'/></IconButton></TableCell>
      </StyledTableRow>
      
    )
  };

  function FilterMenu(props: {handleClose: () => void}){  
    const [parentLocation, setParentLocation] = useState<{id: string, label: string} | undefined>(filterOptions.parentLocation);
    const [type, setType] = useState<LocationType | undefined>(filterOptions.type);
    const [includeDisabled, setIncludeDisabled] = useState(filterOptions.includeDisabled);
    const [locationNames, setLocationNames] = useState<NameDto[]>([]);

    useEffect(() => {
      const fetchNames = async () => {
        try{
          const data = await LocationService.GetNames()
          setLocationNames(data)
        } catch (error: any) {
          setMessageItem({error: error})
        }
      }
      fetchNames()
    }, [])

    const handleTypeChange = (value:any) => {
      setType(value)
    }
    const handleResidingLocationChange = (value:any) => {
      if(value){
        setParentLocation({id: value.id, label: value.label})
      } else{
        setParentLocation(undefined)
      }
    }
    const handleIncludeDisabledChange = () => {
      setIncludeDisabled((prevVal) => !prevVal)
    }

    const handleOkClick = () => {
      props.handleClose()
      setFilterOptions({parentLocation: parentLocation, type: type, includeDisabled: includeDisabled})
    }

    return(
      <Box style={{padding: '1rem', display: "block"}}>
        <Typography variant='h6' sx={{paddingBottom: '1rem', paddingLeft: '0.5rem'}}>Filter</Typography>
        <Autocomplete
          disablePortal={false}
          id="type"
          options={locationTypesList}
          size="small"
          sx={{ width: "15rem", paddingBottom: "1rem" }}
          onChange={(e, value) => handleTypeChange(value)}
          value={type}
          renderInput={(params) => 
          <TextField {...params} label="Type"/>}
      />
      <Autocomplete
        disablePortal={false}
        id="parentLocationLabel"
        options={locationNames}
        size="small"
        sx={{ width: "15rem", paddingBottom: "0.5rem" }}
        value={parentLocation || null}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        getOptionLabel={(option) => option.label ? option.label: ""}
        onChange={(e, value) => handleResidingLocationChange(value)}
        renderInput={(params) => 
        <TextField {...params} label="Residing Location"
        />}
      />
      <FormControlLabel 
        control={
          <Switch 
            checked={includeDisabled}
            onChange={handleIncludeDisabledChange}  
          />
        }
        label={"Include Inactive?"}
        sx={{minWidth: '100%'}}
      />
      <Button onClick={handleOkClick}>Filter</Button>
    </Box>
    );    
  }

  const style = {
    borderRadius: '10px',
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
  };
  return (
    <>
      <Datatable
        key={"LocationsDatatable"}
        tableHeaders={tableHeaders}
        initialListParams={initialListParams}
        tableTitle='Locations'
        buttonLabel='Add New Location'
        buttonOnClick={handleFormOpen}
        showSearch={true}
        renderRow={renderTableRow}
        callService={fetchLocations}
        FilterMenu={FilterMenu}
        setMessageItem={(caughtError: MessageItem) => setMessageItem(caughtError)}
        toggleRefreshFlag={toggleRefresh}
      ></Datatable>
      <Modal
        open={formOpen}
        onClose={handleFormClose}
      >
        <Box sx={style}>
          <LocationForm
            locationId={editLocationId}
            initialFormValues={formValues}
            handleClose={handleFormClose}
            setMessageItem={(caughtError: MessageItem) => setMessageItem(caughtError)}
          />
        </Box>
      </Modal>
      <ErrorHandlingSnackbar
        messageItem={messageItem}
      />
    </>
    
  )
}

export default Locations