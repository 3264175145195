//only used for basic auth login

import axios, { AxiosResponse } from "axios";

const apiBaseUrl = process.env.REACT_APP_API_BASE_URL + "/Login";
const debug = process.env.REACT_APP_DEBUG;

export default class BasicAuthLoginService {
    static async Login(credentials: string) {
        try {
            const response: AxiosResponse = await axios.post(`${apiBaseUrl}`, undefined, {
                headers: {
                    Authorization: `Basic ${credentials}`
                }
            });
            return response;
        } catch (error: any) {
            debug && console.log(error)
            throw error;
        }
    }
}