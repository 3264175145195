import { ChildEntity } from "../AwTypes";
import { NameDto } from "../NameDto.dto";

export enum AttractionType {
    Mechanical,
    Aquatic,
    Hybrid,
    Other
}

export const AttractionTypeString: string[] = [
    "Mechanical",
    "Aquatic",
    "Hybrid",
    "Other"
]

export const AttractionTypeList: AttractionType[] =[
    AttractionType.Mechanical,
    AttractionType.Aquatic,
    AttractionType.Hybrid,
    AttractionType.Other
]

export interface AttractionDto {
    id: string;
    label: string;
    description?: string;
    components?: ChildEntity[];
    type: AttractionType;
    residingArea?: NameDto | null;
    deviceMinimumCapacity?: number | null;
    deviceMaximumCapacity?: number | null;
    deviceCapacityLabel?: string;
    documentationUrl?: string;
    enabled: boolean;
    createdBy?: string;
    createdOn?: Date;
    modifiedBy?: string;
    modifiedOn?: Date;
}