import { TableRow, TableCell, Typography, Stack } from '@mui/material';
import React from 'react'
import { dateTimeOptions } from 'src/config/constants';
import { AttendanceSheetAttendee } from 'src/dtos/Training/AttendanceSheetResponse.dto'

type Props = {
    trainee: AttendanceSheetAttendee
    readyForAssesmentColumnDisabled?: boolean;
}
function CompletedTraineeRow({trainee, readyForAssesmentColumnDisabled}: Props) {
  return (
    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 }, pageBreakInside: "avoid"  }}>
        <TableCell component="th" scope="row" sx={{minWidth:"12rem"}}>
            <Stack direction={"row"} sx={{paddingLeft:"1rem", alignItems:"center"}}>
                {`${trainee.teamMember.prefferedFirstName} ${trainee.teamMember.lastName}`}
            </Stack>
        </TableCell>
        <TableCell>
            {trainee.attended ? "Yes" : "No"}
        </TableCell>
        <TableCell sx={{minWidth:"6rem"}}>
            {trainee.signature &&
                <img style={{width: "5rem"}} src={trainee.signature} alt={`${trainee.teamMember.prefferedFirstName} ${trainee.teamMember.lastName} Signature`}/>
            }
        </TableCell>
        <TableCell sx={{minWidth:"8rem", maxWidth:"8rem"}}>
            <Typography variant='body2' sx={{minWidth:"4rem"}}>
                {trainee.timeSigned && new Date(trainee.timeSigned).toLocaleDateString(undefined, dateTimeOptions)}
            </Typography>
        </TableCell>
        {!readyForAssesmentColumnDisabled && (
            <TableCell>
                {trainee.readyForAssessment === true ? 
                    <Typography>Yes</Typography> : 
                trainee.readyForAssessment === false ? 
                    <Typography>No</Typography> : 
                    null
                }
            </TableCell>
        )}
        <TableCell>
            {trainee.notes}
        </TableCell>
    </TableRow>
    )
}

export default CompletedTraineeRow