import { Box, Button, Stack, TableCell, Typography } from '@mui/material'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from 'dayjs'
import React, { useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import ClickableTableRow from 'src/components/Datatable/ClickableTableRow'
import Datatable from 'src/components/Datatable/Datatable'
import { TableHeader } from 'src/components/Datatable/TableHeader'
import ErrorHandlingSnackbar, { MessageItem } from 'src/components/errorHandlingSnackbar'
import colorConfigs from 'src/config/colorConfigs'
import { dateOptions } from 'src/config/constants'
import { ListResponseDto } from 'src/dtos/ListResponse.dto'
import { TrainingMemoDto } from 'src/dtos/Training/TrainingMemo.dto'
import { ListParameters, SortDirection } from 'src/Services/ListParameters'
import TrainingMemoService from 'src/Services/Training/TrainingMemoService'

function TrainingMemos() {
    const tableHeaders: TableHeader[] = [
        {label: '', field: "statusIndicator", width: "10px", sortable: false},
        {label: "Name", field: "label", width: "25rem", sortable: true},
        {label: "Date Effective", field: "dateEffective", width: "20rem", sortable: true},
        {label: "Acceptances", field: "acceptances", width: "25rem", sortable: true},
    ]

    type filterOptionsType = {
        fromDate?: Date;
        toDate?: Date;
    }

    const [messageItem, setMessageItem] = useState<MessageItem>({})
    const [searchParams, setSearchParams] = useSearchParams()
    const deserializedFilterOptions = searchParams.get("filterOptions") ? JSON.parse(decodeURIComponent(searchParams.get("filterOptions") as string)) : null
    const defaultOptions = { startDate: (dayjs(new Date())).startOf('day').toDate(), includeDisabled: false}
    const [filterOptions, setFilterOptions] = useState<filterOptionsType>(deserializedFilterOptions ?? defaultOptions)
    const navigate = useNavigate();

    const initialListParams: ListParameters = {
        page: 1,
        pageSize: 30,
        sortBy: "createdOn",
        sortDirection: SortDirection.Ascending
    }

    const fetchTrainingMemos = async (listParams: ListParameters, searchTerm?: string): Promise<ListResponseDto> => {
        const data = await TrainingMemoService.GetList(listParams, searchTerm, filterOptions.fromDate, filterOptions.toDate)
        return data
    }

    const renderTableRow = ({data, index}: {data: TrainingMemoDto, index: number}) => {
        const acceptancesString = data.dateIssued == null ? "Unissued" :
            `${data.totalSignatures}/${data.totalTeamMembers}`

        return (
            <>
                <ClickableTableRow
                    hover
                    href={`${window.location.pathname}${window.location.pathname[window.location.pathname.length-1] === "/" ? "" : "/"}${data.id}`}
                    key={`TrainingMemoRow_${index}`}
                    sx={{cursor: "pointer", textDecoration: "none"}}
                >
                    <TableCell sx={{minWidth: `${tableHeaders[0].width} !important`, maxWidth: `${tableHeaders[0].width} !important`, padding: '0', bgcolor: data.enabled ? colorConfigs.datatable.active: colorConfigs.datatable.inActive}}></TableCell>
                    <TableCell sx={{minWidth: `${tableHeaders[1].width} !important`, maxWidth: `${tableHeaders[1].width} !important`}}>{data.label}</TableCell>
                    <TableCell sx={{minWidth: `${tableHeaders[2].width} !important`, maxWidth: `${tableHeaders[2].width} !important`}}>{data.dateEffective && new Date(data.dateEffective).toLocaleDateString(undefined, dateOptions)}</TableCell>
                    <TableCell sx={{minWidth: `${tableHeaders[3].width} !important`, maxWidth: `${tableHeaders[3].width} !important`}}>{acceptancesString}</TableCell>
                </ClickableTableRow>
            </>
        )
    }

    const handleFormOpen = () => {
        navigate(`${window.location.pathname}${window.location.pathname[window.location.pathname.length-1] === "/" ? "" : "/"}new`)
    }

    function FilterMenu(props:{handleClose: () => void}){
        const [fromDate, setFromDate] = useState(filterOptions.fromDate);
        const [toDate, setToDate] = useState(filterOptions.toDate)

        const handleOkClick = () => {
            props.handleClose()
            const newFilterOptions: filterOptionsType = {
                fromDate: fromDate,
                toDate: toDate
            }
            setFilterOptions(newFilterOptions)

            //update search params
            if(JSON.stringify(newFilterOptions) === JSON.stringify(defaultOptions)){
                searchParams.delete("filterOptions")
            } else {
                const serializedFilterOptions = encodeURIComponent(JSON.stringify(newFilterOptions))
                searchParams.set("filterOptions", serializedFilterOptions)
            }
            setSearchParams(searchParams)
        }

        return (
            <Box style={{padding:"1rem", display:"block"}}>
                <Typography variant='h6' sx={{paddingBottom: '1rem', paddingLeft: '0.5rem'}}>Filter</Typography>
                <Stack spacing={2}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker 
                            name="from Date"
                            format='DD/MM/YYYY'
                            value={fromDate ? dayjs(fromDate) : null}
                            onChange={(value) => {
                                if (value) {
                                    // Set time to midnight
                                    const startOfDay = value.startOf('day').toDate();
                                    setFromDate(startOfDay);
                                  } else {
                                    setFromDate(undefined);
                                  }
                            }}
                            slotProps={{
                                textField: {
                                    size: "small",
                                    label: "From Date"
                                }
                            }}
                            sx={{width:"10rem"}}
                        />
                    </LocalizationProvider>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker 
                            name="to Date"
                            format='DD/MM/YYYY'
                            value={toDate ? dayjs(toDate) : null}
                            onChange={(value) => {
                                if (value) {
                                    // Set time to 11:59 PM
                                    const endOfDay = value.endOf('day').toDate();
                                    setToDate(endOfDay);
                                  } else {
                                    setToDate(undefined);
                                  }
                            }}
                            slotProps={{
                                textField: {
                                    size: "small",
                                    label: "To Date"
                                }
                            }}
                            sx={{width:"10rem"}}
                        />
                    </LocalizationProvider>
                </Stack>
                <Button onClick={handleOkClick}>Filter</Button>
            </Box>
        )

    }
    
    return (
        <>
            <Datatable
                tableHeaders={tableHeaders}
                initialListParams={initialListParams}
                tableTitle='Training Memos'
                buttonLabel='Create new Memo'
                buttonOnClick={handleFormOpen}
                showSearch={true}
                renderRow={renderTableRow}
                callService={fetchTrainingMemos}
                setMessageItem={setMessageItem}
                FilterMenu={FilterMenu}
            />
            <ErrorHandlingSnackbar messageItem={messageItem}/>
        </>
    )
}

export default TrainingMemos