import { Autocomplete, LinearProgress, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from '@mui/material'
import { useMutation, useQuery } from '@tanstack/react-query'
import { FormikProps } from 'formik'
import React, { useEffect } from 'react'
import { MessageItem } from 'src/components/errorHandlingSnackbar'
import QuestionTitle from 'src/components/QuestionTitle/QuestionTitle'
import { NameDto } from 'src/dtos/NameDto.dto'
import { TrainingMemoDto } from 'src/dtos/Training/TrainingMemo.dto'
import TrainingGroupParentService from 'src/Services/Training/TrainingGroupParentService'
import TrainingMemoService from 'src/Services/Training/TrainingMemoService'

type Props = {
    id: string;
    formik: FormikProps<TrainingMemoDto>
    setMessageItem: React.Dispatch<React.SetStateAction<MessageItem>>
}
function MemoTeamMembers({id, formik, setMessageItem}:Props) {
    const disabled = formik.values.dateIssued != null; 
    
    //#region queries
    const trainingGroupsQuery = useQuery({
        queryKey: ["trainingGroupParentNames"],
        queryFn: async () => {
            return await TrainingGroupParentService.GetNames();
        }
    })

    useEffect(() => {
        if(trainingGroupsQuery.isError)
            setMessageItem({error: trainingGroupsQuery.error})
    }, [setMessageItem, trainingGroupsQuery.error, trainingGroupsQuery.isError])

    const updateAffectedTrainingGroupsMutation = useMutation({
        mutationFn: (values: NameDto[]) => {
            return TrainingMemoService.UpdateTrainingGroups(id, values)
        },
        onSuccess: (data) => {
            formik.setFieldValue("acceptances", data.data)
        },
        onError: (error) => {
            setMessageItem({error: error})
        }
    })
    //#endregion

    return (
    <Stack spacing={2}>
        <div>
            <QuestionTitle title="Affected Training Groups" required/>
            <Autocomplete
                id="affectedTrainingGroups"
                isOptionEqualToValue={(option, value) => option.id === value.id}
                getOptionLabel={(option) => (option.label ? option.label : "")}
                options={trainingGroupsQuery.data ?? []}
                multiple
                value={formik.values.affectedTrainingGroups}
                onChange={(e, value) => {
                    formik.setFieldValue("affectedTrainingGroups", value)
                    updateAffectedTrainingGroupsMutation.mutate(value)
                }}
                size={"small"}
                sx={{width:"100%"}}
                disabled={disabled}
                renderInput={(params) => 
                    <TextField {...params}/>
                }
            />
        </div>
        <div>
            {updateAffectedTrainingGroupsMutation.isPending ? (
                <LinearProgress/>
            ) : (
                <div style={{height:"4px"}}/> //spacer
            )}
            <TableContainer component={Paper}>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                Team Members
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {formik.values.acceptances.map((acceptance, index) => (
                            <TableRow key={index}>
                                <TableCell>
                                    {acceptance.teamMember.label}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

        </div>
    </Stack>
  )
}

export default MemoTeamMembers