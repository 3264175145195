import {Accordion, AccordionDetails, AccordionSummary, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControlLabel, IconButton, Paper, Stack, Switch, Tooltip, Typography,} from "@mui/material";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import InspectionChecklistService from "src/Services/Checklists/Inspections/InspectionChecklistService";
import QuestionTitle from "src/components/QuestionTitle/QuestionTitle";
import ErrorHandlingSnackbar, { MessageItem,} from "src/components/errorHandlingSnackbar";
import { InspectionChecklistDto } from "src/dtos/Checklists/MaintenanceChecklist.dto";
import * as yup from "yup";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { dateOptions } from "src/config/constants";
import { LoadingButton } from "@mui/lab";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ChecklistVersions from "./Components/ChecklistVersions";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import PDFService from "src/Services/PDF/PDFService";
import { guidRegex } from "src/Utils/helperFunc";
import InspectionChecklistForm from "./Components/InspectionChecklistForm";

const validationSchema = yup.object({
    label: yup
        .string()
        .max(75, "Checklist Name cannot be more than 75 characters")
        .required("Checklist Name is required"),
    description: yup
        .string()
        .max(255, "Checklist Description cannot be more than 255 characters")
        .notRequired(),
    department: yup
        .object()
        .shape({
            id: yup.string().required(),
            label: yup.string().required(),
        })
        .required("Department is required"),
    attraction: yup
        .object()
        .shape({
            id: yup.string().required(),
            label: yup.string().required(),
        })
        .required("Attraction is required"),
    expiry: yup
        .object()
        .shape({
            type: yup.string().required("Expiry Type is required"),
            afterValue: yup
                .number()
                .typeError("Must be a number")
                .positive("Must be positive")
                .notRequired(),
            everyTimeValue: yup
                .string()
                .matches(
                    /^([01]\d|2[0-3]):([0-5]\d)$/,
                    "Invalid time format. Please use HH:mm (24-hour format)"
                ),
            everyDayValue: yup
                .string()
                .matches(/^(Monday|Tuesday|Wednesday|Thursday|Friday|Saturday|Sunday)$/),
        })
        .required(),
    expectedDurationSeconds: yup
        .number()
        .min(0, "Expected Duration must be positive")
        .notRequired(),
});

function InspectionChecklist() {
    const { id } = useParams();
    const [messageItem, setMessageItem] = useState<MessageItem>({
        successMessage: undefined,
        error: undefined,
    });

    const navigate = useNavigate();
    const queryClient = useQueryClient();

    const urlIdRegex = new RegExp(`/${id}$`);

    const handleBackClick = () => {
        //remove id param from end of the url
        navigate({
            pathname: window.location.pathname.replace(urlIdRegex, ""),
        });
    };

    //#region queries
    const inspectionChecklistQuery = useQuery({
        queryKey: ["InspectionChecklist.Get", id],
        queryFn: async () => {
            if (id && guidRegex.test(id))
                return await InspectionChecklistService.Get(id);
            return InspectionChecklistService.GetDefaultValues();
        },
        initialData: InspectionChecklistService.GetDefaultValues(),
    });

    const checklistPdfQuery = useQuery({
        queryKey: ["GetInspectionChecklistPDF", id],
        queryFn: async () => {
            if (id && guidRegex.test(id)) {
                return await PDFService.GetInspectionChecklistPDF(id);
            }
        },
        refetchOnWindowFocus: false,
        enabled: false,
    });

    useEffect(() => {
        if (inspectionChecklistQuery.isError)
            setMessageItem({ error: inspectionChecklistQuery.error });
        if (checklistPdfQuery.isError)
            setMessageItem({ error: checklistPdfQuery.error });
    }, [checklistPdfQuery.error,checklistPdfQuery.isError, inspectionChecklistQuery.error,inspectionChecklistQuery.isError,]);

    const createMutation = useMutation({
        mutationFn: async (values: InspectionChecklistDto) => {
            return await InspectionChecklistService.Create(values);
        },
        onSuccess: (data) => {
            setMessageItem({
                successMessage: "Inspection Checklist Created Successfully!",
            });
            //redirect url to created
            navigate({
                pathname: window.location.pathname.replace(
                    urlIdRegex,
                    `/${data.data.id}`
                ),
            });
        },
        onError: (error) => {
            setMessageItem({ error: error });
        },
    });

    const updateMutation = useMutation({
        mutationFn: async ({
            id,
            values,
        }: {
            id: string;
            values: InspectionChecklistDto;
        }) => {
            return InspectionChecklistService.Update(id, values);
        },
        onSuccess: (data) => {
            setMessageItem({
                successMessage: "Inspection Checklist Updated Successfully!",
            });
            queryClient.setQueryData(
                ["InspectionChecklist.Get", id],
                data.data
            );
        },
        onError: (error) => {
            setMessageItem({ error: error });
        },
    });
    //#endregion

    const handleFileDownloadClick = async () => {
        const result = await checklistPdfQuery.refetch();
        if (result.data) {
            const url = window.URL.createObjectURL(result.data);
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", `${formik.values.label}.pdf`);
            document.body.appendChild(link);
            link.click();
        }
    };

    const save = async (values: InspectionChecklistDto) => {
        if (id === "new") {
            //create
            createMutation.mutateAsync(values);
        } else if (id !== undefined) {
            //Update
            updateMutation.mutateAsync({ id, values });
        }
    };

    function ToggleEnabledSwitch() {
        const [open, setOpen] = useState(false);

        const handleSaveToggleEnable = async () => {
            try {
                if (id !== undefined) {
                    if (!formik.values.enabled) {
                        //enable
                        await InspectionChecklistService.Enable(id);
                        setMessageItem({
                            successMessage:
                                "Inspection Checklist Activated Succesfully!",
                        });
                    } else {
                        //disable
                        await InspectionChecklistService.Disable(id);
                        setMessageItem({
                            successMessage:
                                "Inspection Checklist Deactivated Succesfully!",
                        });
                    }
                }
            } catch (error: any) {
                setMessageItem({ error: error });
            }
        };

        const handleClick = () => {
            formik.setFieldValue("enabled", !formik.values.enabled);
            handleSaveToggleEnable();
            setOpen(false);
        };
        return (
            <>
                <FormControlLabel
                    control={
                        <Switch
                            checked={formik.values.enabled}
                            onChange={() => setOpen(true)}
                        />
                    }
                    label={formik.values.enabled ? "Active" : "Inactive"}
                />
                <Dialog open={open} onClose={() => setOpen(false)}>
                    <DialogTitle>
                        {formik.values.enabled ? "Deactivate" : "Activate"}{" "}
                        Inspection Checklist?
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Are you sure you want to{" "}
                            {formik.values.enabled ? "deactivate" : "activate"}{" "}
                            {formik.values.label}? New inspections cannot be
                            started for this checklist once it has been
                            deactivated.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setOpen(false)}>Cancel</Button>
                        <Button onClick={handleClick}>
                            {formik.values.enabled ? "Deactivate" : "Activate"}
                        </Button>
                    </DialogActions>
                </Dialog>
            </>
        );
    }

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: inspectionChecklistQuery.data,
        validationSchema: validationSchema,
        onSubmit: (values) => {
            save(values);
        },
    });

    return (
        <>
            <Paper sx={{ padding: "1rem" }}>
                <Stack
                    direction="row"
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                    }}
                >
                    <Typography variant="h5">
                        {id === "new" ? "New" : "Edit"} Inspection Checklist
                    </Typography>
                    <Tooltip title="Go Back">
                        <IconButton onClick={handleBackClick}>
                            <ArrowBackIcon fontSize="large" />
                        </IconButton>
                    </Tooltip>
                </Stack>
                <InspectionChecklistForm
                    id={id}
                    formik={formik}
                    setMessageItem={setMessageItem}
                />
                {id !== "new" && (
                    <>
                        <QuestionTitle title="Inspection Checklist Status" />
                        <ToggleEnabledSwitch />
                    </>
                )}
                <Stack
                    direction="row"
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        padding: "1rem",
                    }}
                >
                    {id !== "new" && (
                        <Accordion sx={{ width: "20rem" }}>
                        <AccordionSummary
                                expandIcon={<ExpandMore />}
                                id="additional-info"
                            >
                                <Typography>
                                    Modification Details
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                {formik.values.createdBy && (
                                    <Typography>
                                        Created By:{" "}
                                        {formik.values.createdBy}
                                    </Typography>
                                )}
                                {formik.values.createdOn && (
                                    <Typography>
                                        Created On:{" "}
                                        {new Date(
                                            formik.values.createdOn
                                        ).toLocaleTimeString(
                                            undefined,
                                            dateOptions
                                        )}
                                    </Typography>
                                )}
                                {formik.values.modifiedBy && (
                                    <Typography>
                                        Modified By:{" "}
                                        {formik.values.modifiedBy}
                                    </Typography>
                                )}
                                {formik.values.modifiedOn && (
                                    <Typography>
                                        Modified On:{" "}
                                        {new Date(
                                            formik.values.modifiedOn
                                        ).toLocaleTimeString(
                                            undefined,
                                            dateOptions
                                        )}
                                    </Typography>
                                )}
                            </AccordionDetails>
                        </Accordion>
                    )}

                    <div style={{ flexGrow: 1, textAlign: "right" }}>
                        <LoadingButton
                            variant="contained"
                            color="primary"
                            size="large"
                            loading={
                                createMutation.isPending ||
                                updateMutation.isPending
                            }
                            onClick={() => formik.handleSubmit()}
                        >
                            Save
                        </LoadingButton>
                    </div>
                </Stack>
                
                {formik.values.publishedVersionId && (
                    <LoadingButton
                        variant="contained"
                        loading={checklistPdfQuery.isFetching}
                        onClick={handleFileDownloadClick}
                    >
                        Download PDF of Published Checklist
                    </LoadingButton>
                )}
            </Paper>
            {id !== "new" && id !== undefined && (
                <div style={{ paddingTop: "1.5rem" }}>
                    <ChecklistVersions inspectionChecklistId={id} />
                </div>
            )}

            <ErrorHandlingSnackbar messageItem={messageItem} />
        </>
    );
}

export default InspectionChecklist;
