const colorConfigs = {
    sidebar: {
        bg: "#233044",
        color: "#eeeeee",
        hoverBg: "#1e293a",
        activeBg: "#1e253a"
    },
    topbar: {
        bg: "#fff",
        color: "#000"
    },
    mainBg: "#f0f0f0",
    datatable: {
        active: "green",
        inActive: "Crimson"
    },
    userSideBar: {
        bg: "#233044",
        color: "#eeeeee"
    },
    trackChanges: {
        removed: "Crimson",
        added: "LimeGreen"
    },
    statuses: {
        draft: "#f94449",
        published: "#3A9BDC",
        outdated: "#666666",
    },
    learningDocumentIcons: {
        question: "#212121",
        trainerAction: "#109afa",
        traineeDemo: "#6db774",
        previousIncident: "#ff6700",
        video: "#800080",
        image: "#800080",
    },
    learningDocument: {
        subtopicHeadingBg: "#b4c6e7"
    },
    AW: {
        blue: "#1e8fff",
    },
    utils: {
        hoverBg: "#f1f2f4"
    }
    
};

export default colorConfigs;