import { AxiosResponse } from 'axios'
import React, { useEffect, useRef, useState } from 'react'
import { FileMetadata } from 'src/dtos/FileMetadata.dto'
import { ListResponseDto } from 'src/dtos/ListResponse.dto'
import { MessageItem } from '../errorHandlingSnackbar'
import { LoadingButton } from '@mui/lab'
import { useQuery, useMutation } from '@tanstack/react-query'
import { LinearProgress, List, ListItem, Pagination, Paper } from '@mui/material'
import { Delete } from '@mui/icons-material'

type Props = {
    setMessageItem: React.Dispatch<React.SetStateAction<MessageItem>>
    callService: (page: number, pageSize: number) => Promise<ListResponseDto<FileMetadata>>
    addFileService: (file: File) => Promise<AxiosResponse<FileMetadata>>
    deleteFileService: (fileId: string) => Promise<AxiosResponse>
    queryKey: string
    editable?: boolean
}
function AttachmentsSimple({setMessageItem, callService, addFileService, deleteFileService, queryKey, editable = true}: Props) {
    const hiddenFileInput = useRef<HTMLInputElement>(null);
    const [page, setPage] = useState(1)

    const pageSize = 5
    
    //#region queries
    const attachmentsQuery = useQuery({
        queryKey: ["attachments", page, pageSize, queryKey], 
        queryFn: async () => {
            return await callService(page, pageSize)
        },
        placeholderData: (prev) => prev //keep previous data when fetching 
    })

    const addFileMutation = useMutation({
        mutationFn: async (values: File) => {
            return await addFileService(values)
        },
        onSuccess: () => {
            setMessageItem({successMessage: "File added successfully!"})
            attachmentsQuery.refetch()
        },
        onError: (error) => {
            setMessageItem({error: error})
        }
    })

    const deleteFileMutation = useMutation({
        mutationFn: async (values: string) => {
            return await deleteFileService(values)
        },
        onSuccess: () => {
            setMessageItem({successMessage: "File deleted successfully!"})
            attachmentsQuery.refetch()
        },
        onError: (error) => {
            setMessageItem({error: error})
        }
    })

    useEffect(() => {
        if(attachmentsQuery.isError){
            setMessageItem({error: attachmentsQuery.error})
        }
    }, [setMessageItem, attachmentsQuery.error, attachmentsQuery.isError])

    //#endregion
    
    const handleChooseFileClick = () => {
        hiddenFileInput.current && hiddenFileInput.current.click();
    }

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files;
        if (files) {
            const newFiles = Array.from(files);

            newFiles.forEach((file) => {
                addFileMutation.mutate(file);
            });
        }
    };

    const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
        setPage(value); 
    };

    const handleDeleteFile = (fileId?: string) => {
        if(fileId)
            deleteFileMutation.mutate(fileId)
    }
    
    const totalPages = Math.ceil((attachmentsQuery.data?.totalRecords??0) / pageSize)

    return (
        <>
            {editable && (
                <>
                    <LoadingButton 
                        sx={{marginBottom:"0.5rem"}}
                        variant='contained'
                        loading={addFileMutation.isPending}
                        onClick={handleChooseFileClick}
                    >
                        Choose File
                    </LoadingButton>
                    <input
                        type="file"
                        ref={hiddenFileInput}
                        style={{display:"none"}}
                        multiple
                        onChange={handleFileChange}   
                    />
                </>
            )}

            {attachmentsQuery.isFetching ? (
                <LinearProgress />
            ) : (
                <div style={{height:"4px"}}/> //spacer so list doesnt move
            )}
            {attachmentsQuery.data && attachmentsQuery.data.totalRecords > 0 && (
                <>
                    <List dense sx={{width:"30rem"}} component={Paper}>
                        {attachmentsQuery.data.data.map((file, index) => (
                            <ListItem   
                                key={index}
                                secondaryAction={
                                    <>
                                        {editable && (
                                            <LoadingButton 
                                                onClick={() => handleDeleteFile(file.id)}
                                                loading={deleteFileMutation.isPending}
                                            >
                                                {!deleteFileMutation.isPending && (
                                                    <Delete color='error'/>
                                                )}
                                            </LoadingButton>
                                        )}
                                    </>
                                }
                            >
                                <a href={file.fileUrl} target='_blank' rel="noreferrer">{file.fileName}</a>
                            </ListItem>
                        ))}
                    </List>
                    {totalPages > 1 && (
                        <Pagination
                            count={totalPages}
                            page={page}
                            onChange={handlePageChange}
                            sx={{marginTop:"0.5rem"}}
                        />
                    )}
                </>
            )
        }
    </>
    )
}

export default AttachmentsSimple