import axios, { AxiosResponse } from "axios";
import { GetAuthorisation } from "src/Utils/authUtils";

const apiBaseUrl = process.env.REACT_APP_API_BASE_URL + "/TrainingQualification";
const debug = process.env.REACT_APP_DEBUG;

export default class TrainingQualificationService {
    static async QueueAllTeamMembers(): Promise<AxiosResponse> {
        try {
            const authorization = await GetAuthorisation();
            const response: AxiosResponse = await axios.post<AxiosResponse>(`${apiBaseUrl}/QueueAllTeamMembers`,undefined,{
                headers: {
                    Authorization: authorization
                }
            });
            return response
        } catch(error: any) {
            debug && console.log(error)
            throw error;
        }
    }
}