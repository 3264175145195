import React, { useEffect, useRef, useState } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import AdminLayout from "./components/common/layouts/AdminLayout";
import UserLayout from "./components/common/layouts/UserLayout";
import PageNotFound from "./pages/UtilityPages/PageNotFound/PageNotFound";
import {adminAppRoutes, completedDocumentAppRoutes, userAppRoutes, } from "./routes";
import {MsalProvider ,useMsal,} from "@azure/msal-react";
import { loginRequest } from "./config/authConfig";
import {Avatar ,Button, Paper, Stack, Typography,} from "@mui/material";
import { IPublicClientApplication } from "@azure/msal-browser";
import assets from "./assets";
import LandingPage from "./pages/UtilityPages/LandingPage/LandingPage";
import { QueryClient } from "@tanstack/react-query";
import CompletedDocumentLayout from "./components/common/layouts/CompletedDocumentLayout";
import { PersistQueryClientProvider } from "@tanstack/react-query-persist-client";
import { createSyncStoragePersister } from "@tanstack/query-sync-storage-persister";
import { LogoutAllUsers, RefreshToken } from "./Utils/authUtils";
import { AxiosError } from "axios";
import SessionTimeoutExtend from "./components/SessionTimeoutExtend/SessionTimeoutExtend";
import BasicAuthLoginService from "./Services/BasicAuthLoginService";

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            gcTime: 1000 * 60 * 60 * 24, //24 hours
            retry: (failureCount, error) => {
                if((error as AxiosError).response?.status === 403 || (error as AxiosError).response?.status === 401){
                    return false; //Do not retry on Access denied errors
                }
                return failureCount < 3; //Retry other errors up to 3 times
            },
        },
        mutations: {
            retry: (failureCount, error) => {
                if((error as AxiosError).response?.status === 403 || (error as AxiosError).response?.status === 401){
                    return false; //Do not retry on Access denied errors
                }
                return failureCount < 3; //Retry other errors up to 3 times
            },
            gcTime: 1000 * 60 * 60,
        },
    },
});

const persister = createSyncStoragePersister({
    storage: window.localStorage,
});

const MainContent = () => {
    const { instance } = useMsal();
    const activeAccount = instance.getActiveAccount();
    const navigate = useNavigate();
    const location = useLocation();

    const [username, setUsername] = useState("")
    const [password, setPassword] = useState("")
    const basicAuthToken = sessionStorage.getItem("basicAuthToken");

    const [sessionTimeoutOpen, setSessionTimeoutOpen] = useState(false);
    const [remainingTime, setRemainingTime] = useState(0); // To show remaining idle time
    const IDLE_TIMEOUT = 3 * 60 * 60 * 1000; // 3 hours in milliseconds
    const WARNING_TIME = 15 * 60 * 1000; // 15 minutes before auto-logout to show warning

    const workerRef = useRef<SharedWorker | null>(null)

    useEffect(() => {
        const startWorker = () => {
            
            if (window.SharedWorker && !workerRef.current) {
                workerRef.current = new SharedWorker('/workers/SessionTimeoutWorker.js');

                workerRef.current.port.onmessage = (event) => {
                    const { type, remainingTime } = event.data;
                    if (type === "warning") {
                        setSessionTimeoutOpen(() => true);
                        remainingTime > WARNING_TIME ?
                              setRemainingTime(WARNING_TIME/1000) 
                            : setRemainingTime(remainingTime);
                    } else if (type === "updateRemainingTime") {
                        setRemainingTime(remainingTime);
                    } else if (type === "logout") {
                        setSessionTimeoutOpen(() => false)
                        handleLogoutClick();
                    } else if (type === "extendSession"){
                        setSessionTimeoutOpen(() => false)
                    }
                };
                resetIdleTimer();
            }
        }
        const resetIdleTimer = () => {
            setSessionTimeoutOpen((prev) => {
                if (prev) return true; // Do not reset if session timeout is already open
                if (workerRef.current) {
                    workerRef.current.port.postMessage({ action: "reset" });
                    workerRef.current.port.postMessage({ action: "start", IDLE_TIMEOUT, WARNING_TIME });
                }
                setRemainingTime(0);
                return false;
            });
        
        };

        if (activeAccount) {
            startWorker();
        } else {
            if (workerRef.current) {
                workerRef.current.port.close(); // Terminate worker if no active account
                workerRef.current = null; // Reset worker reference
            }
        }

        // Listen to user activity to reset the timer
        const events = ["mousemove", "keydown", "click", "scroll"];
        events.forEach((event) => window.addEventListener(event, resetIdleTimer));

        resetIdleTimer(); // Start the timer on load

        return () => {
            if (workerRef.current) {
                workerRef.current.port.close();
            }
            events.forEach((event) => window.removeEventListener(event, resetIdleTimer));
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeAccount?.username]);

    // Handles session extension via refresh token
    const handleExtendSession = async () => {
        await RefreshToken();
        workerRef.current && workerRef.current.port.postMessage({ action: "extendSession"})
        setSessionTimeoutOpen(false); // Close timeout dialog if session is extended
    };

    const handleLoginClick = () => {
        // Store the current location before redirecting
        localStorage.setItem("redirectLocation", location.pathname);

        instance.loginRedirect({
                ...loginRequest,
                prompt: "select_account",
                redirectStartPage: "",
            })
            .catch((error) => console.log(error));
    };

    const handleBasicAuthLogin = async () => {
        try {
            const credentials = btoa(`${username}:${password}`);
            const response = await BasicAuthLoginService.Login(credentials);
            if (response.status === 200) {
                sessionStorage.setItem("basicAuthToken", credentials); // Store credentials for future requests
                window.location.reload();
            } else {
                console.error("Login failed: Invalid response");
            }
        } catch (error) {
            console.error("Login failed:", error);
        }
    };

    useEffect(() => {
        const redirectLocation = localStorage.getItem("redirectLocation");
        // Check if there's a stored redirect location
        if (activeAccount && redirectLocation) {
            // Redirect the user to the stored location after successful login
            navigate(redirectLocation);
            localStorage.removeItem("redirectLocation");
        }
    }, [activeAccount, navigate]);

    const handleLogoutClick = async () => {
        await LogoutAllUsers(navigate);
    };

    return (
        <PersistQueryClientProvider
            client={queryClient}
            persistOptions={{ persister, maxAge: 1000 * 60 * 60 * 24 }}
        >
            <div className="App">
                
                    {(activeAccount || basicAuthToken) ? (
                        <Routes>
                            <Route path="/" element={<LandingPage />} />

                            {/* Admin protected routes */}
                            <Route path="/admin/" element={<AdminLayout />}>
                                {adminAppRoutes}
                            </Route>

                            {/* user routes */}
                            <Route path="/user/" element={<UserLayout />}>
                                {userAppRoutes}
                            </Route>

                            {/* completedDocument routes */}
                            <Route
                                path="/completed/"
                                element={<CompletedDocumentLayout />}
                            >
                                {completedDocumentAppRoutes}
                            </Route>

                            {/* catch all (page not found) */}
                            <Route path="/*" element={<PageNotFound />} />
                        </Routes>
                    ) : (
                        <>
                            <div
                                style={{
                                    position: "fixed",
                                    top: "50%",
                                    left:"50%",
                                    transform: "translate(-50%, -50%)"
                                }}
                            >
                                <Paper
                                    sx={{ padding: "2rem", blockSize: "fit-content" }}
                                >
                                    <Stack
                                        sx={{ display: "flex", alignItems: "center" }}
                                    >
                                        <Avatar
                                            src={assets.images.logo}
                                            sx={{
                                                width: "8rem",
                                                height: "8rem",
                                                marginBottom: "1rem",
                                            }}
                                        />
                                        <Typography variant="h3" gutterBottom>
                                            AW Hub
                                        </Typography>
                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "center",
                                            }}
                                        >
                                            <Button
                                                id="microsoft-login-button"
                                                onClick={handleLoginClick}
                                                variant="contained"
                                                sx={{ marginTop: "1rem" }}
                                            >
                                                Sign in with Microsoft
                                            </Button>
                                        </div>
                                    </Stack>
                                    
                                </Paper>
                        
                            </div>
                            <div style={{opacity: 0}}>
                                <input
                                    id="username"
                                    name="username"
                                    value={username}
                                    onChange={(e) => setUsername(e.target.value)}
                                />
                                <input
                                    id="password"
                                    name="password"
                                    type="password"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                />
                                <button
                                    id="basic-auth-login-button"
                                    onClick={handleBasicAuthLogin}
                                >
                                    Login
                                </button>
                            </div>
                        </>
                    )}
            </div>
            <SessionTimeoutExtend 
                open={sessionTimeoutOpen}
                remainingTime={remainingTime}
                handleLogoutClick={handleLogoutClick}
                handleExtendSession={handleExtendSession}
            />
        </PersistQueryClientProvider>
    );
};

function App({ instance }: { instance: IPublicClientApplication }) {
    return (
        <MsalProvider instance={instance}>
            <MainContent />
        </MsalProvider>
    );
}

export default App;
