import { NameDto, NameNumberDto } from "src/dtos/NameDto.dto";
import { ChildEntity } from "../../EntityChild.dto";
import { Status } from "../../Statuses";
import { JournalEntryDto } from "./Journal.dto";

export interface ChecklistResponseDto {
    id: string;
    expiry?: Date;
    status: Status; //Passed, Failed, Inprogress
    startedTime?: Date;
    startedBy?: string;
    completedTime?: Date;
    completedBy?: string;

}
export interface InspectionChecklistResponseDto extends ChecklistResponseDto {
    selectedComponents?: NameNumberDto[]; 
    sectionResponses: InspectionChecklistSectionResponseDto[];
    inspectionChecklistVersionId: string;
    taggedOutComponents?: ChildEntity[];
    inspectedComponents: InspectedComponentDto[];
    trainee?: NameDto;
}

export interface ChecklistSectionResponseDto {
    id: number;
    //old attribute
    status: Status; //passed, failed, unasnwered, inprogress
}

export interface InspectionChecklistSectionResponseDto extends ChecklistSectionResponseDto {
    InspectionChecklistResponseId?: string;
    questionResponses: InspectionChecklistQuestionResponseDto[];
    groupedSectionComponentResponses?: GroupedSectionComponentResponse[];
    groupedPassedComponentsQty?: number;
}

export interface GroupedSectionComponentResponse {
    component: ChildEntity;
    passedAllQuestions: boolean;
    journalEntryId?: string;
    journalEntry?: JournalEntryDto;
}

export interface ChecklistQuestionResponseDto {
    id: number;
    status: Status; //passed, failed, unasnwered, NA
    comment?: string;
    images?: string[];
    answeredBy?: string;
    timeAnswered?: Date;
}

export interface InspectionChecklistQuestionResponseDto extends ChecklistQuestionResponseDto {
    answer: "Yes" | "No" | string | null;

    impactsOperation?: boolean;
    relatedJournalEntryId?: string;
    relatedJournalEntry?: JournalEntryDto;

    //old attributes from v1
    failureCause?: string;
    remidialAction?: string;
    issueResolved?: boolean | null;
    actionRequired?: string; 
    groupedQuestions?: Failure[]; //used for questions relating to 
    failedItems?: ChildEntity[];
}

export interface Failure {
    component?: ChildEntity;
    failureCause?: string;
    remidialAction?: string;
    issueResolved?: boolean | null;
    actionRequired?: string; 
}


export enum InspectedComponentStatus {
    notInspected,
    passed,
    failed,
    expired
}
export const InspectedComponentStatusString: string[] = [
    "Not Inspected",
    "Passed",
    "Failed",
    "Expired"
]
export interface InspectedComponentDto {
    component: NameNumberDto
    status: InspectedComponentStatus
}


