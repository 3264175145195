import { CircularProgress, IconButton, Stack, TextField } from '@mui/material'
import { useMutation } from '@tanstack/react-query'
import { useFormik } from 'formik'
import React, { useRef } from 'react'
import { MessageItem } from 'src/components/errorHandlingSnackbar'
import * as yup from 'yup'
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import { CommentDto } from 'src/dtos/Comment.dto'
import { AxiosResponse } from 'axios'

const validationSchema = yup.object({
    description: yup.string()
        .required("Comment is a required field")
        .max(500, "Comment cannot be more than 500 characters")
})

type Props = {
    commentValues: CommentDto
    editCommentService: (commentId: string, comment: CommentDto) => Promise<AxiosResponse<CommentDto>>
    setMessageItem: React.Dispatch<React.SetStateAction<MessageItem>>
    refetchJournalComments: () => void;
    stopEditing: () => void;
}
function EditComment({commentValues, setMessageItem, refetchJournalComments, editCommentService, stopEditing}: Props) {
    const descriptionInputRef = useRef<HTMLInputElement>(null); 
    const formik = useFormik({
        validationSchema: validationSchema, 
        initialValues: commentValues,
        onSubmit: (values) => {
            updateCommentMutation.mutate(values)
        },
    })

    const updateCommentMutation = useMutation({
        mutationFn: (values:CommentDto) => {
            return editCommentService(values.id, values)
        },
        onSuccess: () => {
            setMessageItem({successMessage: "Comment edited successfully!"})
            refetchJournalComments()
            stopEditing()
        },
        onError: (error) => {
            setMessageItem({error: error})
        }
    })

    const handleSave = () => {
        formik.handleSubmit();
    }
    const handlekeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
        if(event.key === "Enter" && formik.dirty){
            event.preventDefault();
            handleSave();
            descriptionInputRef.current && descriptionInputRef.current.blur();
        } else if(event.key === "Enter" && !formik.dirty) {
            //if no changes when enter pressed just stop editing
            stopEditing()
        }
    }

    return (
        <Stack direction={"row"} spacing={1} sx={{width:"100%"}}>
            <TextField
                inputRef={descriptionInputRef}
                id="description"
                name="description"
                value={formik.values.description}
                autoFocus
                onChange={formik.handleChange}
                onBlur={(e) => {
                    formik.handleBlur(e)
                    stopEditing()
                }}
                onFocus={() => {
                    //move cursor to the end
                    setTimeout(() => {
                        const input = descriptionInputRef.current;
                        if(input){
                            const length = input.value.length;
                            input.setSelectionRange(length, length);
                        }
                    }, 1);
                }}
                onKeyDown={(e) => handlekeyPress(e)}
                variant='outlined'
                size="small"
                multiline
                maxRows={4}
                sx={{width:"100%"}}
                error={formik.touched.description && Boolean(formik.errors.description)}
                helperText={formik.touched.description && formik.errors.description}
            />
            {updateCommentMutation.isPending ? (
                <CircularProgress size={24}/>
            ) : (
                <Stack direction={"row"} sx={{paddingTop:"5px", display:"flex", justifyContent:"end"}} spacing={1}>
                    <IconButton size="small" onMouseDown={handleSave} disabled={!formik.dirty}>
                        <DoneIcon/>
                    </IconButton>
                    <IconButton size="small" onMouseDown={stopEditing}>
                        <CloseIcon/>
                    </IconButton>
                    
                </Stack> 
            )}
            
        </Stack>
     )
}

export default EditComment