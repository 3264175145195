import React from 'react'
import { RouteType } from "./config";
import HomePage from '../pages/AdminSite/HomePage/HomePage';
import ChecklistIcon from '@mui/icons-material/Checklist';
import SettingsIcon from '@mui/icons-material/Settings';
import PageLayout from '../pages/UtilityPages/PageLayout';
import AttractionInspectionsIndex from '../pages/AdminSite/AttractionInspections/AttractionInspectionsIndex';
import Journal from 'src/pages/User Site/Inspections/Journal/Journal';
import Inspection from 'src/pages/User Site/Inspections/Inspection/Inspection';
import AttendanceSheetDemo from 'src/pages/User Site/TrainingDocuments/AttendanceSheetDemo';
import SchoolIcon from '@mui/icons-material/School';
import PeopleIcon from '@mui/icons-material/People';
import TeamMember from 'src/pages/AdminSite/HumanResources/TeamMembers/TeamMember/TeamMemberCreator';
import TeamMembers from 'src/pages/AdminSite/HumanResources/TeamMembers/TeamMembers';
import AssessmentTeamMembers from 'src/pages/User Site/Training/Assessments/AssessmentTeamMembers';
import LearningDocumentPage from 'src/pages/User Site/Training/LearningDocumentPage/LearningDocumentPage';
import TodaysTrainingSessions from 'src/pages/User Site/Training/TrainingSession/TodaysTrainingSessions';
import TrainingSessionPage from 'src/pages/User Site/Training/TrainingSession/TrainingSessionPage';
import AttendanceSheet from 'src/pages/User Site/Training/TrainingSession/Components/AttendanceSheet/AttendanceSheet';
import IndividualAttendanceSheet from 'src/pages/User Site/Training/TrainingSession/Components/AttendanceSheet/IndividualAttendanceSheet';
import BulkActions from 'src/pages/AdminSite/HumanResources/BulkActions/BulkActions';
import TrainingGroupsTrainer from 'src/pages/AdminSite/HumanResources/TrainingGroupsTrainer/TrainingGroupsTrainer';
import TrainingGroupsTrainee from 'src/pages/AdminSite/HumanResources/TrainingGroupsTrainee/TrainingGroupsTrainee';
import Journals from 'src/pages/User Site/Inspections/Journal/Journals';
import AdminPageLayout from 'src/pages/AdminSite/System/AdminPageLayout';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import TrainingAdminPage from 'src/pages/AdminSite/System/Training/TrainingAdminPage';
import CompletedInspections from 'src/pages/CompletedDocuments/CompletedInspections/CompletedInspections';
import JournalSearch from 'src/pages/User Site/Inspections/Journal/JournalSearch';
import JournalEntry from 'src/pages/User Site/Inspections/Journal/JournalEntry/JournalEntry';
import AttractionInspectionsAdminPage from 'src/pages/AdminSite/System/AttractionInspections/AttractionInspectionsAdminPage';
import InspectionV2 from 'src/pages/User Site/Inspections/InspectionV2/Inspection';
import InspectionsOverview from 'src/pages/User Site/Inspections/InspectionsOverview/InspectionsOverview';
import CompletedAttractionInspection from 'src/pages/CompletedDocuments/CompletedInspections/CompletedResponses/CompletedAttractionInspection';
import InspectionJournalEntries from 'src/pages/User Site/Inspections/InspectionV2/Components/InspectionJournalEntries';
import AttractionForm from 'src/pages/AdminSite/AttractionInspections/Attractions/AttractionForm/AttractionForm';
import InspectionChecklist from 'src/pages/AdminSite/AttractionInspections/Inspections/InspectionChecklist/InspectionChecklist';
import InspectionsChecklistCreation from 'src/pages/AdminSite/AttractionInspections/Inspections/InspectionsChecklistCreation/InspectionsChecklistCreation';
import InspectionsChecklists from 'src/pages/AdminSite/AttractionInspections/Inspections/InspectionsChecklists';
import Locations from 'src/pages/AdminSite/Resources/Locations/Locations';
import ResourcesIndex from 'src/pages/AdminSite/Resources/ResourcesIndex';
import ResourcesPageLayout from 'src/pages/AdminSite/Resources/ResourcesPageLayout';
import LearningDocumentForm from 'src/pages/AdminSite/Training/LearningDocuments/LearningDocument/LearningDocumentForm';
import LearningDocumentCreator from 'src/pages/AdminSite/Training/LearningDocuments/LearningDocumentCreator/LearningDocumentCreator';
import LearningDocuments from 'src/pages/AdminSite/Training/LearningDocuments/LearningDocuments';
import LearningItems from 'src/pages/AdminSite/Training/LearningItems/LearningItem/LearningItems';
import TrainingGroupCreator from 'src/pages/AdminSite/Training/TrainingGroups/TrainingGroupCreator/TrainingGroupCreator';
import TrainingGroups from 'src/pages/AdminSite/Training/TrainingGroups/TrainingGroups';
import TrainingSessionCreator from 'src/pages/AdminSite/Training/TrainingSessions/TrainingSessionCreator/TrainingSessionCreator';
import TrainingSessions from 'src/pages/AdminSite/Training/TrainingSessions/TrainingSessions';
import CompletedIndividualAttendanceSheet from 'src/pages/CompletedDocuments/CompletedLearningDocuments/AttendanceSheet/CompletedIndividualAttendanceSheet';
import CompletedLearningDocument from 'src/pages/CompletedDocuments/CompletedLearningDocuments/LearningDocument/CompletedLearningDocument';
import Attractions from 'src/pages/AdminSite/AttractionInspections/Attractions/Attractions';
import PermittedInspections from 'src/pages/AdminSite/AttractionInspections/PermittedInspections/PermittedInspections';
import TrainingMemos from 'src/pages/AdminSite/Training/TrainingMemos/TrainingMemos';
import TrainingMemoCreator from 'src/pages/AdminSite/Training/TrainingMemos/TrainingMemo/TrainingMemoCreator';
import TrainingMemo from 'src/pages/User Site/Training/TrainingMemos/TrainingMemo';
import OutstandingTrainingMemos from 'src/pages/User Site/Training/TrainingMemos/OutstandingTrainingMemos';
import TrainingManualEvidences from 'src/pages/AdminSite/Training/TrainingManualUpload/TrainingManualEvidences';
import RoleAssignments from 'src/pages/AdminSite/System/RoleConfiguration/RoleAssignments';
import TrainingManualEvidence from 'src/pages/AdminSite/Training/TrainingManualUpload/TrainingManualEvidence';



export const adminRoutes: RouteType[] = [
    {
        index: true,
        element: <HomePage/>,
        state: "home",
        pageName: "Home"
    },
    {
        path: "/admin/HumanResources",
        state: "HumanResources",
        element: <ResourcesPageLayout/>,
        sidebarProps: {
            displayText: "Human Resources",
            icon: <PeopleIcon/>
        },
        child: [
            {
                path: "/admin/HumanResources/TeamMembers",
                element: <TeamMembers/>,
                state: "HumanResources.TeamMembers",
                pageName: "Team Members",
                sidebarProps: {
                    displayText: "Team Members"
                }
            },
            {
                path: "/admin/HumanResources/TeamMembers/:id",
                element: <TeamMember/>,
                state: "HumanResources.TeamMembers",
                pageName: "Team Member"
            },
            {
                path: "/admin/HumanResources/Actions",
                element: <BulkActions/>,
                state: "HumanResources.Actions",
                pageName: "Actions",
                sidebarProps: {
                    displayText: "Actions"
                }
            },
            {
                path: "/admin/HumanResources/TrainingGroupsTrainer",
                element: <TrainingGroupsTrainer/>,
                state: "HumanResources.TrainingGroupsTrainer",
                pageName: "Training Groups [Trainer]",
                guideLink: "https://adventureworldthemepark.sharepoint.com/:f:/s/AW-Software/EgKyEczskwlNtCquq_XhhloBtOWMgf3b6WLTaLbdJOcKXA?e=YRXAtd",
                sidebarProps: {
                    displayText: "Training Groups [Trainer]"
                }
            },
            {
                path: "/admin/HumanResources/TrainingGroupsTrainee",
                element: <TrainingGroupsTrainee/>,
                state: "HumanResources.TrainingGroupsTrainee",
                pageName: "Training Groups [Trainee]",
                guideLink: "https://adventureworldthemepark.sharepoint.com/:f:/s/AW-Software/EgKyEczskwlNtCquq_XhhloBtOWMgf3b6WLTaLbdJOcKXA?e=YRXAtd",
                sidebarProps: {
                    displayText: "Training Groups [Trainee]"
                }
            }
        ]
    },
    {
        path: "/admin/resources",
        element: <ResourcesPageLayout/>,
        state: "resources",
        sidebarProps: {
            displayText: "Resources",
            icon: <SettingsIcon/>
        },
        child: [
            {
                index: true,
                element: <ResourcesIndex/>,
                state: "resources.index",
            },
            {
                path: "/admin/resources/locations",
                element: <Locations/>,
                state: "resources.locations",
                pageName: "Locations",
                sidebarProps: {
                    displayText: "Locations"
                }
            }
        ]
    },
    {
        path: "/admin/attractionInspections",
        element: <PageLayout/>,
        state: "attractionInspections",
        sidebarProps: {
            displayText: "Attraction Inspections",
            icon: <ChecklistIcon/>
        },
        child: [
            {
                index: true,
                element: <AttractionInspectionsIndex/>,
                state: "checklists.index"
            },
            {
                path: "/admin/attractionInspections/attractions",
                element: <Attractions/>,
                state: "attractionInspections.attractions",
                pageName: "Attractions",
                sidebarProps: {
                    displayText: "Attractions"
                }
            },
            {
                path: "/admin/attractionInspections/attractions/:id",
                element: <AttractionForm/>,
                state: "attractionInspections.attractions",
                pageName: "Attraction"
            },
            {
                path: "/admin/attractionInspections/inspections",
                element: <InspectionsChecklists/>,
                state: "attractionInspections.inspections",
                pageName: "Inspections",
                sidebarProps: {
                    displayText: "Inspections"
                }
            },
            {
                path: "/admin/attractionInspections/inspections/:id",
                element: <InspectionChecklist/>,
                state: "attractionInspections.inspection",
                pageName: "Attraction Inspection"
            },
            {
                path: "/admin/attractionInspections/inspections/:inspectionId/:versionId",
                element: <InspectionsChecklistCreation/>,
                state: "attractionInspections.inspections.creator",
                pageName: "Attraction Inspection"
            },
            {
                path: "/admin/attractionInspections/permittedInspections",
                element: <PermittedInspections/>,
                state: "attractionInspections.permittedInspections",
                pageName: "Permitted Inspections",
                sidebarProps: {
                    displayText: "Permitted Inspections"
                }
            },
            {
                path: "/admin/attractionInspections/completedInspections",
                element: <CompletedInspections/>,
                state: "attractionInspections.inspections.completed",
                pageName: "Completed Inspections",
                sidebarProps: {
                    displayText: "Completed Inspections",
                }
            },
        ]
    }, 
    {
        path: "/admin/training",
        element: <PageLayout/>,
        state: "training",
        sidebarProps: {
            displayText: "Training",
            icon: <SchoolIcon/>
        },
        child: [
            {
                path: "/admin/training/learningDocuments",
                element: <LearningDocuments/>,
                state: "training.learningDocuments",
                pageName: "Learning Documents",
                sidebarProps: {
                    displayText: "Learning Documents"
                }
            },
            {
                path: "/admin/training/learningDocuments/:id",
                element: <LearningDocumentForm/>,
                state: "training.learningDocuments",
                pageName: "Learning Document"
            },
            {
                path: "/admin/training/learningDocuments/:learningDocumentId/:versionId",
                element: <LearningDocumentCreator/>,
                state: "training.learningDocuments",
                pageName: "Learning Document"
            }, 
            {
                path: "/admin/training/learningItems",
                element: <LearningItems/>,
                state: "training.learningItems",
                pageName: "Learning Items",
                sidebarProps: {
                    displayText: "Learning Items"
                },
            },
            {
                path: "/admin/training/trainingGroups",
                element: <TrainingGroups/>,
                state: "training.trainingGroup",
                pageName: "Training Groups",
                sidebarProps: {
                    displayText: "Training Groups"
                },
            },
            {
                path: "/admin/training/trainingGroups/:id",
                element: <TrainingGroupCreator/>,
                state: "training.trainingGroup",
                pageName: "Training Group"
            },
            {
                path: "/admin/training/trainingSessions",
                element: <TrainingSessions/>,
                state:"training.trainingSessions",
                pageName: "Training Sessions",
                sidebarProps: {
                    displayText: "Training Sessions"
                },
            },
            {
                path: "/admin/training/trainingSessions/:id",
                element: <TrainingSessionCreator/>,
                state: "training.trainingSessions",
                pageName: "Training Session"
            },
            {
                path: "/admin/training/trainingMemos",
                element: <TrainingMemos/>,
                state: "training.TrainingMemos",
                pageName: "Training Memorandum",
                guideLink: "https://adventureworldthemepark.sharepoint.com/:f:/s/AW-Software/Evl_erOJk_FBrNlKZs8hxVsBv3GmG1oElDOYsXLx39NEAQ?e=UI21Vr",
                sidebarProps: {
                    displayText: "Training Memos"
                }
            },
            {
                path: "/admin/training/trainingMemos/:id",
                element: <TrainingMemoCreator/>,
                state: "training.TrainingMemos",
                guideLink: "https://adventureworldthemepark.sharepoint.com/:f:/s/AW-Software/Evl_erOJk_FBrNlKZs8hxVsBv3GmG1oElDOYsXLx39NEAQ?e=UI21Vr",
                pageName: "Training Memorandum"
            },
            {
                path: "/admin/training/manualEvidence",
                element: <TrainingManualEvidences />,
                state: "training.manualEvidences",
                pageName: "Manual Evidence",
                guideLink: "https://adventureworldthemepark.sharepoint.com/:f:/s/AW-Software/EplW579UwVxCvz1CnSaGJ68BXm4Qd01FdUh_jV3evjxzwg?e=6Nel95",
                sidebarProps: {
                    displayText: "Manual Evidence"
                }
            },
            {
                path: "/admin/training/manualEvidence/:id",
                element: <TrainingManualEvidence />,
                state: "training.manualEvidence.upload",
                guideLink: "https://adventureworldthemepark.sharepoint.com/:f:/s/AW-Software/EplW579UwVxCvz1CnSaGJ68BXm4Qd01FdUh_jV3evjxzwg?e=6Nel95",
                pageName: "Manual Evidence"
            }
        ]
    },
    {
        path: "/admin/admin",
        element: <AdminPageLayout/>,
        state: "admin.admin",
        sidebarProps: {
            displayText: "Admin",
            icon: <AdminPanelSettingsIcon/>
        },
        child: [
            {
                path: "/admin/admin/training",
                element: <TrainingAdminPage/>,
                state: "admin.admin.Training",
                pageName: "Training Admin Page",
                sidebarProps: {
                    displayText: "Training"
                },
            }, 
            {
                path: "/admin/admin/attractionInspections",
                element: <AttractionInspectionsAdminPage/>,
                state: "admin.admin.AttractionInspections",
                pageName: "Attraction Inspections Admin Page",
                sidebarProps: {
                    displayText: "Attraction Inspections"
                },
            },
            {
                path: "/admin/admin/roleAssignments",
                element: <RoleAssignments />,
                state: "admin.admin.RoleAssignments",
                pageName: "Role Assignments",
                sidebarProps: {
                    displayText: "Role Assignments"
                }
            }
        ]
    }
    
 
]

export const userRoutes: RouteType[] =[
    {
        index: true,
        element: <HomePage/>,
        state: "home",
        pageName: "Home"
    },  
    {
        path: "/user/attractionInspections",
        element: <PageLayout/>,
        state: "user.attractionInspections",
        sidebarProps: {
            displayText: "Attraction Inspections",
            icon: <ChecklistIcon/>
        },
        child: [
            {
                path: "/user/attractionInspections/inspections",
                element: <InspectionsOverview/>,
                state: "user.attractionInspections.Inspections",
                pageName: "Inspections Overview",
                sidebarProps: {
                    displayText: "Inspections Overview"
                }
            },
            {
                path: "/user/attractionInspections/inspections/:responseId",
                element: <InspectionV2 demoMode={false}/>,
                state: "user.attractionInspections.inspections",
                pageName: "Inspection Checklist"
            },
            {
                path: "/user/attractionInspections/inspections/:responseId/:attractionId",
                element: <InspectionJournalEntries/>,
                state: "user.attractionInspections.inspections.journalEntries",
                pageName: "Journal Entries"
            },
            {
                path: "/user/attractionInspections/inspections/:responseId/:attractionId/:entryId",
                element: <JournalEntry/>,
                state: "user.JournalSearch",
                pageName: "Attraction Journal"
            },
            {
                path: "/user/attractionInspections/inspectionsV1/:responseId",
                element: <Inspection/>,
                state: "user.attractionInspections.inspections",
                pageName: "Inspection Checklist"
            },
            {
                path: "/user/attractionInspections/Journals",
                element: <Journals/>,
                state: "user.attractionInspections.Journals",
                pageName: "Journals",
                sidebarProps: {
                    displayText: "Journals",
                },
            },
            {
                path: "/user/attractionInspections/Journals/:attractionId/search",
                element: <JournalSearch/>,
                state: "user.attractionInspections.JournalSearch",
                pageName: "Attraction Journal"
            },
            {
                path: "/user/attractionInspections/Journals/:attractionId",
                element: <Journal/>,
                state: "user.attractionInspections.Journal",
                pageName: "Attraction Journal"
            },
            {
                path: "/user/attractionInspections/Journals/:attractionId/:entryId",
                element: <JournalEntry/>,
                state: "user.attractionInspections.JournalSearch",
                pageName: "Attraction Journal"
            },
            {
                path: "https://adventureworldthemepark.sharepoint.com/sites/AWMaintenanceTeam2",
                targetBlank: true,
                element: <></>,
                state: "user.attractionInspections.Documents",
                pageName: "Attraction Documents",
                sidebarProps: {
                    displayText: "Attraction Documents"
                }
            }
        ]
    },
    {
        path: "/user/DemoInspection/:versionId",
        element: <InspectionV2 demoMode={true}/>,
        state: "InspectionDemo",
        pageName: "Inspection Demonstration"
    },
    {
        path: "/user/training",
        element: <PageLayout/>,
        state: "user.training",
        pageName: "Training",
        sidebarProps: {
            displayText: "Training",
            icon: <SchoolIcon/>
        },
        child: [
            {
                path: "/user/training/sessions",
                element: <TodaysTrainingSessions/>,
                state: "user.training.sessions",
                pageName: "Training Sessions",
                sidebarProps: {
                    displayText: "Today's Sessions",
                },
            },
            {
                path: "/user/training/sessions/:id",
                element: <TrainingSessionPage/>,
                state: "user.training.session",
                pageName: "Training Session",
            },
            {
                path: "/user/training/sessions/:id/:responseId",
                element: <LearningDocumentPage demoMode={false}/>,
                state: "user.training.Session",
                pageName: "Training Session",
            },
            {
                path: "/user/training/sessions/:id/:responseId/Attendance",
                element: <AttendanceSheet/>,
                state: "user.training.Session.Attendance",
                pageName: "Training Session Attendance"
            },
            {
                path: "/user/training/sessions/:id/Attendance/:responseId",
                element: <IndividualAttendanceSheet/>,
                state: "user.training.Session.Attendance",
                pageName: "Training Session Attendance"
            },
            {
                path: "/user/training/assessments",
                element: <AssessmentTeamMembers/>,
                state: "user.training.assessments",
                pageName: "Training Assessments",
                sidebarProps: {
                    displayText: "Assessments",
                },
            },
            {
                path: "/user/training/assessments/:responseId",
                element: <LearningDocumentPage demoMode={false}/>,
                state: "user.training.assessment",
                pageName: "Training Assessment",
            },
            {
                path: "/user/training/memos",
                element: <OutstandingTrainingMemos />,
                guideLink: "https://adventureworldthemepark.sharepoint.com/:f:/s/AW-Software/EvSBDGE-J-xCgpJdw6o8d-IB1scK4Jw5ankGzq36C568HA",
                state: "user.training.memos",
                pageName: "Training Memos",
                sidebarProps: {
                    displayText: "Training Memos"
                }
            },
            {
                path: "/user/training/memos/:id",
                element: <TrainingMemo />,
                state: "user.training.memos.memo",
                pageName: "Training Memo"
            }
        ]
    },
    {
        path: "/user/DemotrainingDocuments/:versionId",
        element: <LearningDocumentPage demoMode={true}/>,
        state: "demo.trainingDocuments",
        pageName: "Training Document Demo"
    },
    {
        path: "/user/DemotrainingDocuments/:id/Attendance",
        element: <AttendanceSheetDemo/>,
        state: "demo.trainingDocuments.Attendance",
        pageName: "Attendance Sheet Demo"
    }
]

export const completedDocumentRoutes: RouteType[] = [
    {
        path: "/completed/training/trainingSessions/:trainingSessionId/Attendance/:responseId",
        element: <CompletedIndividualAttendanceSheet/>,
        state: "completed.training.trainingSessions.individualAttendanceSheet",
        pageName: "Attendance Sheet"
    },
    {
        path: "/completed/training/trainingSessions/:trainingSessionId/:responseId",
        element: <CompletedLearningDocument/>,
        state: "completed.training.trainingSessions.learningDocument",
        pageName: "Learning Document"
    },
    {
        path: "/completed/attractionInspections/:responseId",
        element: <CompletedAttractionInspection/>,
        state: "completed.attractionInspections.attractionInspection",
        pageName: "Attraction Inspection"
    },
    {
        path: "/completed/training/assessments/:responseId",
        element: <CompletedLearningDocument/>,
        state: "completed.training.assessments.learningDocument",
        pageName: "Training Assessment"
    }
]

export default adminRoutes;