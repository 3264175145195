import { LoadingButton } from '@mui/lab'
import { Stack, TextField, Select, MenuItem, Table, TableHead, TableCell, TableRow, TableBody } from '@mui/material'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from 'dayjs'
import React from 'react'
import QuestionTitle from 'src/components/QuestionTitle/QuestionTitle'
import SimpleEditor from 'src/components/SimpleEditor/SimpleEditor'
import { TrainingMemoDto, TrainingMemoType, TrainingMemoTypeString } from 'src/dtos/Training/TrainingMemo.dto'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { FormikProps } from 'formik'
import { MessageItem } from 'src/components/errorHandlingSnackbar'
import TrainingMemoService from 'src/Services/Training/TrainingMemoService'
import { useParams } from 'react-router-dom'
import SingleFileUpload from 'src/components/SingleFileUpload/SingleFileUpload'
import { EmailToNameString } from 'src/Utils/helperFunc'
import { acceptedImageFileTypes, dateTimeOptions } from 'src/config/constants'

type Props = {
    formik: FormikProps<TrainingMemoDto>
    loading: boolean;
    setSubmitType: React.Dispatch<React.SetStateAction<"save" | "issue">>
    setMessageItem: React.Dispatch<React.SetStateAction<MessageItem>>
}
function MemoMemorandum({formik, loading, setSubmitType, setMessageItem}:Props) {
    const {id} = useParams();

    const disabled = formik.values.dateIssued != null; 

    const handleSaveClick = () => {
        setSubmitType("save")
        setTimeout(() => {
            formik.handleSubmit()
        }, 1);
    }

    const handleIssueClick = () => {
        setSubmitType("issue")
        setTimeout(() => {
            formik.handleSubmit()
        }, 1);
    }    

    const handleChangeDetailChange = (content: string) => {
        formik.setFieldValue("changeDetail", content)
    }

    return (
        <Stack spacing={1}>
            <div>
                <QuestionTitle title="Title" required/>
                <TextField
                    id="label"
                    name="label"
                    value={formik.values.label}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    size="small"
                    sx={{width:"100%"}}
                    disabled={disabled}
                    error={formik.touched.label && Boolean(formik.errors.label)}
                    helperText={formik.touched.label && formik.errors.label}
                />
            </div>
            <div>
                <QuestionTitle title="Date Effective" required/>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker 
                    name="dateEffective"
                    format='DD MMMM YYYY'
                    value={formik.values.dateEffective ? dayjs(formik.values.dateEffective): null}
                    onChange={(value) => {
                        if(value){
                            formik.setFieldValue("dateEffective", value.format("YYYY-MM-DD"))
                        } else {
                            formik.setFieldValue("dateEffective", null)
                        }
                    }}
                    slotProps={{
                        textField: {
                            size: "small",
                            error: formik.touched.dateEffective && Boolean(formik.errors.dateEffective),
                            helperText: formik.touched.dateEffective && formik.errors.dateEffective
                        }
                    }}
                    disabled={disabled}
                    sx={{width:"20rem"}}
                />
            </LocalizationProvider>
            </div>
            <div>
                <QuestionTitle title="Type of Change" required/>
                <Select
                    id="type"
                    name="type"
                    value={formik.values.type}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    size="small"
                    disabled={disabled}
                    sx={{width:"20rem"}}
                >
                    <MenuItem value={TrainingMemoType.ProceduralChange}>{TrainingMemoTypeString[TrainingMemoType.ProceduralChange]}</MenuItem>
                    <MenuItem value={TrainingMemoType.ProcedureReinforcement}>{TrainingMemoTypeString[TrainingMemoType.ProcedureReinforcement]}</MenuItem>
                </Select>
            </div>
            <div>
                <QuestionTitle title="Change Overview" required/>
                <TextField
                    id="changeOverview"
                    name="changeOverview"
                    value={formik.values.changeOverview}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    multiline
                    maxRows={2}
                    size="small"
                    sx={{width:"100%"}}
                    disabled={disabled}
                    error={formik.touched.changeOverview && Boolean(formik.errors.changeOverview)}
                    helperText={formik.touched.changeOverview && formik.errors.changeOverview}
                />
            </div>
            <div style={{marginBottom:"-3rem"}}>
                <QuestionTitle title="Change Detail" required/>
                <SimpleEditor
                    content={formik.values.changeDetail}
                    onBlurSaveContent={handleChangeDetailChange}
                    disabled={disabled}
                />
            </div>
            <div>
                <QuestionTitle title="Intent of Change" required/>
                <TextField
                    id="intentOfChange"
                    name="intentOfChange"
                    value={formik.values.intentOfChange}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    multiline
                    maxRows={2}
                    size="small"
                    sx={{width:"100%"}}
                    disabled={disabled}
                    error={formik.touched.intentOfChange && Boolean(formik.errors.intentOfChange)}
                    helperText={formik.touched.intentOfChange && formik.errors.intentOfChange}
                    />
            </div>
            {id && id !== "new" && (
                <div>
                    <QuestionTitle title="SOP Reference" infoText='Accepts image type of png, jpeg, webP, avif'/>
                    <SingleFileUpload
                        acceptedFileTypes={acceptedImageFileTypes}
                        file={formik.values.sopReference}
                        setFile={(file) => formik.setFieldValue("sopReference", file)}
                        addFileService={(file: File) => TrainingMemoService.AddSOPReference(id, file)}
                        deleteFileService={(fileId: string) => TrainingMemoService.DeleteSOPReference(id, fileId)}
                        setMessageItem={setMessageItem}
                        disabled={disabled}
                    />
                    
                </div>
            )}
            <Stack direction={"row"} spacing={2} sx={{padding:"1rem", display:"flex", justifyContent: "end"}}>
                <LoadingButton 
                    variant={"contained"} 
                    disabled={!formik.dirty || disabled} 
                    loading={loading}
                    onClick={handleSaveClick}
                >
                    Save
                </LoadingButton>
                <LoadingButton 
                    variant={"contained"} 
                    loading={loading}
                    onClick={handleIssueClick}
                    disabled={disabled}
                >
                    Issue
                </LoadingButton>
            </Stack>
            <Table size='small' sx={{width: "fit-content"}}>
                <TableHead>
                    <TableRow>
                        <TableCell>Action</TableCell>
                        <TableCell>Person</TableCell>
                        <TableCell>Time</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                        <TableCell>
                            Created
                        </TableCell>
                        <TableCell>
                            {EmailToNameString(formik.values.createdBy)}
                        </TableCell>
                        <TableCell>
                            {formik.values.createdOn && new Date(formik.values.createdOn).toLocaleDateString(undefined, dateTimeOptions)}
                        </TableCell>
                    </TableRow>
                    {formik.values.modifiedOn && (
                        <TableRow>
                            <TableCell>
                                Last Modifed 
                            </TableCell>
                            <TableCell>
                                {EmailToNameString(formik.values.modifiedBy)}
                            </TableCell>
                            <TableCell>
                                {new Date(formik.values.modifiedOn).toLocaleDateString(undefined, dateTimeOptions)}
                            </TableCell>
                        </TableRow>
                    )}
                    {formik.values.dateIssued && (
                        <TableRow>
                            <TableCell>
                                Issued
                            </TableCell>
                            <TableCell>
                                {EmailToNameString(formik.values.issuedBy)}
                            </TableCell>
                            <TableCell>
                                {new Date(formik.values.dateIssued).toLocaleDateString(undefined, dateTimeOptions)}
                            </TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
        </Stack>
    )
}

export default MemoMemorandum