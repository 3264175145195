import axios, { AxiosResponse } from "axios";
import { ManualEvidenceItem, ManualEvidenceStatus, TrainingManualEvidenceDto } from "src/dtos/Training/TrainingManualEvidence.dto";
import { GetAuthorisation } from "src/Utils/authUtils";
import { ListParameters, SortDirection } from "../ListParameters";
import { ListResponseDto } from "src/dtos/ListResponse.dto";
import { LearningMaterialType } from "src/dtos/Training/TrainingGroup.dto";
import { CommentDto } from "src/dtos/Comment.dto";
import { FileMetadata } from "src/dtos/FileMetadata.dto";
import { NameDto } from "src/dtos/NameDto.dto";

const apiBaseUrl = process.env.REACT_APP_API_BASE_URL + "/TrainingManualEvidence";
const debug = process.env.REACT_APP_DEBUG;

const emptyData: ListResponseDto = {
    data: [],
    totalRecords: 0
}

export default class TrainingManualEvidenceService {
    static GetDefaultValues() {
        const currentMonth = new Date().getMonth();
        let year = new Date().getFullYear();
        
        if (currentMonth >= 7)
            year += 1;
        const eofy = new Date(`${year}-06-30`)

        const defaultVals: TrainingManualEvidenceDto = {
            id: "00000000-0000-0000-0000-000000000000",
            label: "",
            expiry: eofy,
            status: ManualEvidenceStatus.Draft,
            items: [],
            teamMembers: [],
            enabled: true
        }
        return defaultVals;
    }

    static async Get(id: string): Promise<TrainingManualEvidenceDto> {
        try {
            const authorization = await GetAuthorisation();
            const response: AxiosResponse<TrainingManualEvidenceDto> = await axios.get<TrainingManualEvidenceDto>(`${apiBaseUrl}/Get/${id}`, {
                headers: {
                    Authorization: authorization
                }
            });
            return response.data;
        } catch (error: any) {
            debug && console.log(error)
            throw error;
        }
    }

    static async GetList(listParam?: ListParameters, searchTerm?: string, includeDisabled?: boolean): Promise<ListResponseDto> {
        try {
            const authorization = await GetAuthorisation();

            const response: AxiosResponse<ListResponseDto> = await axios.get<ListResponseDto>(`${apiBaseUrl}/GetList`, {
                headers: {
                    Authorization: authorization
                },
                params: {
                    page: listParam?.page,
                    pageSize: listParam?.pageSize,
                    sortBy : listParam?.sortBy,
                    sortDirection: listParam?.sortDirection === SortDirection.Ascending ? 0 : listParam?.sortDirection === SortDirection.Descending ? 1 : undefined,
                    searchTerm: searchTerm,
                    includeDisabled: includeDisabled,
                }
            });
            return response.data;
        } catch(error: any) {
            if(error.response !== undefined) {
                if(error.response.status != null && error.response.status === 404) {
                    return emptyData
                }
            }
            debug && console.log(error)
            throw error
        }
    }

    static async GetListAttachments(id: string, page: number, pageSize: number) : Promise<ListResponseDto<FileMetadata>> {
        try {
            const authorization = await GetAuthorisation();
            const response: AxiosResponse<ListResponseDto<FileMetadata>> = await axios.get<ListResponseDto<FileMetadata>>(`${apiBaseUrl}/GetListAttachments/${id}`, {
                headers: {
                    Authorization: authorization
                },
                params: {
                    page: page, 
                    pageSize: pageSize
                }
            });
            return response.data
        } catch (error: any) {
            debug && console.log(error)
            throw error;
        }
    }

    static async GetListComments(id: string, listParam?: ListParameters) : Promise<ListResponseDto<CommentDto>> {
        try {
            const authorization = await GetAuthorisation();
            const response: AxiosResponse<ListResponseDto<CommentDto>> = await axios.get<ListResponseDto<CommentDto>>(`${apiBaseUrl}/GetListComments/${id}`, {
                headers: {
                    Authorization: authorization
                },
                params: {
                    ...listParam,
                }
            });
            return response.data
        } catch (error: any) {
            debug && console.log(error)
            throw error;
        }
    }

    static async GetTeamMembers(id: string): Promise<NameDto[]> {
        try {
            const authorization = await GetAuthorisation();
            const response: AxiosResponse<NameDto[]> = await axios.get<NameDto[]>(`${apiBaseUrl}/GetTeamMembers/${id}`, {
                headers: {
                    Authorization: authorization
                }
            });
            return response.data
        } catch (error: any) {
            debug && console.log(error)
            throw error;
        }
    }

    static async GetPossibleTeamMembers(id: string): Promise<NameDto[]>{
        try {
            const authorization = await GetAuthorisation();
            const response: AxiosResponse<NameDto[]> = await axios.get<NameDto[]>(`${apiBaseUrl}/GetPossibleTeamMembers/${id}`, {
                headers: {
                    Authorization: authorization
                },
            });
            return response.data
        } catch (error: any) {
            debug && console.log(error)
            throw error;
        }
    }

    static async Create(data: TrainingManualEvidenceDto): Promise<AxiosResponse> {
        try {
            const authorization = await GetAuthorisation();
            const response: AxiosResponse = await axios.post<AxiosResponse>(`${apiBaseUrl}/Create`,data,{
                headers: {
                    Authorization: authorization
                }
            });
            return response
        } catch(error: any) {
            debug && console.log(error)
            throw error;
        }
    }
    
    static async Update(id: string, data: TrainingManualEvidenceDto): Promise<AxiosResponse> {
        try {
            const authorization = await GetAuthorisation();
            const response: AxiosResponse = await axios.put<AxiosResponse>(`${apiBaseUrl}/Update/${id}`, data, {
                headers: {
                    Authorization: authorization
                }
            });
            return response;
        } catch (error: any) {
            debug && console.log(error)
            throw error;
        }
    }

    static async AddItem(id: string, type: LearningMaterialType, itemId: string): Promise<AxiosResponse> {
        try {
            const authorization = await GetAuthorisation();
            const response: AxiosResponse = await axios.post<AxiosResponse>(`${apiBaseUrl}/AddItem/${id}`,null,{
                headers: {
                    Authorization: authorization
                }, 
                params: {
                    type: type,
                    itemId: itemId
                }
            });
            return response
        } catch(error: any) {
            debug && console.log(error)
            throw error;
        }
    }

    static async RemoveItem(id: string, item: ManualEvidenceItem): Promise<AxiosResponse> {
        try {
            const authorization = await GetAuthorisation();
            const response: AxiosResponse = await axios.put<AxiosResponse>(`${apiBaseUrl}/RemoveItem/${id}`,item,{
                headers: {
                    Authorization: authorization
                }, 
            });
            return response
        } catch(error: any) {
            debug && console.log(error)
            throw error;
        }
    }

    static async AddComment(id: string, data: CommentDto): Promise<AxiosResponse> {
        try {
            const authorization = await GetAuthorisation();
            const response: AxiosResponse = await axios.post<AxiosResponse>(`${apiBaseUrl}/AddComment/${id}`,data,{
                headers: {
                    Authorization: authorization
                }
            });
            return response
        } catch(error: any) {
            debug && console.log(error)
            throw error;
        }
    }

    static async UpdateComment(commentId: string, data: CommentDto): Promise<AxiosResponse> {
        try {
            const authorization = await GetAuthorisation();
            const response: AxiosResponse = await axios.put<AxiosResponse>(`${apiBaseUrl}/UpdateComment/${commentId}`,data,{
                headers: {
                    Authorization: authorization
                }
            });
            return response
        } catch(error: any) {
            debug && console.log(error)
            throw error;
        }
    }

    static async AddFile(journalEntryId: string, file: File): Promise<AxiosResponse<FileMetadata>> {
        try {
            const authorization = await GetAuthorisation();

            const formData = new FormData();
            formData.append("file", file);

            const response: AxiosResponse = await axios.post<AxiosResponse>(`${apiBaseUrl}/AddFile/${journalEntryId}`,formData,{
                headers: {
                    Authorization: authorization
                }
            });
            return response
        } catch(error: any) {
            debug && console.log(error)
            throw error;
        }
    }

    static async DeleteFile(id: string, fileId: string): Promise<AxiosResponse> {
        try {
            const authorization = await GetAuthorisation();

            const response: AxiosResponse = await axios.delete<AxiosResponse>(`${apiBaseUrl}/DeleteFile`,{
                headers: {
                    Authorization: authorization
                }, 
                params: {
                    id: id,
                    fileId: fileId
                }
            });
            return response
        } catch(error: any) {
            debug && console.log(error)
            throw error;
        }
    }

    static async AddTeamMember(id: string, teamMember: NameDto): Promise<AxiosResponse> {
        try {
            const authorization = await GetAuthorisation();
            const response: AxiosResponse = await axios.post<AxiosResponse>(`${apiBaseUrl}/AddTeamMember/${id}`,teamMember,{
                headers: {
                    Authorization: authorization
                }
            });
            return response
        } catch(error: any) {
            debug && console.log(error)
            throw error;
        }
    }

    static async RemoveTeamMember(id: string, teamMember: NameDto): Promise<AxiosResponse> {
        try {
            const authorization = await GetAuthorisation();
            const response: AxiosResponse = await axios.put<AxiosResponse>(`${apiBaseUrl}/RemoveTeamMember/${id}`,teamMember,{
                headers: {
                    Authorization: authorization
                }
            });
            return response
        } catch(error: any) {
            debug && console.log(error)
            throw error;
        }
    }

    static async SubmitForApproval(id: string): Promise<AxiosResponse> {
        try {
            const authorization = await GetAuthorisation();
            const response: AxiosResponse = await axios.put<AxiosResponse>(`${apiBaseUrl}/SubmitForApproval/${id}`,null,{
                headers: {
                    Authorization: authorization
                }
            });
            return response
        } catch(error: any) {
            debug && console.log(error)
            throw error;
        }
    }

    static async Reject(id: string): Promise<AxiosResponse<TrainingManualEvidenceDto>> {
        try {
            const authorization = await GetAuthorisation();
            const response: AxiosResponse = await axios.put<AxiosResponse>(`${apiBaseUrl}/Reject/${id}`,null,{
                headers: {
                    Authorization: authorization
                }
            });
            return response
        } catch(error: any) {
            debug && console.log(error)
            throw error;
        }
    }

    static async RejectMoreEvidenceRequired(id: string): Promise<AxiosResponse<TrainingManualEvidenceDto>> {
        try {
            const authorization = await GetAuthorisation();
            const response: AxiosResponse = await axios.put<AxiosResponse>(`${apiBaseUrl}/RejectMoreEvidenceRequired/${id}`,null,{
                headers: {
                    Authorization: authorization
                }
            });
            return response
        } catch(error: any) {
            debug && console.log(error)
            throw error;
        }
    }

    static async Approve(id: string): Promise<AxiosResponse<TrainingManualEvidenceDto>> {
        try {
            const authorization = await GetAuthorisation();
            const response: AxiosResponse = await axios.put<AxiosResponse>(`${apiBaseUrl}/Approve/${id}`,null,{
                headers: {
                    Authorization: authorization
                }
            });
            return response
        } catch(error: any) {
            debug && console.log(error)
            throw error;
        }
    }
}