import { sanitizeHtml } from "src/Utils/helperFunc";

export const createUnorderedListMarkup = (unorderedList?: string) => {
    if(unorderedList){
        return {
          __html: sanitizeHtml(unorderedList)
        };
    } else {
        return {
            __html: "<div></div>"
        }
    }
}