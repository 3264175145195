import React from 'react'
import CommonPoint from './PointTypes/CommonPoint'
import { LearningDocumentPoint, LearningDocumentPointType } from 'src/dtos/Training/LearningDocument.dto'
import { LearningDocumentPointResponse } from 'src/dtos/Training/LearningDocumentResponse.dto';
import TroubleShootingPoint from './PointTypes/TroubleShootingPoint';
import TallyCounterPoint from './PointTypes/TallyCounterPoint';

type Props = {
    index: number;
    point: LearningDocumentPoint;
    pointResponse: LearningDocumentPointResponse;
    previousPointType?: LearningDocumentPointType;
    allIconlessPoints?: boolean;
}
function Point({index, point, pointResponse, previousPointType, allIconlessPoints}: Props) {

    if(point.type === LearningDocumentPointType.tallyCounter)
        return (
            <TallyCounterPoint
                index={index}
                point={point}
                pointResponse={pointResponse}
                allIconlessPoints={allIconlessPoints}
            />
        )
    if(point.type === LearningDocumentPointType.troubleshooting)
        return (
            <TroubleShootingPoint
                index={index}
                point={point}
                pointResponse={pointResponse}
                showHeader={previousPointType !== LearningDocumentPointType.troubleshooting}
                allIconlessPoints={allIconlessPoints}
            />
        )

    return (
        <CommonPoint
            index={index}
            point={point}
            pointResponse={pointResponse}
            allIconlessPoints={allIconlessPoints}
        />  
     )
}

export default Point