import { Chip, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import React from 'react'
import colorConfigs from 'src/config/colorConfigs';
import { LearningDocumentPointType, LearningDocumentSubtopic } from 'src/dtos/Training/LearningDocument.dto'
import { LearningDocumentResponseStatusString, LearningDocumentSubtopicResponse } from 'src/dtos/Training/LearningDocumentResponse.dto';
import Point from './Point';
import { ChipColor } from 'src/config/constants';
import { GetSubtopicStatus, GetSubtopicStatusColour } from 'src/pages/User Site/TrainingDocuments/calculateStatusHelper';

type Props = {
    index: number;
    subtopic: LearningDocumentSubtopic;
    subtopicResponse: LearningDocumentSubtopicResponse;
}
function Subtopic({index, subtopic, subtopicResponse}: Props) {
    const iconlessPoints = [LearningDocumentPointType.standard, LearningDocumentPointType.tallyCounter, LearningDocumentPointType.troubleshooting]
    const numIconlessPoints = subtopic.points.filter(point => iconlessPoints.findIndex(type => type === point.type) >= 0).length;

    const subtopicStatus = GetSubtopicStatus(subtopicResponse)
    const statusColour: ChipColor = GetSubtopicStatusColour(subtopicStatus)

    return (
        <TableContainer component={Paper}>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell sx={{padding:"0"}} colSpan={4}>
                            <Typography 
                                variant='h6' 
                                sx={{
                                    backgroundColor: colorConfigs.learningDocument.subtopicHeadingBg, 
                                    padding:"0.5rem",
                                    paddingLeft:"1rem",
                                    borderTopLeftRadius:"6px",
                                    borderTopRightRadius: "6px"
                                }}>
                                <Stack direction={"row"} sx={{display:"flex", justifyContent:"space-between", marginRight:"1rem"}}>
                                    {`${index +1}. ${subtopic.label}`}
                                    <Chip
                                        label={LearningDocumentResponseStatusString[subtopicStatus]}
                                        color={statusColour}
                                    />
                                </Stack>
                            </Typography>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            Question
                        </TableCell>
                        <TableCell sx={{width:"6rem"}}>
                            Checked
                        </TableCell>
                        <TableCell sx={{width:"10rem"}}>
                            Checked By
                        </TableCell>
                        <TableCell sx={{width:"8rem"}}>
                            Time Checked
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {subtopic.points.map((point, pointIndex) => (
                        <Point
                            key={pointIndex}
                            index={pointIndex}
                            point={point}
                            pointResponse={subtopicResponse.pointResponses[pointIndex]}
                            previousPointType={pointIndex-1 >= 0 ? subtopic.points[pointIndex-1].type : undefined}
                            allIconlessPoints={numIconlessPoints === subtopic.points.length}
                        />
                    ))}
                </TableBody>
            </Table>

        </TableContainer>
    )
}

export default Subtopic