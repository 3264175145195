import { BaseModel } from "../BaseModel.dto";
import { NameDto } from "../NameDto.dto";
import { LearningMaterialType } from "./TrainingGroup.dto";

export interface TrainingManualEvidenceDto extends BaseModel {
    id: string;
    label: string;
    description?: string;
    status: ManualEvidenceStatus;
    expiry?: Date;
    completionDate?: Date;
    items: ManualEvidenceItem[];
    teamMembers: NameDto[];
    approvedOrRejectedBy?: string;
    approvedOrRejectedOn?: Date;
}

export interface ManualEvidenceItem {
    id: string;
    label: string;
    materialType: LearningMaterialType;
    type: string;
}

export enum ManualEvidenceStatus {
    Draft, 
    AwaitingApproval,
    Rejected,
    MoreEvidenceRequired,
    Approved
}

export const ManualEvidenceStatusString : string[] = [
    "Draft",
    "Awaiting Approval",
    "Rejected", 
    "More Evidence Required",
    "Approved"
]


