import { Input, Paper, Slider, Stack, Typography } from '@mui/material';
import React from 'react'
import QuestionTitle from 'src/components/QuestionTitle/QuestionTitle';
import { InspectionChecklistResponseDto, InspectionChecklistSectionResponseDto } from 'src/dtos/Checklists/AttractionInspections/ChecklistResponse.dto';
import { FormikProps } from 'formik';
import ComponentResponse from './ComponentResponse';
import { NameDto } from 'src/dtos/NameDto.dto';
import { MessageItem } from 'src/components/errorHandlingSnackbar';
import { QuestionDto } from 'src/dtos/AwTypes';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import InspectionChecklistResponseService from 'src/Services/Checklists/Inspections/ChecklistResponseService';
import { useDebouncedCallback } from 'use-debounce';

type Props = {
    parentComponentLabel: string,
    formik: FormikProps<InspectionChecklistResponseDto>
    formikString: string;
    sectionResponse: InspectionChecklistSectionResponseDto;
    sectionQuestions: QuestionDto[];
    attraction: NameDto;
    demoMode?: boolean
    setMessageItem: React.Dispatch<React.SetStateAction<MessageItem>>;
}
function GroupedSectionComponents({parentComponentLabel, formik, formikString, sectionResponse, sectionQuestions, attraction, demoMode, setMessageItem}: Props) {    
    const componentResponses = sectionResponse.groupedSectionComponentResponses ?? [];
    const maxComponents = componentResponses.length
    const passedComponentQty = sectionResponse.groupedPassedComponentsQty ?? 0;
    const totalCheckedComponents = componentResponses.filter(gcr => gcr.passedAllQuestions === true).length;
    
    const queryClient = useQueryClient()

    const updatePassedComponentMutation = useMutation({
        mutationKey: ["UpdateGroupedPassedComponentsQty"],
        mutationFn: async ({sectionResponseId, newQty}: {sectionResponseId: number, newQty: number}) => {
            queryClient.cancelQueries({queryKey: ["UpdateGroupedPassedComponentsQty"], exact: true})
            return await InspectionChecklistResponseService.UpdateGroupedPassedComponentsQty(sectionResponseId, newQty)
        }
    })

    const setPassedComponentQty = (newValue: number) => {
        formik.setFieldValue(`${formikString}.groupedPassedComponentsQty`, newValue)
        //debounced mutation call
        if(!demoMode)
            debouncedQtyUpdate(newValue)
    }

    const debouncedQtyUpdate = useDebouncedCallback((newQty: number) => {

        updatePassedComponentMutation.mutate({sectionResponseId: sectionResponse.id, newQty: newQty})
    }, 1000)

    const handleSliderChange = (event: Event, newValue: number | number[]) => {
        setPassedComponentQty(newValue as number);
    };
    
    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPassedComponentQty(event.target.value === '' ? 0 : Number(event.target.value));
    };

    const handleBlur = () => {
        if (passedComponentQty < maxComponents) {
            setPassedComponentQty(0);
        } else if (passedComponentQty > maxComponents) {
            setPassedComponentQty(maxComponents);
        }
    };

    return (
        <>
            <Paper sx={{padding:"1rem"}} >
                <QuestionTitle title={`How many ${parentComponentLabel} passed all of the questions from this section?`} required/>
                <Stack direction={"row"} spacing={4}>
                    <Slider
                        value={typeof passedComponentQty === 'number' ? passedComponentQty : 0}
                        max={maxComponents}
                        marks
                        onChange={handleSliderChange}
                    />
                    <Input
                        value={passedComponentQty}
                        size="small"
                        onChange={handleInputChange}
                        onBlur={handleBlur}
                        inputProps={{
                            step: 1,
                            min: 0,
                            max: maxComponents,
                            type: 'number',
                        }}
                    />
                </Stack>
                <QuestionTitle title={`Which ${parentComponentLabel} passed all of the questions from this section?`} required/>
                <div style={{display:"flex", flexWrap:"wrap", gap:"1rem"}}>
                    {componentResponses.map((componentResponse, index) => (
                        <ComponentResponse
                            key={index}
                            formik={formik}
                            formikString={`${formikString}.groupedSectionComponentResponses[${index}]`}
                            componentResponse={componentResponse}
                            sectionQuestions={sectionQuestions}
                            attraction={attraction}
                            demoMode={demoMode}
                            setMessageItem={setMessageItem}
                        />
                    ))}
                </div>
                {totalCheckedComponents !== passedComponentQty && (
                    <Typography color={"red"}>{`The number of passed ${parentComponentLabel} checked and number inputted must match.`}</Typography>
                )}
            </Paper>
        </>
    )
}

export default GroupedSectionComponents