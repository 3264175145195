import { Paper, Stack } from '@mui/material'
import React, { useState } from 'react'
import DeleteLDResponse from './Components/DeleteLDResponse'
import ErrorHandlingSnackbar from 'src/components/errorHandlingSnackbar'
import DeleteLIResponse from './Components/DeleteLIResponse'
import CheckCompetencies from './Components/CheckCompetencies'

function TrainingAdminPage() {
    const [messageItem, setMessageItem] = useState({})
    return (
    <Paper>
        <Stack sx={{padding:"1rem"}}>
            <DeleteLDResponse setMessageItem={setMessageItem}/>
            <DeleteLIResponse setMessageItem={setMessageItem}/>
            <CheckCompetencies setMessageItem={setMessageItem} />
        </Stack>
        <ErrorHandlingSnackbar messageItem={messageItem}/>
    </Paper>
    )
}

export default TrainingAdminPage