import { Chip, Stack, Typography } from '@mui/material';
import React from 'react'
import { ChipColor } from 'src/config/constants';
import { LearningDocumentTopic, LearningDocumentType } from 'src/dtos/Training/LearningDocument.dto'
import { LearningDocumentResponseStatusString, LearningDocumentTopicResponse } from 'src/dtos/Training/LearningDocumentResponse.dto';
import { GetTopicStatus, GetTopicStatusColour } from 'src/pages/User Site/TrainingDocuments/calculateStatusHelper';
import Subtopic from './Subtopic';

type Props = {
    topic: LearningDocumentTopic;
    topicResponse: LearningDocumentTopicResponse;
    documentType: LearningDocumentType;
}
function Topic({topic, topicResponse, documentType}: Props) {
    const topicStatus = GetTopicStatus(topicResponse, documentType === LearningDocumentType.competencyDocument)
    const statusColour: ChipColor = GetTopicStatusColour(topicStatus);


    return (
        <div style={{padding:"1rem"}}>
            <Stack 
                direction="row"
                sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    marginRight: "1rem",
                }}
            >
                <Typography variant="h5">{topic.label}</Typography>
                <Chip
                    label={LearningDocumentResponseStatusString[topicStatus]}
                    color={statusColour}
                />
            </Stack>
            <Stack spacing={2} sx={{marginTop:"1rem"}}>
                {topic.subtopics.map((subtopic, index) => (
                    <Subtopic
                        key={index}
                        index={index}
                        subtopic={subtopic}
                        subtopicResponse={topicResponse.subtopicResponses[index]}
                    />
                ))}
            </Stack>
            <Typography variant='h6'>Notes</Typography>
            <Typography>{topicResponse.commentary}</Typography>


        </div>
    )
}

export default Topic