import { Chip, IconButton, Paper, Stack, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import ErrorHandlingSnackbar, { MessageItem } from "src/components/errorHandlingSnackbar";
import { ManualEvidenceStatus, ManualEvidenceStatusString, TrainingManualEvidenceDto } from "src/dtos/Training/TrainingManualEvidence.dto";
import TrainingManualEvidenceService from "src/Services/Training/ManualEvidenceService";
import { EmailToNameString, guidRegex } from "src/Utils/helperFunc";
import * as yup from "yup";
import ManualEvidenceForm from "./Components/ManualEvidenceForm";
import { LoadingButton } from "@mui/lab";
import TrainingContentTable from "./Components/TrainingContentTable";
import { ChipColor, dateOptions, dateTimeOptions } from "src/config/constants";
import Comments from "src/components/Comments/Comments";
import AttachmentsSimple from "src/components/AttachmentsSimple/AttachmentsSimple";
import AffectedTeamMembers from "./Components/AffectedTeamMembers";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import MoreEvidenceButton from "./Components/MoreEvidenceButton";

const savingValidationSchema = yup.object({
    label: yup.string().required("Title is a required field").max(75, "Title cannot be more than 75 characters"),
    description: yup.string().notRequired().max(1000, "Summary cannot be more than 1000 characters"),

})
const issuingValidationSchema = yup.object({
    label: yup.string().required("Title is a required field").max(75, "Title cannot be more than 75 characters"),
    description: yup.string().required("Summary is a required field").max(1000, "Summary cannot be more than 1000 characters"),
    completionDate: yup.date().required("Completion date is a required field").max(new Date(), "Must be before today")

})

function TrainingManualEvidence() {
    const [messageItem, setMessageItem] = useState<MessageItem>({})
    const [submitType, setSubmitType] = useState<"save" | "submitForApproval">("save")

    const {id} = useParams();
    const urlIdRegex = new RegExp(`/${id}$`)
    const navigate = useNavigate()
    const queryClient = useQueryClient()

    //#region queries
    const trainingMemoQuery = useQuery({
        queryKey: ["TrainingManualEvidence.Get", id],
        queryFn: async () => {
            if(id !== undefined && guidRegex.test(id))
                return await TrainingManualEvidenceService.Get(id)
            return TrainingManualEvidenceService.GetDefaultValues()
        },
        initialData: TrainingManualEvidenceService.GetDefaultValues()
    })

    //Handle errors
    useEffect(()=> {
        if(trainingMemoQuery.isError)
            setMessageItem({error: trainingMemoQuery.error})

    }, [trainingMemoQuery.isError, trainingMemoQuery.error])

    const createMutation = useMutation({
        mutationFn: (values: TrainingManualEvidenceDto) => {
            return TrainingManualEvidenceService.Create(values)
        },
        onSuccess: (data) => {
            setMessageItem({successMessage: "Manual Evidence created successfully!"})

            //update URL
            navigate({pathname: window.location.pathname.replace(urlIdRegex, `/${data.data.id}`)})
        },
        onError: (error) => {
            setMessageItem({error: error})
        }
    })

    const updateMutation = useMutation({
        mutationFn: ({id, values}: {id: string, values: TrainingManualEvidenceDto}) => {
            return TrainingManualEvidenceService.Update(id, values)
        },
        onSuccess: (data) => {
            setMessageItem({successMessage: "Manual Evidence updated successfully!"})
            //update query data to newly update values
            queryClient.setQueryData(["TrainingManualEvidence.Get", id], data.data)
        },
        onError: (error) => {
            setMessageItem({error: error})
        }
    })

    const submitForApprovalMutation = useMutation({
        mutationFn: (id: string) => {
            return TrainingManualEvidenceService.SubmitForApproval(id)
        },
        onSuccess: (data) => {
            setMessageItem({successMessage: "Submitted for approval successfully!"})
            //update query data to newly update values
            queryClient.setQueryData(["TrainingManualEvidence.Get", id], data.data)
        },
        onError: (error) => {
            setMessageItem({error: error})
        }
    })

    const rejectMutation = useMutation({
        mutationFn: (id: string) => {
            return TrainingManualEvidenceService.Reject(id)
        },
        onSuccess: (data) => {
            setMessageItem({successMessage: "Rejected successfully!"})
            //update query data to newly update values
            queryClient.setQueryData(["TrainingManualEvidence.Get", id], data.data)
        },
        onError: (error) => {
            setMessageItem({error: error})
        }
    })

    const approveMutation = useMutation({
        mutationFn: (id: string) => {
            return TrainingManualEvidenceService.Approve(id)
        },
        onSuccess: (data) => {
            setMessageItem({successMessage: "Approved successfully!"})
            //update query data to newly update values
            queryClient.setQueryData(["TrainingManualEvidence.Get", id], data.data)
        },
        onError: (error) => {
            setMessageItem({error: error})
        }
    })

    
    //#endregion
    
    const handleSave = (values: TrainingManualEvidenceDto) => {
        if(id === "new"){
            createMutation.mutate(values)
        } else if (id !== undefined && guidRegex.test(id)){
            updateMutation.mutate({id: id, values: values})
        }
    }

    const handleSubmit = async (values: TrainingManualEvidenceDto) => {
        if(submitType === "save"){
            handleSave(values)  
        } else if (submitType === "submitForApproval" && id){
            submitForApprovalMutation.mutate(id)
        }
    }

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: trainingMemoQuery.data,
        validationSchema: submitType === "submitForApproval" ? issuingValidationSchema : savingValidationSchema,
        onSubmit: (values) => {
            handleSubmit(values)
        }
    })

    const handleSubmitForApprovalClick = () => {
        setSubmitType("submitForApproval")
        setTimeout(() => {
            formik.handleSubmit()
        }, 2)
    }

    const handleRejectClick = () => {
        if(id)
            rejectMutation.mutate(id)
    }

    const handleApproveClick = () => {
        if(id)
            approveMutation.mutate(id)
    }
    
    const statusChipColour: ChipColor[] = [
        "default", //Draft, 
        "secondary", //AwaitingApproval,
        "error", //Rejected,
        "warning", //MoreEvidenceRequired,
        "success", //Approved
    ]

    const uneditable = [ManualEvidenceStatus.AwaitingApproval, 
                        ManualEvidenceStatus.Approved, 
                        ManualEvidenceStatus.Rejected]
                        .includes(formik.values.status)

    return  (
        <>
            <Paper sx={{padding:"1rem"}}>
                {uneditable ? 
                    <div>

                        <span style={{display:"flex", justifyContent: "space-between"}}>
                            <Stack direction={"row"} spacing={2}>
                                <Typography variant="h5">
                                    {formik.values.label}
                                </Typography>
                                <Chip
                                    label={ManualEvidenceStatusString[formik.values.status]}
                                    color={statusChipColour[formik.values.status]}
                                />
                            </Stack>
                            <Link to={window.location.pathname.replace(`/${id}`, '')}>
                                <IconButton>
                                    <ArrowBackIcon fontSize='large'/>
                                </IconButton>
                            </Link>
                        </span>
                        <Typography variant="h6">Summary</Typography>
                        <Typography>{formik.values.description}</Typography>
                        <Typography>
                            <strong>Training Expiry: </strong>
                            {formik.values.expiry ? new Date(formik.values.expiry).toLocaleDateString(undefined, dateOptions): "Never"}
                        </Typography>
                        <Typography>
                            <strong>Completion Date: </strong>
                            {formik.values.completionDate && new Date(formik.values.completionDate).toLocaleDateString(undefined, dateOptions)}
                        </Typography>
                    </div>
                    :
                    <ManualEvidenceForm
                        id={id}
                        formik={formik}
                        loading={createMutation.isPending || updateMutation.isPending}
                        statusChipColour={statusChipColour}
                        setSubmitType={setSubmitType}
                        setMessageItem={setMessageItem}
                    />
                }
                {id !== "new" && id && 
                    <>
                        <div>
                            <Typography variant="h6">Training Content</Typography>
                            <Typography>The training material that the submitted evidence verifies as adequately achieved</Typography>
                            <TrainingContentTable
                                id={id}
                                formik={formik}
                                editable={!uneditable}
                                setMessageItem={setMessageItem}
                            />
                        </div>
                        <Typography variant="h6">Team Members</Typography>
                        <AffectedTeamMembers
                            id={id}
                            editable={!uneditable}
                            setMessageItem={setMessageItem}
                        />
                        <Typography variant='h6'>Supporting Evidence</Typography>
                        <AttachmentsSimple
                            setMessageItem={setMessageItem}
                            callService={(page, pageSize) => TrainingManualEvidenceService.GetListAttachments(id, page, pageSize)}
                            addFileService={(file) => TrainingManualEvidenceService.AddFile(id, file)}
                            deleteFileService={(fileId) => TrainingManualEvidenceService.DeleteFile(id, fileId)}
                            queryKey={`manualEvidence_${id}`}
                            editable={!uneditable}
                        />
                        <Typography variant='h6'>Comments</Typography>
                        <Comments
                            queryKey={`manualEvidence_${id}`}
                            addCommentService={(comment) => TrainingManualEvidenceService.AddComment(id, comment)}
                            editCommentService={TrainingManualEvidenceService.UpdateComment}
                            getCommentsService={(listParams) => TrainingManualEvidenceService.GetListComments(id, listParams)}
                            setMessageItem={setMessageItem}
                        />

                        {!uneditable && (
                            <span style={{display:"flex", justifyContent:"flex-end"}}>
                                <Stack direction={"row"} spacing={2}>
                                    <LoadingButton 
                                        variant='contained'   
                                        onClick={handleSubmitForApprovalClick}
                                        loading={submitForApprovalMutation.isPending}
                                    >
                                        Submit for Approval
                                    </LoadingButton>
                                </Stack>
                            </span>
                        )}

                        {formik.values.status === ManualEvidenceStatus.AwaitingApproval && (
                            <span style={{display:"flex", flexDirection:"row", gap:"2rem", justifyContent:"center"}}>
                                <LoadingButton
                                    variant="contained"
                                    color="success"
                                    loading={approveMutation.isPending}
                                    onClick={handleApproveClick}
                                >
                                    Approve
                                </LoadingButton>
                                <MoreEvidenceButton
                                    id={id}
                                    setMessageItem={setMessageItem}
                                />
                                <LoadingButton
                                    variant="contained"
                                    color="error"
                                    loading={rejectMutation.isPending}
                                    onClick={handleRejectClick}
                                >
                                    Reject
                                </LoadingButton>
                            </span>
                        )}

                        
                        <Table size='small' sx={{width: "fit-content"}}>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Action</TableCell>
                                    <TableCell>Person</TableCell>
                                    <TableCell>Time</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell>
                                        Created
                                    </TableCell>
                                    <TableCell>
                                        {EmailToNameString(formik.values.createdBy)}
                                    </TableCell>
                                    <TableCell>
                                        {formik.values.createdOn && new Date(formik.values.createdOn).toLocaleDateString(undefined, dateTimeOptions)}
                                    </TableCell>
                                </TableRow>
                                {formik.values.modifiedOn && (
                                    <TableRow>
                                        <TableCell>
                                            Last Modifed 
                                        </TableCell>
                                        <TableCell>
                                            {EmailToNameString(formik.values.modifiedBy)}
                                        </TableCell>
                                        <TableCell>
                                            {new Date(formik.values.modifiedOn).toLocaleDateString(undefined, dateTimeOptions)}
                                        </TableCell>
                                    </TableRow>
                                )}
                                {formik.values.approvedOrRejectedOn && (
                                    <TableRow>
                                        <TableCell>
                                            {formik.values.status === ManualEvidenceStatus.Rejected ? "Rejected" : "Approved"}
                                        </TableCell>
                                        <TableCell>
                                            {EmailToNameString(formik.values.approvedOrRejectedBy)}
                                        </TableCell>
                                        <TableCell>
                                            {new Date(formik.values.approvedOrRejectedOn).toLocaleDateString(undefined, dateTimeOptions)}
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </>
                }

                
            </Paper>
            <ErrorHandlingSnackbar messageItem={messageItem}/>
        </>
    )
    
}

export default TrainingManualEvidence;
