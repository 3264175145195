import { LoadingButton } from '@mui/lab'
import { Chip, IconButton, Stack, TextField, Typography } from '@mui/material'
import { FormikProps } from 'formik'
import React from 'react'
import QuestionTitle from 'src/components/QuestionTitle/QuestionTitle'
import { ChipColor } from 'src/config/constants'
import { ManualEvidenceStatusString, TrainingManualEvidenceDto } from 'src/dtos/Training/TrainingManualEvidence.dto'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Link } from 'react-router-dom'
import { MessageItem } from 'src/components/errorHandlingSnackbar'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from 'dayjs'

type Props = {
    id?: string;
    formik: FormikProps<TrainingManualEvidenceDto>
    loading: boolean;
    statusChipColour: ChipColor[]
    setSubmitType: React.Dispatch<React.SetStateAction<"save" | "submitForApproval">>
    setMessageItem: React.Dispatch<React.SetStateAction<MessageItem>>
    
}
function ManualEvidenceForm({id, formik, loading, statusChipColour, setSubmitType, setMessageItem}:Props) {

    const handleSaveClick = () => {
        setSubmitType("save")
        setTimeout(() => {
            formik.handleSubmit()
        }, 1);
    }

    
    return (
        <Stack spacing={1}>
            <span style={{display:"flex", justifyContent: "space-between"}}>
                    <Stack direction={"row"} spacing={2}>
                        <Typography variant="h5">
                            {`${id === "new" ? "New" : "Edit"} Manual Evidence`}
                        </Typography>
                        <Chip
                            label={ManualEvidenceStatusString[formik.values.status]}
                            color={statusChipColour[formik.values.status]}
                        />
                    </Stack>
                    <Link to={window.location.pathname.replace(`/${id}`, '')}>
                        <IconButton>
                            <ArrowBackIcon fontSize='large'/>
                        </IconButton>
                    </Link>
                </span>
            <div>
                <QuestionTitle title="Request Title" required/>
                <TextField
                    id="label"
                    name="label"
                    value={formik.values.label}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    size="small"
                    sx={{width:"100%"}}
                    error={formik.touched.label && Boolean(formik.errors.label)}
                    helperText={formik.touched.label && formik.errors.label}
                />
            </div>
            <div>
                <QuestionTitle title="Summary" required/>
                <TextField
                    id="description"
                    name="description"
                    value={formik.values.description}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    size="small"
                    multiline
                    rows={2}
                    sx={{width:"100%"}}
                    error={formik.touched.description && Boolean(formik.errors.description)}
                    helperText={formik.touched.description && formik.errors.description}
                />
            </div>
            <div>
                <QuestionTitle title= "Training Expiry"/>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker 
                        name="expiry"
                        format='DD/MM/YYYY'
                        value={formik.values.expiry ? dayjs(formik.values.expiry) : null}
                        onChange={(value) => {
                            if (value) {
                                formik.setFieldValue("expiry", value.format("YYYY-MM-DD"))
                            } else {
                                formik.setFieldValue("expiry", null)
                            }
                        }}
                        slotProps={{
                            textField: {
                                size: "small",
                                variant: "standard",
                                error: formik.touched.expiry && Boolean(formik.errors.expiry),
                                helperText: formik.touched.expiry && formik.errors.expiry
                            }
                        }}
                        sx={{width:"8.75rem"}}
                    />
                </LocalizationProvider>
            </div>
            <div>
                <QuestionTitle title= "Completion Date" required/>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker 
                        name="completionDate"
                        format='DD/MM/YYYY'
                        value={formik.values.completionDate ? dayjs(formik.values.completionDate) : null}
                        onChange={(value) => {
                            if (value) {
                                formik.setFieldValue("completionDate", value.format("YYYY-MM-DD"))
                            } else {
                                formik.setFieldValue("completionDate", null)
                            }
                        }}
                        maxDate={dayjs(new Date())}
                        slotProps={{
                            textField: {
                                size: "small",
                                variant: "standard",
                                error: formik.touched.completionDate && Boolean(formik.errors.completionDate),
                                helperText: formik.touched.completionDate && formik.errors.completionDate
                            }
                        }}
                        sx={{width:"8.75rem"}}
                    />
                </LocalizationProvider>
            </div>
            <span style={{display:"flex", justifyContent:"flex-end"}}>
                <LoadingButton 
                    variant='contained' 
                    loading={loading}
                    onClick={handleSaveClick}    
                >
                    Save
                </LoadingButton>
            </span>
        </Stack>
    )
}

export default ManualEvidenceForm