import { Stack, Paper, InputBase, Autocomplete, TextField, LinearProgress, TableContainer, Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import React, { useEffect, useState } from 'react'
import { MessageItem } from 'src/components/errorHandlingSnackbar'
import TeamMemberService from 'src/Services/HumanResources/TeamMemberService'
import SearchIcon from '@mui/icons-material/Search';
import { dateOptions } from 'src/config/constants'

type Props = {
    id: string
    setMessageItem: React.Dispatch<React.SetStateAction<MessageItem>>
}
function AchievedCompetencies({id, setMessageItem}: Props) {

    const date = new Date()
    const startYear = date.getMonth() >= 7 ? date.getFullYear() : date.getFullYear() -1;
    const currentFY = `${startYear}-${startYear+1}`

    const [financialYear, setFinancialYear] = useState(currentFY)
    const [searchTerm, setSearchTerm] = useState("")

    const teamMemberAchievedCompetenciesQuery = useQuery({
        queryKey: ["TeamMember.GetAchievedCompetencies", id, financialYear],
        queryFn: async () => {
            return await TeamMemberService.GetAchievedCompetencies(id, financialYear)
        },
        initialData: {
            achievedCompetencies: [],
            financialYears: [currentFY]
        }
    })

    useEffect(() => {
        if(teamMemberAchievedCompetenciesQuery.isError)
            setMessageItem({error: teamMemberAchievedCompetenciesQuery.error})
    }, [setMessageItem, teamMemberAchievedCompetenciesQuery.error, teamMemberAchievedCompetenciesQuery.isError])

    return (
        <Stack spacing={1} sx={{paddingTop:"0.5rem"}}>
            <Stack direction={"row"} spacing={2}>
                <Paper
                    component="form"
                    sx={{p: '2px 1px', marginLeft:"1rem", display: 'flex', alignItems: 'center', width:"13rem" }}
                >
                    <SearchIcon color="disabled"/>
                    <InputBase
                        sx={{ml: 1, flex: 1}}
                        placeholder='Search'
                        value={searchTerm}
                        onChange={e => setSearchTerm(e.target.value)}
                    />

                </Paper>
                <Autocomplete
                    id="FinancialYear"
                    options={teamMemberAchievedCompetenciesQuery.data.financialYears}
                    value={financialYear}
                    onChange={(_e, value) => {
                        setFinancialYear(value)
                    }}
                    disableClearable
                    size={"small"}
                    sx={{width:"10rem"}}
                    renderInput={(params) => 
                        <TextField 
                            {...params}
                            label="Financial Year"
                        />
                    }
                />
            </Stack>
            {teamMemberAchievedCompetenciesQuery.isFetching ? (
                <LinearProgress/>
            ) : (
                <div style={{height:"4px"}}/> //spacer
            )}
            <TableContainer component={Paper}>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                Training Area
                            </TableCell>
                            <TableCell>
                                Attained Date
                            </TableCell>
                            <TableCell>
                                Expiry
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {teamMemberAchievedCompetenciesQuery.data.achievedCompetencies.filter(ac => ac.learningArea.label.toLowerCase().includes(searchTerm.toLowerCase())).map((acheivedCompetency, index) => (
                            <TableRow key={`${acheivedCompetency.learningArea.id}_${acheivedCompetency.attainedDate}`}>
                                <TableCell>
                                    {acheivedCompetency.learningArea.label}
                                </TableCell>
                                <TableCell>
                                    {new Date(acheivedCompetency.attainedDate).toLocaleDateString(undefined, dateOptions)}
                                </TableCell>
                                <TableCell>
                                    {acheivedCompetency.expiry ? new Date(acheivedCompetency.expiry).toLocaleDateString(undefined, dateOptions) : ""}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Stack>
    )
}

export default AchievedCompetencies