import axios, { AxiosResponse } from "axios";
import { AttractionDto, AttractionType, ChildEntity, ListResponseDto } from "../../dtos/AwTypes";
import { ListParameters, SortDirection } from "../ListParameters";
import { GetAuthorisation } from "src/Utils/authUtils";
import { NameDto } from "src/dtos/NameDto.dto";

const apiBaseUrl = process.env.REACT_APP_API_BASE_URL + "/attraction";
const debug = process.env.REACT_APP_DEBUG;

const emptyData: ListResponseDto = {
    data: [],
    totalRecords: 0
}

export default class AttractionService {
    static GetDefaultValues(){
        const defaultAttractionValues: AttractionDto = {
            id: "00000000-0000-0000-0000-000000000000",
            label: "",
            description: "",
            residingArea: null,
            components: [],
            enabled: true,
            type: AttractionType.Mechanical,
            deviceCapacityLabel: "",
            deviceMinimumCapacity: null,
            deviceMaximumCapacity: null
        }
        return defaultAttractionValues
    }

    static async GetNames(): Promise<NameDto[]> {
        try {
            const authorization = await GetAuthorisation();
            const response: AxiosResponse<NameDto[]> = await axios.get<NameDto[]>(`${apiBaseUrl}/GetNames`, {
                headers: {
                    Authorization: authorization
                }
            });
            return response.data;
        } catch (error: any) {
            debug && console.log(error)
            throw error;
        }
    }

    static async GetList(listParam?: ListParameters, searchTerm?: string, type?: AttractionType, includeDisabled?: boolean): Promise<ListResponseDto> {
        try {
            const authorization = await GetAuthorisation();
            const response: AxiosResponse<ListResponseDto> = await axios.get<ListResponseDto>(`${apiBaseUrl}/List`, {
                params: {
                    page: listParam?.page,
                    pageSize: listParam?.pageSize,
                    sortBy : listParam?.sortBy,
                    sortDirection: listParam?.sortDirection === SortDirection.Ascending ? 0 : listParam?.sortDirection === SortDirection.Descending ? 1 : undefined,
                    searchTerm: searchTerm,
                    type: type,
                    includeDisabled: includeDisabled                
                },
                headers: {
                    Authorization: authorization
                }
            });
            return response.data;
        } catch (error: any) {
            if(error.response !== undefined){
                if(error.response.status != null && error.response.status === 404){
                    return emptyData
                }
            }
            debug && console.log(error)
            throw error;
        }
    }

    static async Create(attractionsData: AttractionDto): Promise<AxiosResponse> {
        try {
            const authorization = await GetAuthorisation();
            const response: AxiosResponse = await axios.post<AxiosResponse>(
                `${apiBaseUrl}/Create`,
                attractionsData, {
                    headers: {
                        Authorization: authorization
                    }
                }
            );
            return response
        } catch (error: any) {
            debug && console.log(error)
            throw error;
        }
    }

    static async Update(id: string, attractionsData: AttractionDto): Promise<AxiosResponse> {
        try {
            const authorization = await GetAuthorisation();
            const response: AxiosResponse = await axios.put<AxiosResponse>(
                `${apiBaseUrl}/Update`,
                attractionsData,
                {
                    params: {id: id},
                    headers: {
                        Authorization: authorization
                    }
                }
            );
            return response
        } catch (error: any) {
            debug && console.log(error)
            throw error;
        }
    }

    static async Get(id: string): Promise<AttractionDto> {
        try {
            const authorization = await GetAuthorisation();
            const response: AxiosResponse<AttractionDto> = await axios.get<AttractionDto>(`${apiBaseUrl}/Get/${id}`, {
                headers: {
                    Authorization: authorization
                }
            });
            return response.data;
        } catch (error: any) {
            debug && console.log(error)
            throw error;
        }
    }

    static async GetComponents(id: string): Promise<ChildEntity> {
        try {
            const authorization = await GetAuthorisation();
            const response: AxiosResponse<ChildEntity> = await axios.get<ChildEntity>(`${apiBaseUrl}/Components/${id}`, {
                headers: {
                    Authorization: authorization
                }
            });
            const data = response.data;
            return data
        } catch (error: any) {
            debug && console.log(error)
            throw error;
        }
    }

    static async Enable(id: string): Promise<AxiosResponse> {
        try {
            const authorization = await GetAuthorisation();
            const response: AxiosResponse = await axios.put(`${apiBaseUrl}/Enable/${id}`, null, {
                headers: {
                    Authorization: authorization
                }
            });
            return response
        } catch (error: any) {
            debug && console.log(error)
            throw error;
        }
    }

    static async Disable(id: string): Promise<AxiosResponse> {
        try {
            const authorization = await GetAuthorisation();
            const response: AxiosResponse = await axios.put(`${apiBaseUrl}/Disable/${id}`, null, {
                headers: {
                    Authorization: authorization
                }
            });
            return response
        } catch (error: any) {
            debug && console.log(error)
            throw error;
        }
    }
}