import React, { memo, useRef, useState } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { ListItem, TextField, Stack, IconButton, Grid, Tooltip, FormControl, Select, MenuItem, SelectChangeEvent, InputLabel } from '@mui/material';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import DeleteIcon from '@mui/icons-material/Delete';
import ListIcon from '@mui/icons-material/List';
import ChecklistIcon from '@mui/icons-material/Checklist';
import { LearningDocumentPoint, LearningDocumentPointType, LearningDocumentVersion, PointTypeString } from 'src/dtos/Training/LearningDocument.dto';
import ULeditor from '../ULEditor/ULeditor';
import { FormikProps } from 'formik';
import LearningDocumentVersionService from 'src/Services/Training/LearningDocuments/LearningDocumentVersionService';
import SubpointsCreator from '../SubpointsCreator/SubpointsCreator';
import PlaylistRemoveIcon from '@mui/icons-material/PlaylistRemove';
import { FocusElement } from 'src/Utils/helperFunc';
import { LoadingButton } from '@mui/lab';
import { Delete } from '@mui/icons-material';
import { useMutation } from '@tanstack/react-query';
import ErrorHandlingSnackbar, { MessageItem } from 'src/components/errorHandlingSnackbar';
import QuestionTitle from 'src/components/QuestionTitle/QuestionTitle';
  

export type Props = {
  formik: FormikProps<LearningDocumentVersion>;
  formikString: string;
  formikPointValue: LearningDocumentPoint;
  index: number;
  DeletePoint: () => void;
  DeleteSubPoint: (subpointIndex: number) => void;
  HandleAddPointBelow: () => void;
  preparationChecklist?: boolean;
};

function areEqual(prevProps: Props, nextProps: Props) {
  return (
    prevProps.formikPointValue === nextProps.formikPointValue &&
    prevProps.index === nextProps.index
  );
}

function PointsListItemUnmemoized({ formik, formikString, formikPointValue, index: pointIndex, DeletePoint, DeleteSubPoint, HandleAddPointBelow, preparationChecklist }: Props) {
  const hiddenFileInput = useRef<HTMLInputElement>(null);
  const [messageItem, setMessageItem] = useState<MessageItem>({})
  const handleTypeChange = (e: SelectChangeEvent<LearningDocumentPointType>) => {
    const selectedType : LearningDocumentPointType = typeof e.target.value === 'string' ? LearningDocumentPointType.standard : e.target.value
    formik.setFieldValue(`${formikString}.type`, selectedType)

    if(selectedType === LearningDocumentPointType.troubleshooting){
      if(!formikPointValue.unorderedList){
        formik.setFieldValue(`${formikString}.unorderedList`, " ")
      }
    } else if (formikPointValue.unorderedList === " "){
      formik.setFieldValue(`${formikString}.unorderedList`, null)
    }
  }

  const handleAddExamplesClick = () => {
    if(formikPointValue.unorderedList) {
      formik.setFieldValue(`${formikString}.unorderedList`, null)
    } else {
      formik.setFieldValue(`${formikString}.unorderedList`, " ")
    }
  }
  

  const handleAddSubPointsClick = () => {
    const newSubpoint = {...LearningDocumentVersionService.GetDefaultSubpointValue(), sortOrder: formikPointValue.subpoints.length}
    const newSubpoints = [...formikPointValue.subpoints, newSubpoint]
    formik.setFieldValue(`${formikString}.subpoints`, newSubpoints)
  }

  const addFileMutation = useMutation({
    mutationFn: async (values: File) => {
        return await LearningDocumentVersionService.AddPointFile(formik.values.id, formikPointValue.id, values)
    },
    onSuccess: (data) => {
        formik.setFieldValue(`${formikString}.file`, data.data)
    },
    onError: (error) => {
      setMessageItem({error: error})
    }
})

  const deleteFileMutation = useMutation({
      mutationFn: async () => {
          return await LearningDocumentVersionService.DeletePointFile(formik.values.id, formikPointValue.id)
      },
      onSuccess: () => {
          formik.setFieldValue(`${formikString}.file`, null)
      },
      onError: (error) => {
        setMessageItem({error: error})
      }
  })

  const handleChooseFileClick = () => {
    if(formikPointValue.id === 0){
      setMessageItem({errorMessage: "Save the document before adding a video/image to a new point"})
      return
    }
    hiddenFileInput.current && hiddenFileInput.current.click();
  }

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files
    if (files) {
      const newFiles = Array.from(files);
      const newFile = newFiles[0]
      addFileMutation.mutate(newFile);
    }

    event.target.files = null;
  }
  
  const handleDeletePointClick = () => {
    if(formikPointValue.file){
      handleDeleteFile()
    }
    DeletePoint()
  }
  const handleDeleteFile = () => {
    deleteFileMutation.mutate()
  }

  return (
    <>
      <Draggable 
          draggableId={`point_${formikPointValue.sortOrder}`} 
          index={pointIndex}          
      >
        {(provided, snapshot) => (
            <ListItem
              ref={provided.innerRef}
              {...provided.draggableProps}
              sx={snapshot.isDragging ? { background: 'rgb(235,235,235)' } : {}}
            >
              <Grid container>
                <Grid container columnSpacing={1}>
                  <Stack direction={"row"} sx={{width: "100%"}}>
                    <div 
                      {...provided.dragHandleProps} 
                      style={{ display: "flex", alignItems: "center", marginRight: '8px' }}
                      >
                      <DragIndicatorIcon/>
                    </div>
                    <Grid item xs={preparationChecklist ? 0 : 3}>
                      {!preparationChecklist && (
                        <FormControl sx={{width:"100%", paddingRight:"0.5rem"}}>
                          <InputLabel>Type</InputLabel>
                          <Select 
                            id={`${formikString}.type`}
                            name={`${formikString}.type`}
                            value={formikPointValue.type}
                            onChange={handleTypeChange}
                            size='small' 
                            sx={{width: "100%"}}
                            label="Type"
                          >
                            <MenuItem value={LearningDocumentPointType.standard}>{PointTypeString[LearningDocumentPointType.standard]}</MenuItem>
                            <MenuItem value={LearningDocumentPointType.question}>{PointTypeString[LearningDocumentPointType.question]}</MenuItem>
                            <MenuItem value={LearningDocumentPointType.trainerAction}>{PointTypeString[LearningDocumentPointType.trainerAction]}</MenuItem>
                            <MenuItem value={LearningDocumentPointType.traineeDemo}>{PointTypeString[LearningDocumentPointType.traineeDemo]}</MenuItem>
                            <MenuItem value={LearningDocumentPointType.previousIncident}>{PointTypeString[LearningDocumentPointType.previousIncident]}</MenuItem>
                            <MenuItem value={LearningDocumentPointType.tallyCounter}>{PointTypeString[LearningDocumentPointType.tallyCounter]}</MenuItem>
                            <MenuItem value={LearningDocumentPointType.video}>{PointTypeString[LearningDocumentPointType.video]}</MenuItem>
                            <MenuItem value={LearningDocumentPointType.image}>{PointTypeString[LearningDocumentPointType.image]}</MenuItem>
                            <MenuItem value={LearningDocumentPointType.troubleshooting}>{PointTypeString[LearningDocumentPointType.troubleshooting]}</MenuItem>

                          </Select>
                        </FormControl>
                      )}
                    </Grid>
                    <Grid item xs={preparationChecklist ? 12 : 9}>
                      <Stack direction={"row"} spacing={0.5}>
                        <TextField
                          id={`${formikString}.label`} 
                          name={`${formikString}.label`}
                          label={"Label"}
                          value={formikPointValue.label}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          onKeyDown={e => {
                            if(e.key === "Enter" && e.shiftKey && e.ctrlKey) {
                              e.preventDefault();
                              if(!formikPointValue.unorderedList){
                                handleAddExamplesClick();
                              }
                            }
                            else if(e.key === "Enter" && e.shiftKey){
                              e.preventDefault();
                              handleAddSubPointsClick();
                              setTimeout(() => {
                                FocusElement(1 + formikPointValue.subpoints.length)

                              }, 1)
                            }
                            else if(e.key === "Enter"){
                              e.preventDefault();
                              if(formikPointValue.label.length > 0){
                                HandleAddPointBelow()
                                let moveAmount = 1
                                moveAmount += formikPointValue.subpoints.length;
                                //Move focus to new subpoints label
                                setTimeout(() => {
                                  FocusElement(moveAmount);
                                }, 1); 
                              }
                            }
                          }}
                          size="small"
                          sx={{width: "100%"}}
                          multiline
                          maxRows={3}
                        />
                        {formikPointValue.type !== LearningDocumentPointType.troubleshooting && (
                          <>
                            <Tooltip title={`${formikPointValue.unorderedList ? "Delete Examples" : "Add Examples"}`}>
                              <div style={{display: "flex", alignItems: "center"}}>
                                <IconButton  tabIndex={-1} size='small' onClick={handleAddExamplesClick}>
                                  {formikPointValue.unorderedList ? (
                                      <PlaylistRemoveIcon/>
                                    ) : (
                                      <ListIcon fontSize='small'/>
                                  )}
                                </IconButton>
                              </div>
                            </Tooltip>
                            <Tooltip title="Add Subpoint">
                              <div style={{display: "flex", alignItems: "center"}}>
                                <IconButton tabIndex={-1} size='small' onClick={handleAddSubPointsClick}>
                                  <ChecklistIcon fontSize='small'/>
                                </IconButton>
                              </div>
                            </Tooltip>
                          </>
                        )

                        }
                        <Tooltip title="Delete Point and Subpoints">
                          <div style={{display: "flex", alignItems: "center"}}>
                          <IconButton tabIndex={-1} onClick={handleDeletePointClick}>
                            <DeleteIcon/>
                          </IconButton>
                          </div>
                        </Tooltip>
                      </Stack>
                      {[LearningDocumentPointType.image, LearningDocumentPointType.video].includes(formikPointValue.type) && 
                        (
                          <>
                            {
                              formikPointValue.file ? 
                              (
                                <>
                                  <Stack   
                                    direction={"row"}
                                    spacing={2}
                                  >
                                      <a href={formikPointValue.file.fileUrl} target='_blank' rel="noreferrer">{formikPointValue.file.fileName}</a>
                                      <LoadingButton 
                                        onClick={handleDeleteFile}
                                        loading={deleteFileMutation.isPending}
                                      >
                                        <Delete color='error'/>
                                      </LoadingButton>
                                  </Stack>
                                </>
                                
                              )
                              : 
                              (
                                <>
                                  <Stack direction={"row"}>
                                    <LoadingButton 
                                        sx={{marginBottom:"0.5rem", marginTop:"0.5rem"}}
                                        variant='contained'
                                        loading={addFileMutation.isPending}
                                        onClick={handleChooseFileClick}
                                    >
                                        Choose File
                                    </LoadingButton>
                                    <QuestionTitle
                                      title=""
                                      infoText={`Accepted File Types: ${formikPointValue.type === LearningDocumentPointType.image ? ".png, .jpeg, .webp, .avif" : ".mp4, .webm, .avi, .mkv, .ogg, .mov"}`}
                                    />
                                  </Stack>
                                  <input
                                      type="file"
                                      accept={
                                        formikPointValue.type === LearningDocumentPointType.image ? 
                                          "image/png, image/jpeg, image/webp, image/avif"
                                          : "video/mp4, video/webm, video/x-msvideo, video/x-matroska, video/ogg, video/quicktime"
                                      }
                                      ref={hiddenFileInput}
                                      style={{display:"none"}}
                                      onChange={handleFileChange}   
                                  />
                              </>
                              )
                            }
                          </>
                        )  
                      }
                      {formikPointValue.type === LearningDocumentPointType.tallyCounter && (
                        <TextField
                          id={`${formikString}.tallyNumber`}
                          name={`${formikString}.tallyNumber`}
                          value={formikPointValue.tallyNumber}
                          onChange={(e: any) => {
                            if(e.target.value === ""){
                              formik.setFieldValue(`${formikString}.tallyNumber`, null)
                            } else {
                              formik.handleChange(e)
                            }
                          }}
                          onBlur={formik.handleBlur}
                          type='number'
                          size="small"
                          sx={{marginTop:"0.5rem", width:"9rem"}}
                          label="Minimum Tally"      
                        />
                      )}
                    </Grid>
                  </Stack>
                </Grid>
                <Grid item xs={9.25} sx={{marginLeft: "3rem"}}>
                  {formikPointValue.unorderedList && (
                    <ULeditor
                      label={formikPointValue.type === LearningDocumentPointType.troubleshooting ? "Solutions" : "Examples"} 
                      content={formikPointValue.unorderedList}
                      onBlurSaveContent={(content) => formik.setFieldValue(`${formikString}.unorderedList`, content)}             
                    />
                  )}
                  
                </Grid>
                <Grid item xs={12}>
                  {formikPointValue.subpoints && (
                    <SubpointsCreator
                      formik={formik}
                      formikString={`${formikString}.subpoints`}
                      formikSubpointsValue={formikPointValue.subpoints}
                      pointSortOrder={formikPointValue.sortOrder}
                      DeleteSubPoint={DeleteSubPoint}
                      HandleAddPointBelow={HandleAddPointBelow}
                    />
                  )}
                </Grid>
                
              </Grid>
              
            </ListItem>
            
        )}
      </Draggable>
      <ErrorHandlingSnackbar
        messageItem={messageItem}
      />
    </>
  );
};

const PointsListItem = memo<Props>(PointsListItemUnmemoized, areEqual)

export default PointsListItem;
