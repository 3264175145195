import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from "@mui/material";
import React, { useEffect, useState } from "react";

type Props = {
    open: boolean,
    remainingTime: number
    handleLogoutClick: () => Promise<void>
    handleExtendSession: () => Promise<void>
}

export default function SessionTimeoutExtend({open, remainingTime, handleLogoutClick, handleExtendSession}:Props) {

    const handleClose = (event: {}, reason: "backdropClick" | "escapeKeyDown") => {
        if (reason && (reason === "backdropClick" || reason === "escapeKeyDown")) 
            return;
    }

    const [remaningSeconds, setRemainingSeconds] = useState(remainingTime);

    useEffect(() => {
        setRemainingSeconds(remainingTime);
    }, [remainingTime]); // Update only when remainingTime changes

    const minutes = Math.floor(remaningSeconds/60)
    const seconds = remaningSeconds % 60
    let displayedTime = minutes > 0 ? `${minutes}m `: ""
    displayedTime += `${seconds}s`
    
    return (
        <Dialog
            
            open={open}
            onClose={handleClose}
        >
            <DialogTitle id="alert-dialog-title">
                Session timeout
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {`You have been inactive and will be logged out in ${displayedTime}. Do you want to continue your session?`}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleLogoutClick}>{`Logout`}</Button>
                <Button variant="contained" onClick={handleExtendSession}>
                    {`Extend session`}
                </Button>
            </DialogActions>
        </Dialog>
    );
}
