import { TableCell } from '@mui/material'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import ClickableTableRow from 'src/components/Datatable/ClickableTableRow'
import Datatable from 'src/components/Datatable/Datatable'
import { TableHeader } from 'src/components/Datatable/TableHeader'
import ErrorHandlingSnackbar, { MessageItem } from 'src/components/errorHandlingSnackbar'
import { dateOptions } from 'src/config/constants'
import { ListResponseDto } from 'src/dtos/ListResponse.dto'
import { ManualEvidenceStatusString, TrainingManualEvidenceDto } from 'src/dtos/Training/TrainingManualEvidence.dto'
import { ListParameters, SortDirection } from 'src/Services/ListParameters'
import TrainingManualEvidenceService from 'src/Services/Training/ManualEvidenceService'
import { EmailToNameString } from 'src/Utils/helperFunc'

function TrainingManualEvidences() {
    const tableHeaders: TableHeader[] = [
        {label: '', field: "statusIndicator", width: "10px", sortable: false},
        {label: "Name", field: "label", width: "25rem", sortable: true},
        {label: "Requested By", field: "createdBy", width: "15rem", sortable: true},
        {label: "Created On", field: "createdOn", width: "10rem", sortable: true},
        {label: "Status", field: "status", width: "20rem", sortable: true},
    ]

    const [messageItem, setMessageItem] = useState<MessageItem>({})
    const navigate = useNavigate();

    const initialListParams: ListParameters = {
        page: 1,
        pageSize: 30,
        sortBy: "createdOn",
        sortDirection: SortDirection.Descending
    }

    const fetchManualEvidences = async (listParams: ListParameters, searchTerm?: string): Promise<ListResponseDto> => {
        return await TrainingManualEvidenceService.GetList(listParams, searchTerm)
    }

    const statusColour: string[] = [
        "grey", //Draft, 
        "#9c27b0", //AwaitingApproval,
        "#d32f2f", //Rejected,
        "#ed6c02", //MoreEvidenceRequired,
        "#2e7d32", //Approved
    ]

    const renderTableRow = ({data, index}: {data: TrainingManualEvidenceDto, index: number}) => {
        return (
            <>
                <ClickableTableRow
                    hover
                    href={`${window.location.pathname}${window.location.pathname[window.location.pathname.length-1] === "/" ? "" : "/"}${data.id}`}
                    key={`TrainingManualEvidenceRow_${index}`}
                    sx={{cursor: "pointer", textDecoration: "none"}}
                >
                    <TableCell sx={{minWidth: `${tableHeaders[0].width} !important`, maxWidth: `${tableHeaders[0].width} !important`, padding: '0', bgcolor: statusColour[data.status]}}></TableCell>
                    <TableCell sx={{minWidth: `${tableHeaders[1].width} !important`, maxWidth: `${tableHeaders[1].width} !important`}}>{data.label}</TableCell>
                    <TableCell sx={{minWidth: `${tableHeaders[2].width} !important`, maxWidth: `${tableHeaders[2].width} !important`}}>{EmailToNameString(data.createdBy)}</TableCell>
                    <TableCell sx={{minWidth: `${tableHeaders[3].width} !important`, maxWidth: `${tableHeaders[3].width} !important`}}>{data.createdOn && new Date(data.createdOn).toLocaleDateString(undefined, dateOptions)}</TableCell>
                    <TableCell sx={{minWidth: `${tableHeaders[4].width} !important`, maxWidth: `${tableHeaders[4].width} !important`}}>{ManualEvidenceStatusString[data.status]}</TableCell>
                </ClickableTableRow>
            </>
        )
    }

    const handleFormOpen = () => {
        navigate(`${window.location.pathname}${window.location.pathname[window.location.pathname.length-1] === "/" ? "" : "/"}new`)
    }

    return (
        <>
            <Datatable
                tableHeaders={tableHeaders}
                initialListParams={initialListParams}
                tableTitle='Manual Evidence'
                buttonLabel='Create new Evidence'
                buttonOnClick={handleFormOpen}
                showSearch={true}
                renderRow={renderTableRow}
                callService={fetchManualEvidences}
                setMessageItem={setMessageItem}
            />
            <ErrorHandlingSnackbar messageItem={messageItem}/>
        </>
    )
}

export default TrainingManualEvidences