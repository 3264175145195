import { Modal } from '@mui/material'
import React from 'react'
import { useLocation } from 'react-router-dom'
import { MessageItem } from 'src/components/errorHandlingSnackbar'
import { NameDto } from 'src/dtos/NameDto.dto'
import JournalEntryEdit from 'src/pages/User Site/Inspections/Journal/JournalEntry/Components/JournalEntryEdit'
import JournalEntryNew from 'src/pages/User Site/Inspections/Journal/JournalEntry/Components/JournalEntryNew'
import { validationSchema } from 'src/pages/User Site/Inspections/Journal/JournalEntry/JournalEntry'

type Props = {
    open: boolean
    handleClose: () => void
    journalEntryId?: string
    setMessageItem: React.Dispatch<React.SetStateAction<MessageItem>>
    onCreate: (journalEntryId: string) => void
    attraction: NameDto
    demoMode?: boolean;
}
function JournalEntryForm({open, handleClose, journalEntryId, setMessageItem, onCreate, attraction, demoMode}: Props) {

    const location = useLocation()

    
    return (
        <Modal
            open={open}
            onClose={handleClose}
        >
            <div style={{ position: "relative", width: "100%", height: "100svh", overflow: "hidden", background:"#fff" }}>
                {journalEntryId ? (
                    <>
                        <JournalEntryEdit
                            id={journalEntryId}
                            validationSchema={validationSchema}
                            setMessageItem={setMessageItem}
                            attractionName={attraction}
                            onBackClick={handleClose}
                            backLink={location.pathname}
                            demoMode={demoMode}
                        />
                    </>
                ) : (
                    <>
                        <JournalEntryNew
                            validationSchema={validationSchema}
                            setMessageItem={setMessageItem}
                            attractionName={attraction}
                            backLink={location.pathname}
                            onBackClick={handleClose}
                            onCreate={onCreate}
                            demoMode={demoMode}
                        />
                    </>
                )}

            </div>
        </Modal>
    )
}

export default JournalEntryForm