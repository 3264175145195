import { Box, TableCell } from '@mui/material'
import React, { useState } from 'react'
import ClickableTableRow from 'src/components/Datatable/ClickableTableRow'
import Datatable from 'src/components/Datatable/Datatable'
import { TableHeader } from 'src/components/Datatable/TableHeader'
import ErrorHandlingSnackbar, { MessageItem } from 'src/components/errorHandlingSnackbar'
import { dateOptions } from 'src/config/constants'
import { ListResponseDto } from 'src/dtos/ListResponse.dto'
import { TrainingMemoDto } from 'src/dtos/Training/TrainingMemo.dto'
import { ListParameters, SortDirection } from 'src/Services/ListParameters'
import TrainingMemoService from 'src/Services/Training/TrainingMemoService'

function OutstandingTrainingMemos() {
    const tableHeaders: TableHeader[] = [
        {label: "Name", field: "label", width: "25rem", sortable: true},
        {label: "Date Effective", field: "dateEffective", width: "20rem", sortable: true},
        {label: "Acceptances", field: "acceptances", width: "25rem", sortable: true},
    ]

    const [messageItem, setMessageItem] = useState<MessageItem>({})

    const initialListParams: ListParameters = {
        page: 1,
        pageSize: 30,
        sortBy: "createdOn",
        sortDirection: SortDirection.Ascending
    }

    const fetchTrainingMemos = async (listParams: ListParameters, searchTerm?: string): Promise<ListResponseDto> => {
        const data = await TrainingMemoService.GetOutstanding(listParams, searchTerm)
        return data
    }

    const renderTableRow = ({data, index}: {data: TrainingMemoDto, index: number}) => {
        const acceptancesString = `${data.totalSignatures}/${data.totalTeamMembers}`

        return (
            <>
                <ClickableTableRow
                    hover
                    href={`${window.location.pathname}${window.location.pathname[window.location.pathname.length-1] === "/" ? "" : "/"}${data.id}`}
                    key={`TrainingMemoRow_${index}`}
                    sx={{cursor: "pointer", textDecoration: "none"}}
                >
                    <TableCell sx={{minWidth: `${tableHeaders[0].width} !important`, maxWidth: `${tableHeaders[0].width} !important`}}>{data.label}</TableCell>
                    <TableCell sx={{minWidth: `${tableHeaders[1].width} !important`, maxWidth: `${tableHeaders[1].width} !important`}}>{data.dateEffective && new Date(data.dateEffective).toLocaleDateString(undefined, dateOptions)}</TableCell>
                    <TableCell sx={{minWidth: `${tableHeaders[2].width} !important`, maxWidth: `${tableHeaders[2].width} !important`}}>{acceptancesString}</TableCell>
                </ClickableTableRow>
            </>
        )
    }

    
    return (
        <>
            <Box sx={{padding:"1rem"}}>
                <Datatable
                    tableHeaders={tableHeaders}
                    initialListParams={initialListParams}
                    tableTitle='Outstanding Training Memos'
                    showSearch={true}
                    renderRow={renderTableRow}
                    callService={fetchTrainingMemos}
                    setMessageItem={setMessageItem}
                />
            </Box>
            <ErrorHandlingSnackbar messageItem={messageItem}/>
        </>
    )
}

export default OutstandingTrainingMemos