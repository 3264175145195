import { Autocomplete, Chip, InputBase, LinearProgress, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import React, { useEffect, useState } from 'react'
import { MessageItem } from 'src/components/errorHandlingSnackbar'
import TeamMemberService from 'src/Services/HumanResources/TeamMemberService'
import SearchIcon from '@mui/icons-material/Search';
import { Link } from 'react-router-dom'
import { LearningDocumentType, LearningDocumentTypeString } from 'src/dtos/Training/LearningDocument.dto'
import { dateOptions } from 'src/config/constants'
import { LearningDocumentResponseStatusString } from 'src/dtos/Training/LearningDocumentResponse.dto'
import { LearningItemTypeString } from 'src/dtos/Training/LearningItem.dto'
import { GetTopicStatusColour } from 'src/pages/User Site/TrainingDocuments/calculateStatusHelper'

type Props = {
    id: string
    setMessageItem: React.Dispatch<React.SetStateAction<MessageItem>>
}
function TrainingFacilitated({id, setMessageItem}: Props) {

    const date = new Date()
    const startYear = date.getMonth() >= 7 ? date.getFullYear() : date.getFullYear() -1;
    const currentFY = `${startYear}-${startYear+1}`

    const [financialYear, setFinancialYear] = useState(currentFY)
    const [searchTerm, setSearchTerm] = useState("")

    const trainingFacilitatedQuery = useQuery({
        queryKey: ["TeamMember.GetTrainingFacilitated", id, financialYear],
        queryFn: async () => {
            return await TeamMemberService.GetTrainingFacilitated(id, financialYear)
        },
        initialData: {
            trainingFacilitated: [],
            financialYears: [currentFY]
        }
    })

    useEffect(() => {
        if(trainingFacilitatedQuery.isError)
            setMessageItem({error: trainingFacilitatedQuery.error})
    }, [setMessageItem, trainingFacilitatedQuery.error, trainingFacilitatedQuery.isError])
    
    return (
        <Stack spacing={1} sx={{paddingTop:"0.5rem"}}>
            <Stack direction={"row"} spacing={2}>
                <Paper
                    component="form"
                    sx={{p: '2px 1px', marginLeft:"1rem", display: 'flex', alignItems: 'center', width:"13rem" }}
                >
                    <SearchIcon color="disabled"/>
                    <InputBase
                        sx={{ml: 1, flex: 1}}
                        placeholder='Search'
                        value={searchTerm}
                        onChange={e => setSearchTerm(e.target.value)}
                    />

                </Paper>
                <Autocomplete
                    id="FinancialYear"
                    options={trainingFacilitatedQuery.data.financialYears}
                    value={financialYear}
                    onChange={(_e, value) => {
                        setFinancialYear(value)
                    }}
                    disableClearable
                    size={"small"}
                    sx={{width:"10rem"}}
                    renderInput={(params) => 
                        <TextField 
                            {...params}
                            label="Financial Year"
                        />
                    }
                />
            </Stack>
            {trainingFacilitatedQuery.isFetching ? (
                <LinearProgress/>
            ) : (
                <div style={{height:"4px"}}/> //spacer
            )}
            <TableContainer component={Paper}>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                Training Item
                            </TableCell>
                            <TableCell>
                                Date
                            </TableCell>
                            <TableCell>
                                Status
                            </TableCell>
                            <TableCell>
                                Type
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {trainingFacilitatedQuery.data.trainingFacilitated.filter(tf => tf.label.toLowerCase().includes(searchTerm.toLowerCase())).map((trainingFacilitated, index) => {
                            let url = ""
                            
                            if (trainingFacilitated.learningDocumentResponseId && trainingFacilitated.trainingSessionId){
                                url = `/completed/training/trainingSessions/${trainingFacilitated.trainingSessionId}/${trainingFacilitated.learningDocumentResponseId}`
                            } else if (trainingFacilitated.learningItemResponseId && trainingFacilitated.trainingSessionId){
                                url = `/completed/training/trainingSessions/${trainingFacilitated.trainingSessionId}/Attendance/${trainingFacilitated.learningItemResponseId}`
                            } else if(trainingFacilitated.learningDocumentResponseId && trainingFacilitated.documentType === LearningDocumentType.competencyDocument) { 
                                url = `/completed/training/assessments/${trainingFacilitated.learningDocumentResponseId}`
                            }

                            return (
                                <TableRow key={index}>
                                    <TableCell>
                                        {url !== "" ? 
                                            <Link to={url}>
                                                {trainingFacilitated.label}
                                            </Link>
                                            :
                                            <Typography>{trainingFacilitated.label}</Typography>
                                        }
                                    </TableCell>
                                    <TableCell>
                                        {trainingFacilitated.date ? new Date(trainingFacilitated.date).toLocaleDateString(undefined, dateOptions) : ""}
                                    </TableCell>
                                    <TableCell>
                                        <Chip
                                            size="small"
                                            label={LearningDocumentResponseStatusString[trainingFacilitated.status]}
                                            color={GetTopicStatusColour(trainingFacilitated.status)}
                                        />
                                        
                                    </TableCell>
                                    <TableCell>
                                        {
                                            trainingFacilitated.documentType != null ? 
                                            LearningDocumentTypeString[trainingFacilitated.documentType] :
                                            trainingFacilitated.itemType != null ?
                                            LearningItemTypeString[trainingFacilitated.itemType]
                                            : ""
                                        }
                                    </TableCell>
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </Stack>
    )
}

export default TrainingFacilitated