import { LoadingButton } from '@mui/lab'
import { Typography } from '@mui/material'
import { useMutation } from '@tanstack/react-query'
import React from 'react'
import { MessageItem } from 'src/components/errorHandlingSnackbar'
import TrainingQualificationService from 'src/Services/Training/TrainingQualificationService'

type Props = {
    setMessageItem: React.Dispatch<React.SetStateAction<MessageItem>>
}
function CheckCompetencies({setMessageItem}:Props) {

    const updateCompetenciesMutation = useMutation({
        mutationFn: async () => {
            return await TrainingQualificationService.QueueAllTeamMembers()
        },
        onSuccess: () => {
            setMessageItem({successMessage: "Team members successfully queued"})
        },
        onError: (error) => {
            setMessageItem({error: error})
        }
    })

    return (
        <div style={{display:"flex", flexDirection:"column"}}>
            <Typography variant='h6'>Update All Team Members Competencies</Typography>
            <Typography>This function will queue team members to check each member's completed learning against the requirements of a training area (from the training group configuration). If the requirements are met, the member will be given the corresponding competency for that area.</Typography>
            <div style={{paddingTop:"1rem"}}>
                <LoadingButton
                    variant='contained'
                    loading={updateCompetenciesMutation.isPending}
                    onClick={() => updateCompetenciesMutation.mutate()}
                >
                    Update Competenices 
                </LoadingButton>
            </div>
        </div>
    )
}

export default CheckCompetencies