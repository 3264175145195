import { useQuery } from '@tanstack/react-query'
import React, { useEffect } from 'react'
import { MessageItem } from 'src/components/errorHandlingSnackbar'
import DepartmentService from 'src/Services/Resources/DepartmentService'
import DepartmentRole from './DepartmentRole'
import TeamMemberService from 'src/Services/HumanResources/TeamMemberService'


type Props = {
    teamMemberId: string;
    setMessageItem: React.Dispatch<React.SetStateAction<MessageItem>>
}
function DepartmentRoles({teamMemberId, setMessageItem}: Props) {
    const departmentNamesQuery = useQuery({
        queryKey: ["Departments.GetNames"],
        queryFn: async () => {
            return await DepartmentService.GetNames()
        }
    })

    const tmDepartmentRolesQuery = useQuery({
        queryKey: ["TeamMembers.GetDepartmentRoles", teamMemberId, true],
        queryFn: async () => {
            return await TeamMemberService.GetDepartmentRoles(teamMemberId, true)
        }
    })

    useEffect(() => {
        if(departmentNamesQuery.isError)
            setMessageItem({error: departmentNamesQuery.error})
    }, [setMessageItem, departmentNamesQuery.isError, departmentNamesQuery.error])

    const departmentNames = departmentNamesQuery.data ?? [];
    const tmDepartmentRoles = tmDepartmentRolesQuery.data ?? [];
    return (
        <>
            {departmentNames.map((department, index) => (
                <DepartmentRole
                    key={index}
                    teamMemberId={teamMemberId}
                    department={department}
                    assignedDepartmentRole={tmDepartmentRoles.find(tmDepRole => tmDepRole.role.id === department.id)}
                    checked={tmDepartmentRoles.find(tmDepRole => tmDepRole.role.id === department.id)?.enabled === true}
                    setMessageItem={setMessageItem}
                />
            ))

            }
        </>
    )
}

export default DepartmentRoles