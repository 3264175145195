import { CircularProgressProps, Box, CircularProgress, Typography, Accordion, AccordionSummary, Stack, Divider, AccordionDetails, Chip } from '@mui/material';
import { FormikProps } from 'formik';
import React, { Fragment } from 'react'
import { MessageItem } from 'src/components/errorHandlingSnackbar';
import { InspectionChecklistSection, SectionRepeatType } from 'src/dtos/AwTypes';
import { InspectionChecklistResponseDto, InspectionChecklistSectionResponseDto } from 'src/dtos/Checklists/AttractionInspections/ChecklistResponse.dto';
import { Status, StatusString } from 'src/dtos/Statuses';
import { ExpandMore } from '@mui/icons-material'
import Question from './question/Question';
import { NameDto } from 'src/dtos/NameDto.dto';
import { GetQuestionStatus, GetQuestionVisible, GetSectionStatus, GetSectionStatusColour } from '../CalculateStatusesHelper';
import GroupedSectionComponents from './GroupedSection/GroupedSectionComponents';

type Props = {
    formik: FormikProps<InspectionChecklistResponseDto>
    formikString: string
    sectionContent: InspectionChecklistSection;
    sectionResponse: InspectionChecklistSectionResponseDto
    questionsEditable: true | string;
    setMessageItem: React.Dispatch<React.SetStateAction<MessageItem>>;
    attraction: NameDto;
    demoMode?: boolean; //If the answers are saved.
}

function Section({formik, formikString, sectionContent, sectionResponse, questionsEditable, setMessageItem, attraction, demoMode}: Props) {
    
    const totalQuestions = sectionResponse.questionResponses.filter((qr, index) =>  ![Status.NA, Status.NotInspected].includes(GetQuestionStatus(qr, sectionContent.questions[index], formik.values.inspectedComponents, formik.values.selectedComponents ?? []))).length;
    const passedQuestions = sectionResponse.questionResponses.filter((qr, index) => GetQuestionStatus(qr, sectionContent.questions[index], formik.values.inspectedComponents, formik.values.selectedComponents ?? []) === Status.Passed).length;
    

    function QuestionProgress(props: CircularProgressProps & { value: number }) {
        const progressColour = sectionStatus === Status.Passed ? "success" : sectionStatus === Status.Failed ? "error" : "primary";
        return (
            <Box sx={{ position: "relative", display: "inline-flex" }}>
                <CircularProgress variant="determinate" {...props} color={progressColour} size="3rem"/>
                <Box
                    sx={{
                        top: 0,
                        left: 0,
                        bottom: 0,
                        right: 0,
                        position: "absolute",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    <Typography
                        variant="caption"
                        component="div"
                        color="text.secondary"
                    >{`${passedQuestions}/${totalQuestions}`}</Typography>
                </Box>
            </Box>
        );
    }

    const sectionStatus = GetSectionStatus(sectionResponse, sectionContent, formik.values.inspectedComponents, formik.values.selectedComponents ?? [])
    const statusColour = GetSectionStatusColour(sectionStatus)
    

    return (
        <Accordion>
            <AccordionSummary
                expandIcon={<ExpandMore/>}
                aria-controls={`${sectionContent.label}-content`}
                id={`${sectionContent.label}-header`}
            >
                <Stack direction="row" sx={{ width: "100%", marginRight:"1rem", display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                    <Typography variant='h5'>{sectionContent.label}</Typography>
                    <Stack direction="row" spacing={1} sx={{ display: "flex", alignItems: "center"}}>
                        <Chip
                            label={StatusString[sectionStatus]}
                            color={statusColour}
                        />
                        <QuestionProgress 
                            value={(passedQuestions/totalQuestions)*100}  
                        />          
                    </Stack>
                    
                </Stack>
            </AccordionSummary>
            <Divider/>
            <AccordionDetails>
                <Typography variant='body1' sx={{paddingLeft:"1rem"}}>{sectionContent.sublabel}</Typography>
                <Stack spacing={2}>
                    {sectionContent.questions.map((question, questionIndex) => (
                        <Fragment key={questionIndex}>
                            {sectionResponse.questionResponses[questionIndex] != null && GetQuestionVisible(question, formik.values.selectedComponents ?? []) && (
                                <Question
                                    formik={formik}
                                    formikString={`${formikString}.questionResponses[${questionIndex}]`}
                                    questionContent={question}
                                    questionResponse={sectionResponse.questionResponses[questionIndex]}
                                    questionEditable={questionsEditable}
                                    attraction={attraction}
                                    setMessageItem={setMessageItem}
                                    sectionRepeatTypeGrouped={sectionContent.sectionRepeatType === SectionRepeatType.Grouped}
                                    demoMode={demoMode}
                                />
                            )}
                        </Fragment>
                    ))}
                    {sectionContent.sectionRepeatType === SectionRepeatType.Grouped && sectionContent.repeatingComponentSet && sectionResponse.groupedSectionComponentResponses &&(
                        <GroupedSectionComponents
                            parentComponentLabel={sectionContent.repeatingComponentSet.label}
                            formik={formik}
                            formikString={formikString}
                            sectionResponse={sectionResponse}
                            sectionQuestions={sectionContent.questions}
                            attraction={attraction}
                            setMessageItem={setMessageItem}
                            demoMode={demoMode}
                        />
                    )}
                </Stack>
            </AccordionDetails>
        </Accordion>
    )
}

export default Section