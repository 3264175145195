import { TabContext, TabList, TabPanel } from '@mui/lab'
import { Box, Paper, Tab } from '@mui/material'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useFormik } from 'formik'
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import ErrorHandlingSnackbar, { MessageItem } from 'src/components/errorHandlingSnackbar'
import { TrainingMemoDto } from 'src/dtos/Training/TrainingMemo.dto'
import TrainingMemoService from 'src/Services/Training/TrainingMemoService'
import { guidRegex } from 'src/Utils/helperFunc'
import MemoMemorandum from './Components/MemoMemorandum'
import MemoTeamMembers from './Components/MemoTeamMembers'
import * as yup from "yup";
import MemoTeamAcceptance from './Components/MemoTeamAcceptance'

const savingValidationSchema = yup.object({
    label: yup.string().required("Title is a required field").max(75, "Title cannot be more than 75 characters"),
    dateEffective: yup.date().notRequired(),
    type: yup.number().notRequired(),
    changeOverview: yup.string().notRequired().max(300, "Change overview cannot be more than 300 characters"),
    changeDetail: yup.string().notRequired(),
    intentOfChange: yup.string().notRequired().max(300, "Intent of change cannot be more than 300 characters")
})
const issuingValidationSchema = yup.object({
    label: yup.string().required("Title is a required field").max(75, "Title cannot be more than 75 characters"),
    dateEffective: yup.date().required("Date Effective is a required field"),
    type: yup.number().required("Type of Change is a required field"),
    changeOverview: yup.string().required("Change overview is a required field").max(300, "Change overview cannot be more than 300 characters"),
    changeDetail: yup.string().required("Change detail is a required field"),
    intentOfChange: yup.string().required("Intent of change is a required field").max(300, "Intent of change cannot be more than 300 characters")
})

function TrainingMemoCreator() {
    const [searchParams, setSearchParams] = useSearchParams()
    const defaultTab = "Memo"
    const [openTab, setOpenTab] = useState(searchParams.get("openTab") ?? defaultTab);
    const [messageItem, setMessageItem] = useState<MessageItem>({})
    const [submitType, setSubmitType] = useState<"save" | "issue">("save")
    

    const {id} = useParams();
    const urlIdRegex = new RegExp(`/${id}$`)
    const navigate = useNavigate()
    const queryClient = useQueryClient()
    
    //#region queries
    const trainingMemoQuery = useQuery({
        queryKey: ["trainingMemo", id],
        queryFn: async () => {
            if(id !== undefined && guidRegex.test(id))
                return await TrainingMemoService.Get(id)
            return TrainingMemoService.GetDefaultValues()
        },
        initialData: TrainingMemoService.GetDefaultValues()
    })

    useEffect(() => {
        if(trainingMemoQuery.isError){
            setMessageItem({error: trainingMemoQuery.error})
        }
        
    }, [trainingMemoQuery.error, trainingMemoQuery.isError])

    const createMutation = useMutation({
        mutationFn: (values: TrainingMemoDto) => {
            return TrainingMemoService.Create(values)
        },
        onSuccess: (data) => {
            setMessageItem({successMessage: "Training Memo created successfully!"})

            //update URL
            navigate({pathname: window.location.pathname.replace(urlIdRegex, `/${data.data.id}`)})
        },
        onError: (error) => {
            setMessageItem({error: error})
        }
    })

    const updateMutation = useMutation({
        mutationFn: ({id, values}: {id: string, values: TrainingMemoDto}) => {
            return TrainingMemoService.Update(id, values)
        },
        onSuccess: (data) => {
            setMessageItem({successMessage: "Training Memo updated successfully!"})
            //update query data to newly update values
            queryClient.setQueryData(["trainingMemo", id], data.data)
        },
        onError: (error) => {
            setMessageItem({error: error})
        }
    })

    const issueMutation = useMutation({
        mutationFn: (id: string) => {
            return TrainingMemoService.Issue(id)
        },
        onSuccess: (data) => {
            setMessageItem({successMessage: "Training Memo issued successfully!"})
            formik.setValues(data.data)
        },
        onError: (error) => {
            setMessageItem({error: error})
        }
    })

    
    
    //#endregion

    const handleTabChange = (_event: React.SyntheticEvent, tabValue: string) => {
        setOpenTab(tabValue);
        if(tabValue === defaultTab) {
            searchParams.delete("openTab")
        } else {
            searchParams.set("openTab", tabValue)
        }
        setSearchParams(searchParams)
    };

    const handleSave = async (values: TrainingMemoDto) => {
        if(id === "new"){
            //Create
            createMutation.mutate(values)
        } else if(id !== undefined && guidRegex.test(id)){
            //update
            updateMutation.mutate({id, values})
        }
    }

    const handleIssue = async () => {
        if(formik.dirty){
            setMessageItem({errorMessage: "Save your changes before issuing the memo!"})
            return
        }

        if(id !== undefined && guidRegex.test(id))
            issueMutation.mutate(id)
    }

    const handleSubmit = async (values: TrainingMemoDto) => {
        if(submitType === "save"){
            handleSave(values)  
        } else {
            handleIssue()
        }
    }


    const formik = useFormik({
        enableReinitialize: true,
        initialValues: trainingMemoQuery.data,
        validationSchema: submitType === "issue" ? issuingValidationSchema : savingValidationSchema,
        onSubmit: (values) => {
            handleSubmit(values)
        }
    })
    
    return (
        <>
            <Paper>
                <TabContext value={openTab}>
                    <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                        <TabList onChange={handleTabChange}>
                            <Tab label="Memorandum" value="Memo"/>
                            <Tab label="Team Members" value="TeamMembers" disabled={id === "new"}/>
                            <Tab label="Team Acceptance" value="TeamAcceptance" disabled={formik.values.dateIssued == null}/>
                        </TabList>
                    </Box>
                    <TabPanel value="Memo">
                        <MemoMemorandum
                            formik={formik}
                            loading={createMutation.isPending || updateMutation.isPending || issueMutation.isPending}
                            setSubmitType={setSubmitType}
                            setMessageItem={setMessageItem}
                        />
                    </TabPanel>
                    <TabPanel value="TeamMembers">
                        <MemoTeamMembers
                            id={formik.values.id}
                            formik={formik}
                            setMessageItem={setMessageItem}
                        />
                    </TabPanel>
                    <TabPanel value="TeamAcceptance">
                        <MemoTeamAcceptance 
                            trainingMemo={formik.values}
                        />
                    </TabPanel>

                </TabContext>
            </Paper>
            <ErrorHandlingSnackbar messageItem={messageItem}/>
        </>
    )
}

export default TrainingMemoCreator