import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, Tab, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import React, { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom';
import RoleService from 'src/Services/HumanResources/RoleService';
import { MessageItem } from 'src/components/errorHandlingSnackbar';
import { RoleGroupType } from 'src/dtos/Resources/User.dto';
import TeamMemberService from 'src/Services/HumanResources/TeamMemberService';
import TmRole from './Components/TmRole';
import DepartmentRoles from './Components/DepartmentRoles';

type Props = {
    teamMemberId: string;
    setMessageItem: React.Dispatch<React.SetStateAction<MessageItem>>
}
function TmRoles({teamMemberId, setMessageItem}: Props) {
    
    const [searchParams, setSearchParams] = useSearchParams()
    const defaultTab = "System"
    const [openTab, setOpenTab] = React.useState(searchParams.get("nestedTab") ?? defaultTab);


    const handleTabChange = (_event: React.SyntheticEvent, tabValue: string) => {
        setOpenTab(tabValue);
        if(tabValue === defaultTab){
            searchParams.delete("nestedTab")
        } else {
            searchParams.set("nestedTab", tabValue)
        }
        setSearchParams(searchParams)
    };

    const rolesQuery = useQuery({
        queryKey: ["rolesNames"],
        queryFn: async () => {
            return await RoleService.GetNamesOLD()
        }
    })

    const tmRolesQuery = useQuery({
        queryKey: ["TeamMembers.GetRoles", teamMemberId, true],
        queryFn: async () => {
            return await TeamMemberService.GetRoles(teamMemberId, true)
        }
    })

    useEffect(() => {
        if(rolesQuery.isError){
            setMessageItem({error: rolesQuery.error})
        }

        if(tmRolesQuery.isError){
            setMessageItem({error: tmRolesQuery.error})
        }
    }, [setMessageItem, rolesQuery.isError, rolesQuery.error, tmRolesQuery.isError, tmRolesQuery.error])

    
    const tmRoles = tmRolesQuery.data ?? [];

    const Roles = ({groupType}:{groupType: RoleGroupType}) => {
        const rolesData = (rolesQuery.data ?? []).filter(role => role.group === groupType)
        return (
            <>
                {rolesData.map((role, index) => (
                    <TmRole
                        key={index}
                        teamMemberId={teamMemberId}
                        role={role}
                        assignedRole={tmRoles.find(tmRole => tmRole.role.id === role.id)}
                        checked={tmRoles.find(tmRole => tmRole.role.id === role.id)?.enabled === true}
                        setMessageItem={setMessageItem}
                    />
                ))}
            </>
        )
    }

    return (
        <>
            <TabContext value={openTab}>
                <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                    <TabList onChange={handleTabChange}>
                        <Tab label="System Roles" value="System"></Tab>
                        <Tab label="Department Roles" value="Departments"></Tab>
                        <Tab label="Attraction Inspection Roles" value="AttractionInspections"></Tab>
                        <Tab label="Training Roles" value="Training"></Tab>

                    </TabList>
                </Box>
                    <TabPanel value="System">
                        <Typography variant='h5'>System Roles</Typography>
                        <Roles groupType={RoleGroupType.System}/>
                        
                    </TabPanel>
                    <TabPanel value="Departments">
                        <Typography variant='h5'>Department Roles</Typography>
                        <DepartmentRoles 
                            teamMemberId={teamMemberId}
                            setMessageItem={setMessageItem}    
                        />
                    </TabPanel>
                    <TabPanel value="AttractionInspections">
                        <Typography variant='h5'>Attraction Inspections Roles</Typography>
                        <Roles groupType={RoleGroupType.AttractionInspections}/>
                    </TabPanel>
                    <TabPanel value="Training">
                        <Typography variant='h5'>Training Roles</Typography>
                        <Roles groupType={RoleGroupType.Training}/>
                    </TabPanel>
            </TabContext>
        </>
    )
}

export default TmRoles