import { TableRow, TableCell, Typography, Stack } from '@mui/material'
import React from 'react'
import { dateTimeOptions } from 'src/config/constants'
import { AttendanceSheetAttendee } from 'src/dtos/Training/AttendanceSheetResponse.dto'
type Props = {
    trainer: AttendanceSheetAttendee
}
function CompletedTrainerRow({trainer}: Props) {
  return (
    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 }, pageBreakInside: "avoid"}}>
        <TableCell>
            <Stack direction={"row"} sx={{paddingLeft:"1rem", alignItems:"center"}}>
                {`${trainer.teamMember.prefferedFirstName} ${trainer.teamMember.lastName}`}
            </Stack>
        </TableCell>
        <TableCell>
            {trainer.attended ? "Yes" : "No"}
        </TableCell>
        <TableCell>
            {trainer.signature &&
                <img style={{width: "5rem"}} src={trainer.signature} alt={`${trainer.teamMember.prefferedFirstName} ${trainer.teamMember.lastName} Signature`}/>
            }
        </TableCell>
        <TableCell>
            <Typography variant='body2' sx={{minWidth:"4rem"}}>
                {trainer.timeSigned && new Date(trainer.timeSigned).toLocaleDateString(undefined, dateTimeOptions)}
            </Typography>
        </TableCell>
    </TableRow>
  )
}

export default CompletedTrainerRow