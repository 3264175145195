import { Stack, Button, TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody, Typography } from "@mui/material"
import { useMutation } from "@tanstack/react-query"
import { FormikProps } from "formik"
import React from "react"
import { useParams } from "react-router-dom"
import LearningDocumentResponseService from "src/Services/Training/LearningDocuments/LearningDocumentResponseService"
import { guidRegex } from "src/Utils/helperFunc"
import CustomSignaturePad from "src/components/SignaturePad/SignaturePad"
import { MessageItem } from "src/components/errorHandlingSnackbar"
import { dateTimeOptions } from "src/config/constants"
import { AttendanceSheetResponse, AttendeeType } from "src/dtos/Training/AttendanceSheetResponse.dto"
import { LearningDocumentResponseDto, LearningDocumentResponseStatus } from "src/dtos/Training/LearningDocumentResponse.dto"
import AssessorRow from "./AssessorRow"
import { LoadingButton } from "@mui/lab"
import { GetTopicStatus } from "src/pages/User Site/TrainingDocuments/calculateStatusHelper"


function TraineeSignOff({formik, updateAttendanceSheetResponse}: {formik: FormikProps<LearningDocumentResponseDto>, updateAttendanceSheetResponse: (newValues: AttendanceSheetResponse) => void;}) {
    //There should only be one trainee
    const traineeIndex = formik.values.attendanceSheetResponse.attendees.findIndex(tm => tm.type === AttendeeType.Trainee)
    
    if (traineeIndex === -1){
        return null
    }
    const trainee = formik.values.attendanceSheetResponse.attendees[traineeIndex]
    
    const updateTraineeSignature = (signature: string | undefined) => {
        let newValues = formik.values.attendanceSheetResponse
        newValues.attendees[traineeIndex].attended = true
        newValues.attendees[traineeIndex].signature = signature
        if(signature){
            newValues.attendees[traineeIndex].timeSigned = new Date()
        } else {
            newValues.attendees[traineeIndex].timeSigned = undefined
        }
        updateAttendanceSheetResponse(newValues)
    }
    const traineeName = `${trainee.teamMember.prefferedFirstName} ${trainee.teamMember.lastName}`
    return (
        <>
            <TableContainer component={Paper}  sx={{ minWidth: "30rem", width: "30rem" }}>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell>Name</TableCell>
                        <TableCell>Signature</TableCell>
                        <TableCell>Time Signed</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow
                        key={trainee.id}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                        <TableCell component="th" scope="row">
                            {traineeName}
                        </TableCell>
                        <TableCell>
                            <CustomSignaturePad 
                                person={{name: traineeName, signature: trainee.signature}}
                                setSignature={(signature: string | undefined) => updateTraineeSignature(signature)}
                            />
                        </TableCell>
                        <TableCell>
                            {trainee.timeSigned && new Date(trainee.timeSigned).toLocaleDateString(undefined, dateTimeOptions)}
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
        </>
    )
}

type Props = {
    formik: FormikProps<LearningDocumentResponseDto>
    setMessageItem: React.Dispatch<React.SetStateAction<MessageItem>>
    departmentName?: string
    currentUser: {id: string, name: string}
}
function CompetencyDocumentSignOff({formik, setMessageItem, departmentName, currentUser}: Props) {
    const {responseId} = useParams();

    //#region queries
    const updateCompetentMutation = useMutation({
        mutationFn: ({id, value}: {id: string, value: boolean | null}) => {
            return LearningDocumentResponseService.UpdateCompetent(id, value)
        },        
    })

    const updateAttendanceSheetResponseMutation = useMutation({
        mutationFn: ({id, values}: {id: string, values: AttendanceSheetResponse}) => {
            return LearningDocumentResponseService.UpdateAttendanceSheetResponse(id, values)
        },        
    })
    //#endregion

    const handleUpdateAttendanceSheetResponse = (newValues: AttendanceSheetResponse) => {
        formik.setFieldValue(`attendanceSheetResponse`, newValues)
        if(responseId && guidRegex.test(responseId)){
            updateAttendanceSheetResponseMutation.mutate({id: responseId, values: newValues})
        }
    }

    const ResultOfAssesment = () => {        
        const competentTopics = formik.values.topicResponses.filter(tr => GetTopicStatus(tr, true) === LearningDocumentResponseStatus.Competent).length
        const notYetCompetentTopics = formik.values.topicResponses.filter(tr => GetTopicStatus(tr, true) === LearningDocumentResponseStatus.NotYetCompetent).length
        const totalTopics = formik.values.topicResponses.length

        const handleUpdateCompetent = (newValue: boolean | null) => {
            formik.setFieldValue("competent", newValue)
            if(responseId && guidRegex.test(responseId)){
                updateCompetentMutation.mutate({id: responseId, value: newValue})
            }

        }
        
        const handleYesClick = () => {
            if(competentTopics !== totalTopics){
                setMessageItem({errorMessage: "Cannot set outcome as Competent unless competency in all topics has been achieved."})
                return
            }
            if(formik.values.competent){
                handleUpdateCompetent(null)
            } else {
                handleUpdateCompetent(true)
            }
        }
    
        const handleNoClick = () => {
            if(competentTopics + notYetCompetentTopics !== totalTopics){
                setMessageItem({errorMessage: "All topics must be set as Competent or Not Yet Competent before an outcome can be set."})
                return
            }
            if(formik.values.competent === false){
                handleUpdateCompetent( null)
            } else {
                handleUpdateCompetent(false)
            }
        }
        
        return (
          <Stack direction="row" spacing={2}> 
            <Button 
              size="medium"
              sx={{width:"15rem"}}
              variant={formik.values.competent === true ? "contained" : "outlined"}
              color="success" 
              onClick={handleYesClick}
            >
                Trainee has ACHIEVED Probationary Competency in this role
            </Button>
            <Button           
              size="medium"
              sx={{width:"15rem"}}
              variant={formik.values.competent === false ? "contained" : "outlined"} 
              color="warning" 
              onClick={handleNoClick}
            >
                Trainee has NOT YET ACHIEVED Probationary Competency in this role
            </Button>
          </Stack>
        )
    }

    const AssesorSignOff = () => {
        const assessors = formik.values.attendanceSheetResponse.attendees.filter(asr => asr.type === AttendeeType.Trainer)

        const addAssessorMutation = useMutation({
            mutationFn: ({id, microsoftId}: {id: string, microsoftId: string}) => {
                return LearningDocumentResponseService.AddAssessor(id, microsoftId)
            },
            onSuccess: (data) => {
                formik.setFieldValue("attendanceSheetResponse", data.data.attendanceSheetResponse)
            },
            onError: (error) => {
                setMessageItem({error: error})
            }
        })
        const handleAddAssessor = () => {
            if(responseId && guidRegex.test(responseId)){
                addAssessorMutation.mutate({id: responseId, microsoftId: currentUser.id})
            }
        }
        return (
            <>
            
            <TableContainer component={Paper} sx={{ minWidth: "45rem", width: "50rem" }}>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell>Notes</TableCell>
                            <TableCell>Signature</TableCell>
                            <TableCell>Time Signed</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {assessors.map((assessor) => (
                            <AssessorRow
                                key={assessor.id}
                                formik={formik}
                                assessor={assessor}
                                updateAttendanceSheetResponse={handleUpdateAttendanceSheetResponse}
                            />  
                        ))}
                        {assessors.findIndex(assessor => assessor.teamMember.microsoftAccountId === currentUser.id) === -1 && (
                            <TableRow>
                                <TableCell colSpan={4}>
                                    <LoadingButton onClick={handleAddAssessor} loading={addAssessorMutation.isPending}>Add Myself as an Assessor</LoadingButton>

                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            </>
        )
    }

    return (
        <>
            <Typography variant='h6'>Outcome</Typography>
            <ResultOfAssesment/>
            <Typography variant='h6' sx={{marginTop:"1rem"}}>Assessors</Typography>
            <Typography>
                As the Assessor, I acknowledge I have completed this document myself and I am responsible for the results shown by signing the below section. If Competent I acknowledge that the trainee has completed all necessary criteria to work in this role unsupervised. 
            </Typography>
            {formik.values.attendanceSheetResponse && (
                <AssesorSignOff/>
            )}
            <Typography variant='h6' sx={{marginTop:"1rem"}}>Trainee</Typography>
            <Typography>By signing below, I agree to accept the results of this assessment as both fair and accurate. I understand that until I receive competency in all areas of this assessment, I cannot undertake this role, I have up to two attempts to achieve this.  </Typography>
            {formik.values.attendanceSheetResponse && (
                <TraineeSignOff
                    formik={formik}
                    updateAttendanceSheetResponse={handleUpdateAttendanceSheetResponse}
                />
            )}  
            <Typography sx={{marginTop:"1rem"}}>{`Assessors have been appointed by Training and Recruitment and ${departmentName} Departments, they have been assessed as having current industry skills and knowledge relevant to the assessment being undertaken. Digital assessment tools are owned and monitored by the Training and Recruitment Team.`}</Typography>
        </>
    )
}

export default CompetencyDocumentSignOff