import axios, { AxiosResponse } from "axios";
import { GetAuthorisation } from "src/Utils/authUtils";
import { JournalDto } from "src/dtos/Checklists/AttractionInspections/Journal.dto";

const apiBaseUrl = process.env.REACT_APP_API_BASE_URL + "/AttractionJournal";
const debug = process.env.REACT_APP_DEBUG;

export default class AttractionJournalService {
    

    static async GetJournals() : Promise<JournalDto[]> {
        try {
            const authorization = await GetAuthorisation();
            const response: AxiosResponse<JournalDto[]> = await axios.get<JournalDto[]>(`${apiBaseUrl}/GetJournals`, {
                headers: {
                    Authorization: authorization
                },
            });
            return response.data
        } catch (error: any) {
            debug && console.log(error)
            throw error;
        }
    }

    static async UpdateReadTime(attractionId: string): Promise<AxiosResponse> {
        try {
            const authorization = await GetAuthorisation();
            const response: AxiosResponse = await axios.post<AxiosResponse>(`${apiBaseUrl}/UpdateReadTime/${attractionId}`, null,{
                headers: {
                    Authorization: authorization
                }
            });
            return response
        } catch(error: any) {
            debug && console.log(error)
            throw error;
        }
    }
}
