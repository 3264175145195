import { Stack, Typography, Paper, TableContainer, Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material'
import React from 'react'
import assets from 'src/assets'
import { AttendanceSheetAttendee } from 'src/dtos/Training/AttendanceSheetResponse.dto'
import { LearningDocumentTopic, LearningDocumentType } from 'src/dtos/Training/LearningDocument.dto'
import CompletedTraineeRow from '../AttendanceSheet/CompletedTraineeRow'
import CompletedTrainerRow from '../AttendanceSheet/CompletedTrainerRow'
import { dateOptions } from 'src/config/constants'
import textContent from 'src/config/textContent'

type Props = {
    learningDocumentLabel: string
    topics: LearningDocumentTopic[]
    trainees: AttendanceSheetAttendee[]
    trainers: AttendanceSheetAttendee[]
    departmentLabel: string
    trainingSessionDate?: Date | null
    type?: LearningDocumentType;
}
function CompletedAttendanceSheet({learningDocumentLabel, topics, trainees, trainers, departmentLabel, trainingSessionDate, type}: Props) {

    return (
    <>
        <Stack spacing={2}>
            <Stack direction="row" sx={{width:"100%", padding:"1rem"}}>
                <img alt="AW LOGO" src ={assets.images.logo} style={{ width: "5rem", height: "5rem" }}/>
                <Typography variant='h5' sx={{width: "100%", paddingLeft:"1rem", paddingRight:"1rem", display:"flex", justifyContent:"center", alignItems:"center", textAlign:"center"}}>{`Training Outcome - ${learningDocumentLabel}`}</Typography>
                <Typography sx={{display: "flex", justifyContent: "center", alignItems:"center", width:"8rem"}}>{trainingSessionDate && new Date(trainingSessionDate).toLocaleDateString(undefined, dateOptions)}</Typography>
            </Stack>
            <div style={{padding: "1rem"}}>
                <Typography variant='h6'>Training Topics</Typography>
                <div style={{display:"flex", flexDirection: "column", flexWrap:"wrap", gap:"0.5rem", width:"80%", maxHeight:"10rem"}}>
                    {topics.map((topic, index) => (
                        <Typography key={index}>{`Topic ${index+1}: ${topic.label}`}</Typography>   
                    ))}
                </div>

                <Typography variant='h6'>Trainees</Typography>
                {type === LearningDocumentType.trainingDocumentRTM ? (
                    <Typography>
                        {textContent.learningDocument.RTMtraineeSignOff}
                    </Typography>
                ) : (
                    <Typography>
                        {textContent.learningDocument.NTMtraineeSignOff}
                    </Typography>
                )}
                <TableContainer component={Paper} sx={{overflow:"visible"}}>
                    <Table sx={{ minWidth: 650 }} size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell>Name</TableCell>
                                <TableCell sx={{width:"6rem", padding: "2px"}}>Attended</TableCell>
                                <TableCell sx={{width:"8rem"}}>Signature</TableCell>
                                <TableCell sx={{width:"8rem"}}>Time Signed</TableCell>
                                <TableCell sx={{width:"8rem"}}>{type === LearningDocumentType.trainingDocumentRTM ? "Competent / Not Yet Competent" : "Ready For Assessment"}</TableCell>
                                <TableCell sx={{ whiteSpace: 'normal', wordWrap: 'break-word', width: '15rem' }}>Notes / Feedback</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {trainees.map((trainee, index) => (
                                <CompletedTraineeRow 
                                    key={index} 
                                    trainee={trainee}
                                />
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>

                <Typography variant='h6' sx={{marginTop: "1rem"}}>Trainers</Typography>
                {type === LearningDocumentType.trainingDocumentRTM ? (
                    <Typography>{textContent.learningDocument.RTMtrainerSignOff}</Typography>
                ) : (
                    <Typography>{textContent.learningDocument.NTMtrainerSignOff}</Typography>
                )}
                <TableContainer component={Paper}  sx={{ minWidth: "30rem", width: "30rem" }}>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell>Name</TableCell>
                                <TableCell>Attended</TableCell>
                                <TableCell>Signature</TableCell>
                                <TableCell>Time Signed</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {trainers.map((trainer, index) => (
                                <CompletedTrainerRow
                                    key={index}
                                    trainer={trainer}
                                />
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <Typography sx={{marginTop:"1rem"}}>{textContent.learningDocument.TrainersClause(departmentLabel)}</Typography>

            </div>
                        
        </Stack>
    </>
  )
}

export default CompletedAttendanceSheet