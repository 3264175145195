import { BaseModel } from "../BaseModel.dto";
import { FileMetadata } from "../FileMetadata.dto";
import { NameDto } from "../NameDto.dto";

export interface TrainingMemoDto extends BaseModel {
    id: string;
    label: string;
    dateEffective?: Date;
    dateIssued?: Date;
    issuedBy?: string;
    affectedTrainingGroups: NameDto[];
    type: TrainingMemoType;
    changeOverview?: string;
    changeDetail: string;
    sopReference?: FileMetadata;
    intentOfChange?: string;
    acceptances: TrainingMemoAcceptanceDto[];
    totalSignatures: number;
    totalTeamMembers: number;
}

export interface TrainingMemoAcceptanceDto {
    teamMember: NameDto;
    signature?: string;
    timeSigned?: Date;
    modifiedBy?: string;
    modifiedOn?: Date;
}

export enum TrainingMemoType {
    ProceduralChange, 
    ProcedureReinforcement
}

export const TrainingMemoTypeString = [
    "Procedural Change",
    "Procedure Reinforcement"
]