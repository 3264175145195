import { Stack, FormControlLabel, Checkbox, Tooltip, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography } from '@mui/material'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import React, { useState } from 'react'
import { MessageItem } from 'src/components/errorHandlingSnackbar'
import { dateTimeOptions } from 'src/config/constants'
import { TeamMemberRole } from 'src/dtos/HumanResources/Role.dto'
import TeamMemberService from 'src/Services/HumanResources/TeamMemberService'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { NameDto } from 'src/dtos/NameDto.dto'

type Props = {
    department: NameDto
    assignedDepartmentRole?: TeamMemberRole
    teamMemberId: string
    checked: boolean
    setMessageItem: React.Dispatch<React.SetStateAction<MessageItem>>  
}

function DepartmentRole({teamMemberId, department, assignedDepartmentRole, checked, setMessageItem}: Props) {
    const queryClient = useQueryClient();
    
    const enableMutation = useMutation({
        mutationFn: () => {
            return TeamMemberService.EnableDepartmentRole(teamMemberId, department.id)
        },
        onSuccess: (data) => {
            setMessageItem({successMessage: "Department Role added successfully!"})
            queryClient.setQueryData(["TeamMembers.GetDepartmentRoles", teamMemberId, true], data.data)
        },
        onError: (error) => {
            setMessageItem({error: error})
        }
    })
    
    const disableMutation = useMutation({
        mutationFn: () => {
            return TeamMemberService.DisableDepartmentRole(teamMemberId, department.id)
        },
        onSuccess: (data) => {
            setMessageItem({successMessage: "Department Role removed successfully!"})
            queryClient.setQueryData(["TeamMembers.GetDepartmentRoles", teamMemberId, true], data.data)
        },
        onError: (error) => {
            setMessageItem({error: error})
        }
    })

    const handleCheckboxChange = () => {
        if(checked){
            disableMutation.mutate()
        } else {
            enableMutation.mutate()
        }
    }

    const ModificationDetails = () => {
        const [open, setOpen] = useState(false)
        
        if(assignedDepartmentRole == null || assignedDepartmentRole.createdBy == null){
            return null
        }

        return (
            <>
                <div style={{display:"felx", alignSelf: "center", justifyContent:"center", cursor:"pointer"}}>
                    <Tooltip title="Modification Details">
                        <IconButton onClick={() => setOpen(true)}>
                            <InfoOutlinedIcon color='primary'/>
                        </IconButton>
                    </Tooltip>
                </div>
                <Dialog
                    open={open}
                    onClose={() => setOpen(false)}
                >
                    <DialogTitle>
                        {`${department.label} Modification Details`}
                    </DialogTitle>
                    <DialogContent>
                        <Typography>{`Last Modified By: ${assignedDepartmentRole.modifiedBy ?? assignedDepartmentRole.createdBy}`}</Typography>
                        <Typography>{`Last Modified: ${new Date(assignedDepartmentRole.modifiedOn ?? assignedDepartmentRole.createdOn ?? "")?.toLocaleDateString(undefined, dateTimeOptions)}`}</Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setOpen(false)}>
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
            </>
        )

    }
    
    return (
        <div>
            <Stack direction="row">
                <FormControlLabel
                    label={department.label}
                    control={
                        (enableMutation.isPending || disableMutation.isPending) ? 
                        <div style={{paddingLeft: "5px", paddingRight:"5px", marginBottom:"10px"}}>
                            <div className="loader" /> 
                        </div>
                        :
                        <Checkbox
                            checked={checked}
                            onChange={handleCheckboxChange}  
                        />
                    }
                    />
                    <ModificationDetails/>
            </Stack>
        </div>
    )
}

export default DepartmentRole