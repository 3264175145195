import { Stack, ListItemButton, ListItemIcon, Checkbox, ListItemText, Button, Typography, Dialog, DialogTitle, DialogContent, List, ListItem, DialogActions } from '@mui/material'
import React, { useState } from 'react'
import { GroupedSectionComponentResponse, InspectionChecklistResponseDto } from 'src/dtos/Checklists/AttractionInspections/ChecklistResponse.dto'
import NoteOutlinedIcon from '@mui/icons-material/NoteOutlined';
import { ChevronRight, Note } from '@mui/icons-material';
import { FormikProps } from 'formik';
import JournalEntryForm from '../question/Components/JournalEntryForm';
import { NameDto } from 'src/dtos/NameDto.dto';
import { MessageItem } from 'src/components/errorHandlingSnackbar';
import { useSearchParams } from 'react-router-dom';
import { QuestionDto, QuestionType } from 'src/dtos/AwTypes';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import InspectionChecklistResponseService from 'src/Services/Checklists/Inspections/ChecklistResponseService';

type Props = {
    formik: FormikProps<InspectionChecklistResponseDto>
    formikString: string;
    componentResponse: GroupedSectionComponentResponse;
    sectionQuestions: QuestionDto[];
    attraction: NameDto;
    demoMode?: boolean;
    setMessageItem: React.Dispatch<React.SetStateAction<MessageItem>>;
}
function ComponentResponse({formik, formikString, componentResponse, attraction, sectionQuestions, demoMode, setMessageItem}: Props) {
    const [journalEntryFormOpen, setJournalEntryFormOpen] = useState(false)
    const [selectQuestionDialogOpen, setSelectQuestionDialogOpen] = useState(false)
    const [searchParams, setSearchParams] = useSearchParams()

    const queryClient = useQueryClient()

    const updateComponentResponseMutation = useMutation({
        mutationKey: ["UpdateGroupedSectionComponentResponse"],
        mutationFn: async (componentResponse: GroupedSectionComponentResponse) => {
            queryClient.cancelQueries({queryKey: ["UpdateGroupedSectionComponentResponse"], exact: true})
            return await InspectionChecklistResponseService.UpdateGroupedSectionComponentResponse(componentResponse)
        }
    })
    
    const handleComponentClick = () => {
        formik.setFieldValue(`${formikString}.passedAllQuestions`, !componentResponse.passedAllQuestions)
        if(!demoMode)
            updateComponentResponseMutation.mutate({...componentResponse, passedAllQuestions: !componentResponse.passedAllQuestions})
    }

    const handleJournalEntryClick = () => {
        //if there is already a journal entry then they would have already been asked which question it relates to
        if(componentResponse.journalEntryId == null){
            searchParams.set("componentId", componentResponse.component.id!.toString())
            setSearchParams(searchParams)
            setSelectQuestionDialogOpen(true)

        } else {
            setJournalEntryFormOpen(true)
        }
    }

    const onJournalEntryCreate = (journalEntryId: string) => {
        formik.setFieldValue(`${formikString}.journalEntryId`, journalEntryId)
        updateComponentResponseMutation.mutate({...componentResponse, journalEntryId: journalEntryId})
    }

    const handleQuestionSelect = (questionId: number) => {
        searchParams.set("relatedQuestionId", questionId.toString())
        setSearchParams(searchParams)
        setSelectQuestionDialogOpen(false)
        setJournalEntryFormOpen(true)
    }

    function SelectQuestionDialog({open, handleClose, componentLabel, handleSelect}: {open: boolean, handleClose: () => void, componentLabel: string, handleSelect: (questionId: number) => void}) {
        const questions = sectionQuestions.filter(sq => sq.type !== QuestionType.Alert)
        return (
            <Dialog
                open={open}
                onClose={handleClose}
            >
                <DialogTitle>{`Which question does this journal entry for ${componentLabel} relate to?`}</DialogTitle>
                <DialogContent>
                    <List>
                        {questions.map((question, index) => (
                            <ListItem key={index}>
                                <ListItemButton onClick={() => handleSelect(question.id)}>
                                    <ListItemText primary={`${question.number}. ${question.label}`}/>
                                    <ChevronRight sx={{marginLeft:"8px"}}/>
                                </ListItemButton>
                            </ListItem>
                        ))}
                    </List>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }

    return (
        <>
            <Stack>
                <ListItemButton onClick={handleComponentClick}>
                    <ListItemIcon>
                        <Checkbox
                            edge="start"
                            disableRipple
                            disableFocusRipple
                            checked={componentResponse.passedAllQuestions}
                        />
                    </ListItemIcon>
                    <ListItemText primary={componentResponse.component.label}/>
                </ListItemButton>
                <Button onClick={handleJournalEntryClick}>
                    {componentResponse.journalEntryId ? (
                        <Note/>
                    ) : (
                        <NoteOutlinedIcon color={componentResponse.passedAllQuestions ? "primary" : "error"}/>
                    )}
                    {componentResponse.passedAllQuestions ? (
                        <Typography variant='caption' sx={{display:"flex", alignItems:"flex-end", marginRight:"6.75px"}}>Optional Journal Entry</Typography>
                    ) : (
                        <Stack direction={"row"}>
                            <Typography variant='caption' sx={{display:"flex", alignItems:"flex-end"}} color={componentResponse.journalEntryId ? "primary" : "error"}>Required Journal Entry</Typography>
                            <span style={{color: 'red', verticalAlign: "top", marginLeft:"2px"}}>*</span>
                        </Stack>
                    )}
                    
                </Button>
            </Stack>
            <SelectQuestionDialog
                open={selectQuestionDialogOpen}
                handleClose={() => setSelectQuestionDialogOpen(false)}
                componentLabel={componentResponse.component.label}
                handleSelect={handleQuestionSelect}
            />
            <JournalEntryForm
                open={journalEntryFormOpen}
                handleClose={() => setJournalEntryFormOpen(false)}
                journalEntryId={componentResponse.journalEntryId}
                setMessageItem={setMessageItem}
                attraction={attraction}
                onCreate={onJournalEntryCreate}
            />
        </>
    )
}

export default ComponentResponse