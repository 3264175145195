import { Delete } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import { Stack, Autocomplete, TextField, TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { FormikProps } from 'formik'
import React, { useEffect, useState } from 'react'
import { MessageItem } from 'src/components/errorHandlingSnackbar'
import { NameDto } from 'src/dtos/NameDto.dto'
import { LearningMaterialTypeString, LearningMaterialType } from 'src/dtos/Training/TrainingGroup.dto'
import { ManualEvidenceItem, TrainingManualEvidenceDto } from 'src/dtos/Training/TrainingManualEvidence.dto'
import LearningDocumentService from 'src/Services/Training/LearningDocuments/LearningDocumentService'
import LearningItemService from 'src/Services/Training/LearningItemService'
import TrainingManualEvidenceService from 'src/Services/Training/ManualEvidenceService'

type Props = {
    id: string;
    formik: FormikProps<TrainingManualEvidenceDto>
    editable?: boolean;
    setMessageItem: React.Dispatch<React.SetStateAction<MessageItem>>
}
function TrainingContentTable({id, formik, editable, setMessageItem}:Props) {
    const [itemType, setItemType] = useState(LearningMaterialType.learningDocument)
    const [learningDocument, setLearningDocument] = useState<NameDto | null>(null)
    const [learningItem, setLearningItem] = useState<NameDto | null>(null)

    const queryClient = useQueryClient();
    
    //#region queries
    const learningDocsQuery = useQuery({
        queryKey: ["learningDocumentNames"],
        queryFn: async () => {
            return await LearningDocumentService.GetNames();
        }        
    })

    const learningItemsQuery = useQuery({
        queryKey: ["learningItemNames"],
        queryFn: async () => {
            return await LearningItemService.GetNames();
        }
    })
    //HandleQuery Errors
    useEffect(() => {
        if(learningDocsQuery.isError)
            setMessageItem({error: learningDocsQuery.error})

        if(learningItemsQuery.isError)
            setMessageItem({error: learningItemsQuery.error})
        
    }, [setMessageItem, learningDocsQuery.error, learningDocsQuery.isError, learningItemsQuery.error, learningItemsQuery.isError])
    
    const addItemMutation = useMutation({
        mutationFn: ({id, type, itemId}: {id: string, type: LearningMaterialType, itemId: string}) => {
            return TrainingManualEvidenceService.AddItem(id, type, itemId)
        },
        onSuccess: (data) => {
            setMessageItem({successMessage: "Item added successfully!"})

            //update values
            queryClient.setQueryData(["TrainingManualEvidence.Get", id], data.data)
            queryClient.invalidateQueries({queryKey: ["TrainingManualEvidence.GetPossibleTeamMembers", id], exact: true})
        },
        onError: (error) => {
            setMessageItem({error: error})
        }
    })

    const removeItemMutation = useMutation({
        mutationFn: ({id, item}: {id: string, item: ManualEvidenceItem}) => {
            return TrainingManualEvidenceService.RemoveItem(id, item)
        },
        onSuccess: (data) => {
            setMessageItem({successMessage: "Item removed successfully!"})

            //update values
            queryClient.setQueryData(["TrainingManualEvidence.Get", id], data.data)
            queryClient.invalidateQueries({queryKey: ["TrainingManualEvidence.GetPossibleTeamMembers", id], exact: true})
        },
        onError: (error) => {
            setMessageItem({error: error})
        }
    })
    //#endregion

    const handleAddItem = () => {
        const itemId = learningDocument?.id ?? learningItem?.id
        if (itemId)
            addItemMutation.mutate({id: id, type: itemType, itemId: itemId })
    }

    const handleRemoveItem = (item: ManualEvidenceItem) => {
        removeItemMutation.mutate({id: id, item: item})
    }
    const itemTypeNames: LearningMaterialType[] = [
        LearningMaterialType.learningDocument,
        LearningMaterialType.learningItem
    ]
    return (
        <>
            {editable && (
                <Stack direction={"row"} spacing={2} sx={{paddingTop:"0.5rem", paddingBottom: "0.5rem"}}>
                    <Autocomplete
                        id={`itemType`}
                        options={itemTypeNames}
                        size='small'
                        sx={{width: "14rem"}}
                        value={itemType}
                        disableClearable
                        getOptionLabel={(option) => LearningMaterialTypeString[option] ?? ""}
                        onChange={(e, value) => {
                            setItemType(value)
                            value === LearningMaterialType.learningDocument 
                                ? setLearningItem(null) 
                                : setLearningDocument(null)
                        }}
                        renderInput={(params) => 
                            <TextField {...params} label="Type"/>
                        }
                    />
                    {itemType === LearningMaterialType.learningDocument && (
                        <Autocomplete
                            id={`learningDocument`}
                            options={learningDocsQuery.data ?? []}
                            size='small'
                            sx={{width: "25rem"}}
                            value={learningDocument}
                            getOptionLabel={(option) => option.label ? option.label : ""}
                            autoHighlight
                            onChange={(e, value) => {
                                setLearningDocument(value)
                            }}
                            onKeyDown={(event) => {
                                if(event.key === "Enter")
                                    handleAddItem()
                            }}
                            renderInput={(params) => 
                                <TextField {...params} label="Learning Document"/>
                            }
                        />
                    )}
                    {itemType === LearningMaterialType.learningItem && (
                        <Autocomplete
                            id={`learningItem`}
                            options={learningItemsQuery.data ?? []}
                            size='small'
                            sx={{width: "25rem"}}
                            value={learningItem}
                            getOptionLabel={(option) => option.label ? option.label : ""}
                            onChange={(e, value) => {
                                setLearningItem(value)
                            }}
                            autoHighlight
                            onKeyDown={(event) => {
                                if(event.key === "Enter")
                                    handleAddItem()
                            }}
                            renderInput={(params) => 
                                <TextField {...params} label="Learning Item"/>
                            }
                        />
                    )}
                    <LoadingButton 
                        variant='contained'
                        onClick={handleAddItem}
                        loading={addItemMutation.isPending}
                    >
                        Add
                    </LoadingButton>
                </Stack>
            )}
            {formik.values.items.length > 0 && (
                <TableContainer component={Paper} sx={{width:"40rem"}}>
                    <Table size='small'>
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    Name
                                </TableCell>
                                <TableCell>
                                    Type
                                </TableCell>
                                {editable && (
                                    <TableCell/>
                                )}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {formik.values.items.map((item, index) => (
                                <TableRow key={index}>
                                    <TableCell>
                                        {item.label}
                                    </TableCell>
                                    <TableCell>
                                        {item.type}
                                    </TableCell>
                                    {editable && (
                                        <TableCell>
                                            <LoadingButton
                                                loading={removeItemMutation.isPending}
                                                onClick={() => handleRemoveItem(item)}
                                            >
                                                <Delete color='error'/>
                                            </LoadingButton>
                                        </TableCell>
                                    )}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}
        </>
    )
}

export default TrainingContentTable