import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { Checkbox, Stack, Tooltip } from '@mui/material'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { useMutation } from '@tanstack/react-query'
import React, { useState } from 'react'
import { MessageItem } from 'src/components/errorHandlingSnackbar'
import { PermittedInspectionDto, TeamMemberPermittedInspectionsDto } from 'src/dtos/Checklists/AttractionInspections/PermittedInspectionsDto.dto'
import { NameDto } from 'src/dtos/NameDto.dto'
import PermittedAttractionInspectionsService from 'src/Services/Checklists/Inspections/PermittedAttractionInspectionsService'
import dayjs from 'dayjs'
import { InfoOutlined } from '@mui/icons-material'
import { EmailToNameString } from 'src/Utils/helperFunc'
import { dateTimeOptions } from 'src/config/constants'

type Props = {
    teamMember: TeamMemberPermittedInspectionsDto, 
    inspection: NameDto;
    setTeamMemberPermittedInspections: React.Dispatch<React.SetStateAction<TeamMemberPermittedInspectionsDto[]>>
    setMessageItem: React.Dispatch<React.SetStateAction<MessageItem>>
}
function TeamMemberPermittedInspectionCell({teamMember, inspection, setTeamMemberPermittedInspections, setMessageItem}: Props) {
    const permittedInspection = teamMember.permittedInspections.find(pi => pi.inspectionChecklist.id === inspection.id)
    const [signedOffDate, SetSignedOffDate] = useState(permittedInspection?.dateSignedOff ?? null)
    const addPermittedInspectionMutation = useMutation({
        mutationFn: (permittedInspection: PermittedInspectionDto) => {
            return PermittedAttractionInspectionsService.AddPermittedInspection(teamMember.teamMember.id, permittedInspection)
        }, onSuccess: (data) => {
            setMessageItem({successMessage: `Permitted Inspection added to ${teamMember.teamMember.label} successfully`})
            setTeamMemberPermittedInspections(prevValues => 
                prevValues.map(value => (value.teamMember.id === teamMember.teamMember.id ? {...value, permittedInspections: data} : value))  
            );
        }, 
        onError: (error) => {
            setMessageItem({error: error})
        }
    })
    
    const removePermittedInspectionMutation = useMutation({
        mutationFn: (permittedInspection: PermittedInspectionDto) => {
            return PermittedAttractionInspectionsService.RemovePermittedInspection(teamMember.teamMember.id, permittedInspection)
        }, onSuccess: (data) => {
            setMessageItem({successMessage: `Permitted Inspection removed from ${teamMember.teamMember.label} successfully`})
            setTeamMemberPermittedInspections(prevValues => 
                prevValues.map(value => (value.teamMember.id === teamMember.teamMember.id ? {...value, permittedInspections: data} : value))  
            );
        }, 
        onError: (error) => {
            setMessageItem({error: error})
        }
    })
    
    
    const handleCheckboxClick = () => {
        if(signedOffDate == null){
            setMessageItem({errorMessage: "Must set signed off date before setting permitted inspection"})
            return
        }
        if(permittedInspection?.enabled === true){
            removePermittedInspectionMutation.mutate({...permittedInspection, dateSignedOff: signedOffDate})
        } else {
            addPermittedInspectionMutation.mutate({...PermittedAttractionInspectionsService.GetDefaultValues(inspection), dateSignedOff: signedOffDate})
        }
    }

    const pending = addPermittedInspectionMutation.isPending || removePermittedInspectionMutation.isPending
    return (
        <Stack direction={"row"}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker 
                    name="date"
                    format='DD/MM/YYYY'
                    value={signedOffDate ? dayjs(signedOffDate) : null}
                    onChange={(value) => {
                        if(value){
                            SetSignedOffDate(value.format("YYYY-MM-DD"))
                        } else {
                            SetSignedOffDate(null)
                        }
                    }}
                    slotProps={{
                        textField: {
                            size: "small",
                            variant: "standard",
                            label: "Signed Off Date"
                        }
                    }}
                    disabled={pending}
                    sx={{width:"8.75rem"}}
                />
            </LocalizationProvider>
            <div style={{marginLeft:"0.5rem", display:"flex", justifyContent:"center", alignItems:"center", width:"42px", height:"45px"}}>
                {pending ? 
                    <div className='loader'/>
                    :
                    <Checkbox
                        checked={permittedInspection && permittedInspection.enabled === true}
                        onClick={handleCheckboxClick}
                    />
                }
            </div>
            {permittedInspection?.modifiedBy && permittedInspection.modifiedOn && (
                <Tooltip 
                    title={
                        <>
                            Modified By: {EmailToNameString(permittedInspection.modifiedBy)} <br />
                            Modified On: {new Date(permittedInspection.modifiedOn).toLocaleDateString(undefined, dateTimeOptions)}
                        </>
                    } 
                    sx={{cursor:"pointer"}}
                >
                    <InfoOutlined color='primary'/>
                </Tooltip>
            )}

        </Stack>
    )
}

export default TeamMemberPermittedInspectionCell