import { Stack, TableCell, TableRow } from '@mui/material'
import React from 'react'
import { dateTimeOptions } from 'src/config/constants';
import { LearningDocumentPoint } from 'src/dtos/Training/LearningDocument.dto';
import { LearningDocumentPointResponse } from 'src/dtos/Training/LearningDocumentResponse.dto';
import { EmailToNameString } from 'src/Utils/helperFunc';
import Subpoint from './Subpoint';
import { PointIcon } from 'src/pages/User Site/TrainingDocuments/TrainingDocumentComponents/Point/Components/PointIcon';
import { createUnorderedListMarkup } from 'src/pages/User Site/TrainingDocuments/TrainingDocumentComponents/Point/Components/CreateUnorderedListMarkup';

type Props = {
    index: number;
    point: LearningDocumentPoint;
    pointResponse: LearningDocumentPointResponse;
    allIconlessPoints?: boolean;
}
function CommonPoint({index, point, pointResponse, allIconlessPoints}: Props) {
    
    return (
        <>
            <TableRow>
                <TableCell>
                        <Stack direction={"row"} sx={{display:"flex", alignItems:"center"}}>
                            {allIconlessPoints !== true && (
                                <PointIcon pointType={point.type}/>
                            )}
                            <Stack>
                                {`${index+1}. ${point.label}`}
                                <div dangerouslySetInnerHTML={createUnorderedListMarkup(point.unorderedList)}/>
                            </Stack>
                        </Stack>
                </TableCell>
                <TableCell>
                    {pointResponse.checked ? "Yes" : "No"}
                </TableCell>
                <TableCell>
                    {EmailToNameString(pointResponse.answeredBy)}
                </TableCell>
                <TableCell>
                    {pointResponse.timeAnswered && new Date(pointResponse.timeAnswered).toLocaleDateString(undefined, dateTimeOptions)}
                </TableCell>
            </TableRow>    
            {point.subpoints.map((subpoint, subpointIndex) => (
                <Subpoint
                    key={subpointIndex}
                    index={subpointIndex}
                    pointIndex={index}
                    subpoint={subpoint}
                    subpointResponse={pointResponse.subpointResponses[subpointIndex]}
                    allIconless={allIconlessPoints}
                />
            ))}
        </>
    )
}

export default CommonPoint