import { Delete } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Autocomplete, InputBase, List, ListItem, Pagination, Paper, Stack, TextField, Typography } from '@mui/material';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import React, { useEffect, useState } from 'react'
import { MessageItem } from 'src/components/errorHandlingSnackbar'
import { NameDto } from 'src/dtos/NameDto.dto';
import TrainingManualEvidenceService from 'src/Services/Training/ManualEvidenceService';
import SearchIcon from '@mui/icons-material/Search';

type Props = {
    id: string;
    editable: boolean
    setMessageItem: React.Dispatch<React.SetStateAction<MessageItem>>
}
function AffectedTeamMembers({id, editable, setMessageItem}: Props) {
    const [selectedTeamMember, setSelectedTeamMember] = useState<NameDto | null>(null)
    const [searchTerm, setSearchTerm] = useState("")
    const [page, setPage] = useState(1)
    const pageSize = 10

    const queryClient = useQueryClient();

    //#region queries
    const teamMembersQuery = useQuery({
        queryKey: ["TrainingManualEvidence.GetTeamMembers", id],
        queryFn: async () => {
            return await TrainingManualEvidenceService.GetTeamMembers(id)
        }
    })
    const possibleTeamMembersQuery = useQuery({
        queryKey: ["TrainingManualEvidence.GetPossibleTeamMembers", id],
        queryFn: async () => {
            return await TrainingManualEvidenceService.GetPossibleTeamMembers(id)
        }
    })

    //Handle errors
    useEffect(() => {
        if(possibleTeamMembersQuery.isError)
            setMessageItem({error: possibleTeamMembersQuery.error})
    }, [setMessageItem, possibleTeamMembersQuery.isError, possibleTeamMembersQuery.error])

    const addTeamMemberMutation = useMutation({
        mutationFn: ({id, teamMember}: {id: string, teamMember: NameDto}) => {
            return TrainingManualEvidenceService.AddTeamMember(id, teamMember)
        },
        onSuccess: (data) => {
            setMessageItem({successMessage: "Team Member added successfully!"})
            
            //clear autocomplete value
            setSelectedTeamMember(null)
            //update values
            queryClient.setQueryData(["TrainingManualEvidence.GetTeamMembers", id], data.data)
        },
        onError: (error) => {
            setMessageItem({error: error})
        }
    })

    const removeTeamMemberMutation = useMutation({
        mutationFn: ({id, teamMember}: {id: string, teamMember: NameDto}) => {
            return TrainingManualEvidenceService.RemoveTeamMember(id, teamMember)
        },
        onSuccess: (data) => {
            setMessageItem({successMessage: "Team Member removed successfully!"})

            //update values
            queryClient.setQueryData(["TrainingManualEvidence.GetTeamMembers", id], data.data)
        },
        onError: (error) => {
            setMessageItem({error: error})
        }
    })
    //#endregion
    
    const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
        setPage(value); 
    };

    const handleRemoveTeamMember = (teamMember: NameDto) => {
        removeTeamMemberMutation.mutate({id, teamMember})
    }

    const handleAddTeamMember = () => {
        if(selectedTeamMember)
            addTeamMemberMutation.mutate({id, teamMember: selectedTeamMember})
    }

    const teamMembers = teamMembersQuery.data?.filter(tm => tm.label.toLowerCase().includes(searchTerm.toLowerCase())) ?? [];
    const totalPages = Math.ceil(teamMembers.length / pageSize);
    const paginatedTeamMembers = teamMembers.slice((page - 1) * pageSize, page * pageSize);

    return (
        <div style={{width:"30rem"}}>
            {editable && (
                <Stack direction={"row"} spacing={2}>
                    <Autocomplete
                        id={"teamMember"}
                        options={possibleTeamMembersQuery.data?.filter(tm => !(teamMembersQuery.data??[]).map(tm => tm.id).includes(tm.id)) ?? []}
                        size="small"
                        sx={{width: "25rem"}}
                        value={selectedTeamMember}
                        autoHighlight
                        onKeyDown={(event) => {
                            if(event.key === "Enter")
                                handleAddTeamMember()
                        }}
                        getOptionLabel={(option) => option.label ? option.label : ""}
                        onChange={(_e, value) => {
                            setSelectedTeamMember(value)
                        }}
                        renderInput={(params) => 
                            <TextField {...params} label="Team Member"/>
                        }
                    />

                    <LoadingButton 
                        variant='contained'
                        loading={addTeamMemberMutation.isPending}
                        onClick={handleAddTeamMember}
                    >
                        Add
                    </LoadingButton>
                </Stack>    
            )}
            {(teamMembersQuery.data?.length?? 0) > 0 && (
                //Search bar
                <Paper
                    component="form"
                    sx={{p: '2px 4px', marginBottom:"0.5rem", marginTop:"0.5rem", display: 'flex', alignItems: 'center' }}
                >
                    <SearchIcon color="disabled"/>
                    <InputBase
                        sx={{ml: 1, flex: 1}}
                        placeholder='Search'
                        value={searchTerm}
                        onChange={e => setSearchTerm(e.target.value)}
                    />
                </Paper>
            )}
            {(teamMembersQuery.data?.length??0) > 0 && (
                <>
                    <List dense sx={{marginTop:"0.5rem"}} component={Paper}>
                        {paginatedTeamMembers.map((teamMember) => (
                            <ListItem
                                key={teamMember.id}
                                secondaryAction={
                                    <>
                                        {
                                            editable && (
                                                <LoadingButton 
                                                    onClick={() => handleRemoveTeamMember(teamMember)}
                                                    loading={removeTeamMemberMutation.isPending}
                                                >
                                                    {!removeTeamMemberMutation.isPending && (
                                                        <Delete color='error'/>
                                                    )}
                                                </LoadingButton>
                                            )
                                        }
                                    </>
                                }
                            >
                                {teamMember.label}
                            </ListItem>
                        ))}
                    </List>
                    
                    <Stack direction={"row"} sx={{display:"flex", justifyContent:"space-between"}}>
                        <Pagination
                            count={totalPages}
                            page={page}
                            onChange={handlePageChange}
                            sx={{marginTop:"0.5rem"}}
                        />
                        <Typography sx={{padding:"5px"}}>{`Total: ${teamMembers.length}`}</Typography>
                    </Stack>
                 
                </>
            )}
        </div>
    )
}

export default AffectedTeamMembers