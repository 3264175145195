import { Box, InputBase, List, ListItem, Paper, Stack, Typography } from '@mui/material'
import React, { useState } from 'react'
import { Draggable, DraggableProvided, DraggableStateSnapshot, Droppable } from 'react-beautiful-dnd'
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { NameDto } from 'src/dtos/NameDto.dto';
import SearchIcon from '@mui/icons-material/Search';


type Props = {
    droppableId: string;
    roles: NameDto[];
}
function AssignableRolesList({droppableId, roles}: Props) {
    const [searchTerm, setSearchTerm] = useState("")
    const getRenderItem = (items: NameDto[]) =>
        (provided: DraggableProvided, snapshot: DraggableStateSnapshot, rubric: any) => {
            const role = items[rubric.source.index];
            return (
            <ListItem
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                style={provided.draggableProps.style}
                sx={snapshot.isDragging ? { background: 'rgb(235,235,235)' } : {}}
            >
                <Stack direction="row" alignItems="center">
                    <DragIndicatorIcon />
                    <Typography sx={{userSelect:"none"}}>{role.label}</Typography>
                </Stack>
            </ListItem>
            );
        };
    return (
        <Box component={Paper} sx={{height:"fit-content", paddingRight:"4px" }}>
            <Typography variant='h5' sx={{paddingTop:"1rem", textAlign:"center"}}>
                Assignable Roles
            </Typography>
            <Paper
                component="form"
                sx={{p: '2px 1px', marginLeft:"1rem", display: 'flex', alignItems: 'center', width:"13rem" }}
            >
                <SearchIcon color="disabled"/>
                <InputBase
                    sx={{ml: 1, flex: 1}}
                    placeholder='Search'
                    value={searchTerm}
                    onChange={e => setSearchTerm(e.target.value)}
                />

            </Paper>
            <Droppable 
                droppableId={droppableId}
                type={"roles"}
                renderClone={getRenderItem(roles)}
            >
                {(provided, snapshot) => (
                    <List ref={provided.innerRef} {...provided.droppableProps} sx={{maxHeight:"calc(100vh - 12.9rem)", width:"15rem", overflowY:"scroll"}}>
                        {roles.filter(role => role.label.toLowerCase().includes(searchTerm.toLowerCase())).map((role, index) => {
                            const shouldRenderClone = snapshot.draggingFromThisWith === `AssignableRoles_${role.id}`
                            return (
                                <React.Fragment key={role.id}>
                                    {shouldRenderClone ? (
                                        <ListItem>
                                                <div style={{ display: "flex", alignItems: "center", marginRight: '8px' }}>
                                                    <DragIndicatorIcon/>
                                                    <Typography sx={{userSelect:"none"}}>{role.label}</Typography>
                                                </div>
                                        </ListItem>
                                    ) : (
                                        <Draggable
                                            draggableId={`${droppableId}_${role.id}`}
                                            index={index}
                                        >
                                            {(provided, snapshot) => (
                                                <>
                                                    <ListItem
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        sx={snapshot.isDragging ? {background: 'rgb(235,235,235)' } : {}}
                                                        style={{transform: "none !important"}}
                                                    >
                                                        <div
                                                            {...provided.dragHandleProps}
                                                            style={{ display: "flex", alignItems: "center", marginRight: '8px' }}
                                                        >
                                                            <DragIndicatorIcon/>
                                                            <Typography sx={{userSelect:"none"}}>{role.label}</Typography>
                                                            
                                                        </div>
                                                    </ListItem>
                                                </>
                                            )}
                                        </Draggable>
                                    )}
                                </React.Fragment>
                            )
                        })}
                        
                        {/* {provided.placeholder} */}
                    </List>
                )}
            </Droppable>
        </Box>
    )
}

export default AssignableRolesList