import { Stack, Typography } from '@mui/material'
import React from 'react'
import { dateOptions, dateTimeOptions } from 'src/config/constants'
import { TrainingMemoDto, TrainingMemoTypeString } from 'src/dtos/Training/TrainingMemo.dto'
import { EmailToNameString, sanitizeHtml } from 'src/Utils/helperFunc'
type Props = {
    trainingMemo: TrainingMemoDto
}
function TrainingMemoMemorandum({trainingMemo}: Props) {
    
    const createMarkup = (content: string) => {        
        return {
            __html: sanitizeHtml(content)
        }
    }

    const trainingGroupsNum = trainingMemo.affectedTrainingGroups.length
    return (
        <Stack>
            <Typography variant='h4'>
                {trainingMemo.label}
            </Typography>
            <Typography variant='caption'>
                {`Issued by: ${EmailToNameString(trainingMemo.issuedBy)} On: ${trainingMemo.dateIssued && new Date(trainingMemo.dateIssued).toLocaleDateString(undefined, dateTimeOptions)}`}
            </Typography>
            <Typography variant='h6'>
                Attention
            </Typography>
            <Typography>
                {trainingMemo.affectedTrainingGroups.map((atg, index) => " " + atg.label + 
                    (trainingGroupsNum > 1 
                        ? trainingGroupsNum - 1 === index + 1 
                        ? " and " 
                        : trainingGroupsNum !== index + 1 
                        ? ", " 
                        : "" 
                        : ""
                    ))} Team Members
            </Typography>
            <Typography variant='h6'>
                Date Effective
            </Typography>
            <Typography>
                {trainingMemo.dateEffective && new Date(trainingMemo.dateEffective).toLocaleDateString(undefined, dateOptions)}
            </Typography>
            <Typography variant='h6'>
                Type of Change
            </Typography>
            <Typography>
                {TrainingMemoTypeString[trainingMemo.type]}
            </Typography>
            <Typography variant='h6'>
                Change Overview
            </Typography>
            <Typography>
                {`${trainingMemo.changeOverview}`}
            </Typography>
            <Typography variant='h6'>
                Change Detail
            </Typography>
            {trainingMemo.changeDetail && <div dangerouslySetInnerHTML={createMarkup(trainingMemo.changeDetail)}/>}
            <Typography variant='h6'>
                Intent of Change
            </Typography>
            <Typography >
                {trainingMemo.intentOfChange}
            </Typography>
            {trainingMemo.sopReference && (
                <>
                    <Typography variant='h6'>
                        SOP Reference
                    </Typography>
                    <img
                        src={trainingMemo.sopReference.fileUrl}
                        alt={trainingMemo.sopReference.fileName}
                        loading='lazy'
                        style={{
                            width: "100%",
                            objectFit: 'cover',
                        }}
                    />
                </>
            )}
        </Stack>  
    )
}

export default TrainingMemoMemorandum