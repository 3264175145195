import { InspectionChecklistVersion, QuestionType, QuestionDto, InspectionChecklistSection, ChecklistVersionName } from "src/dtos/Checklists/MaintenanceChecklist.dto";
import { Status } from "src/dtos/Statuses";
import { ListParameters, SortDirection } from "../../ListParameters";
import { ListResponseDto } from "src/dtos/ListResponse.dto";
import axios, { AxiosResponse } from "axios";
import { GetAuthorisation } from "src/Utils/authUtils";

const apiBaseUrl = process.env.REACT_APP_API_BASE_URL + "/InspectionChecklistVersion";
const debug = process.env.REACT_APP_DEBUG;

const emptyData: ListResponseDto = {
    data: [],
    totalRecords: 0
}

export default class InspectionChecklistVersionService {
    static GetDefaultVersionValues(){
        const defaultInspectionChecklistVersionValues: InspectionChecklistVersion = {
            id: "00000000-0000-0000-0000-000000000000",
            versionLabel: "",
            versionDescription: "",
            status: Status.Draft,
            forceWorkflow: true,
            repeatingComponentSet: null,
            repeatType: null,
            userSelectedComponent: null,
            enabled: true,
            inspectionChecklistId: "00000000-0000-0000-0000-000000000000",
            sections: []
        }
        return defaultInspectionChecklistVersionValues
    }

    static GetDefaultQuestionValues(){
        const questionValues: QuestionDto  = {
            id: 0,
            label: "",
            number: 1,
            type: QuestionType.YesOrNo,
            relatedComponent: null,
            sortOrder: 0
        }
        return questionValues;
    }

    static GetDefaultSectionValues(){
        const sectionValues: InspectionChecklistSection = {
            id: 0,
            label: "",
            sublabel: "",
            questions: [this.GetDefaultQuestionValues()],
            repeatingComponentSet: null,
            sectionRepeatType: null,
            sortOrder: 0
        }
        return sectionValues;
    }

    static async Get(id: string): Promise<InspectionChecklistVersion> {
        try {
            const authorization = await GetAuthorisation();
            const response: AxiosResponse<InspectionChecklistVersion> = await axios.get<InspectionChecklistVersion>(`${apiBaseUrl}/get/${id}`, {
                headers: {
                    Authorization: authorization
                },
            });
            return response.data;
        } catch (error: any) {
            debug && console.log(error);
            throw error;
        }
    }

    static async GetInspection(id: string): Promise<InspectionChecklistVersion> {
        try {
            const authorization = await GetAuthorisation();
            const response: AxiosResponse<InspectionChecklistVersion> = await axios.get<InspectionChecklistVersion>(`${apiBaseUrl}/GetInspection/${id}`, {
                headers: {
                    Authorization: authorization
                }
            })
            return response.data;
        } catch (error: any) {
            debug && console.log(error);
            throw error;
        }
    }

    static async GetList(inspectionChecklistId: string, listParam?: ListParameters, searchTerm?: string, status?: Status): Promise<ListResponseDto>{
        try {
            const authorization = await GetAuthorisation();
            const response: AxiosResponse<ListResponseDto> = await axios.get<ListResponseDto>(`${apiBaseUrl}/GetList/${inspectionChecklistId}`, {
                headers: {
                    Authorization: authorization
                },
                params: {
                    page: listParam?.page,
                    pageSize: listParam?.pageSize,
                    sortBy : listParam?.sortBy,
                    sortDirection: listParam?.sortDirection === SortDirection.Ascending ? 0 : listParam?.sortDirection === SortDirection.Descending ? 1 : undefined,
                    searchTerm: searchTerm,
                    inspectionChecklistId: inspectionChecklistId,
                    status: status,                
                }
            });
            return response.data;
        } catch (error: any) {
            if(error.response !== undefined){
                if(error.response.status != null && error.response.status === 404){
                    return emptyData
                }
            }
            debug && console.log(error)
            throw error;
        }
    }

    static async GetNames(inspectionChecklistId: string): Promise<ChecklistVersionName[]>{
        try {
            const authorization = await GetAuthorisation();
            const response: AxiosResponse<ChecklistVersionName[]> = await axios.get<ChecklistVersionName[]>(`${apiBaseUrl}/GetNames/${inspectionChecklistId}`, {
                headers: {
                    Authorization: authorization
                },
            });
            return response.data
        } catch (error: any) {
            debug && console.log(error)
            throw error;
        }
    }

    static async GetCopy(versionId: string): Promise<InspectionChecklistVersion>{
        try {
            const authorization = await GetAuthorisation();
            const resposne: AxiosResponse<InspectionChecklistVersion> = await axios.get<InspectionChecklistVersion>(`${apiBaseUrl}/GetCopy/${versionId}`, {
                headers: {
                    Authorization: authorization
                },
            });
            return resposne.data;
        } catch (error: any) {
            debug && console.log(error)
            throw error;
        }
    }

    static async Create(checklistVersionData: InspectionChecklistVersion): Promise <AxiosResponse> {
        try {
            const authorization = await GetAuthorisation();
            const response: AxiosResponse = await axios.post<AxiosResponse>(`${apiBaseUrl}/Create`, checklistVersionData,{
                headers: {
                    Authorization: authorization
                },
            });
            return response
        } catch (error: any) {
            debug && console.log(error)
            throw error;
        }
    }

    static async Update(id: string, checklistVersionData: InspectionChecklistVersion): Promise<AxiosResponse> {
        try {
            const authorization = await GetAuthorisation();
            const response: AxiosResponse = await axios.put<AxiosResponse>(`${apiBaseUrl}/Update/${id}`, checklistVersionData, {
                headers: {
                    Authorization: authorization
                },
            });
            return response;
        } catch (error: any) {
            debug && console.log(error);
            throw error;
        }
    }

    static async Publish(id: string) : Promise<AxiosResponse> {
        try {
            const authorization = await GetAuthorisation();
            const response: AxiosResponse = await axios.put<AxiosResponse>(`${apiBaseUrl}/Publish/${id}`, null, {
                headers: {
                    Authorization: authorization
                },
            });
            return response
        } catch (error: any) {
            debug && console.log(error)
            throw error;
        }
    }

    static async Unpublish(id: string) : Promise<AxiosResponse> {
        try {
            const authorization = await GetAuthorisation();
            const response: AxiosResponse = await axios.put<AxiosResponse>(`${apiBaseUrl}/Unpublish/${id}`, null, {
                headers: {
                    Authorization: authorization
                },
            });
            return response
        } catch (error: any) {
            debug && console.log(error)
            throw error;
        }
    }

}