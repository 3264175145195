import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import React from 'react'
import { LearningDocumentSubtopic } from 'src/dtos/Training/LearningDocument.dto'
import { LearningDocumentSubtopicResponse } from 'src/dtos/Training/LearningDocumentResponse.dto'
import Point from './Point'
import { sanitizeHtml } from 'src/Utils/helperFunc'

type Props = {
    initialSectionContent: string,
    preparationChecklist?: LearningDocumentSubtopic
    preparationChecklistResponse?: LearningDocumentSubtopicResponse
}

function GeneratePreparationSectionContent({content, checklist, preparationChecklistResponse}: {content: string, checklist?: LearningDocumentSubtopic, preparationChecklistResponse?: LearningDocumentSubtopicResponse}) {

    const checklistRegex = /<preparationchecklist\b[^>]*>[\s\S]*?<\/preparationchecklist>/gi;
    
    const createMarkup = (content: string) => {        
        return {
            __html: sanitizeHtml(content)
        }
    }

    if(checklistRegex.test(content) && preparationChecklistResponse)
    {
        const contentItems = content.split(checklistRegex);
        return (
            <>
                {contentItems.map((contentItem, index) => (
                    <React.Fragment key={index}>
                        <div dangerouslySetInnerHTML={createMarkup(contentItem)}/>
                        {index !== contentItems.length-1 && checklist && (
                            <TableContainer component={Paper} key={index}>
                                <Table size="small">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell sx={{padding:"0"}} colSpan={4}>
                                                <Typography 
                                                    variant='h6' 
                                                    sx={{
                                                        padding:"0.5rem",
                                                        paddingLeft:"1rem",
                                                        borderTopLeftRadius:"6px",
                                                        borderTopRightRadius: "6px"
                                                    }}>
                                                        {checklist.label}
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>
                                                Question
                                            </TableCell>
                                            <TableCell sx={{width:"6rem"}}>
                                                Checked
                                            </TableCell>
                                            <TableCell sx={{width:"10rem"}}>
                                                Checked By
                                            </TableCell>
                                            <TableCell sx={{width:"8rem"}}>
                                                Time Checked
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {checklist.points.map((point, pointIndex) => (
                                            <Point
                                                key={pointIndex}
                                                index={pointIndex}
                                                point={point}
                                                pointResponse={preparationChecklistResponse.pointResponses[pointIndex]}
                                                allIconlessPoints={true}
                                            />
                                        ))}
                                    </TableBody>
                                </Table>

                            </TableContainer>
                        )}

                    </React.Fragment>
                ))}
            </>
        )
    }
    return (
        <div dangerouslySetInnerHTML={createMarkup(content)}/>
    )
}
function PreparationSection({initialSectionContent, preparationChecklist, preparationChecklistResponse}: Props) {
    return (
        <div style={{padding:"1rem"}}>
            <Typography variant="h5">Preparation</Typography>
            <GeneratePreparationSectionContent
                content={initialSectionContent}
                checklist={preparationChecklist}
                preparationChecklistResponse={preparationChecklistResponse}
            />
        </div>
    )
}

export default PreparationSection