import React, { useRef } from 'react'
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { Button, Stack, Typography } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { MessageItem } from '../errorHandlingSnackbar';
import { AxiosResponse } from 'axios';
import { FileMetadata } from 'src/dtos/FileMetadata.dto';
import { LoadingButton } from '@mui/lab';
import { DeleteForever } from '@mui/icons-material';

type Props = {
    acceptedFileTypes?: string;
    addFileService: (file: File) => Promise<AxiosResponse<FileMetadata>>
    deleteFileService: (fileId: string) => Promise<AxiosResponse>
    file?: FileMetadata;
    setFile: (fileMetaData: FileMetadata | null) => void
    setMessageItem: React.Dispatch<React.SetStateAction<MessageItem>>
    disabled?: boolean;
}
function SingleFileUpload({acceptedFileTypes, file, setFile, addFileService, deleteFileService, setMessageItem, disabled}:Props) {
    const hiddenFileInput = useRef<HTMLInputElement>(null);
    
    //#region quieries
    const addFileMutation = useMutation({
        mutationFn: async (fileData: File) => {
            return await addFileService(fileData)
        },
        onSuccess: (response) => {
            const fileMetadata = response.data
            setMessageItem({successMessage: "File added successfully!"})
            setFile(fileMetadata)
        },
        onError: (error) => {
            setMessageItem({error: error})
        }
    })

    const deleteFileMutation = useMutation({
        mutationFn: async (fileId: string) => {
            return await deleteFileService(fileId)
        },
        onSuccess: () => {
            setMessageItem({successMessage: "File deleted successfully!"})
            setFile(null)
        },
        onError: (error) => {
            setMessageItem({error: error})
        }
    })
    //#endregion

    const handleChooseFileClick = () => {
        hiddenFileInput.current && hiddenFileInput.current.click();
    }

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if(file && file.id){
            deleteFileMutation.mutate(file.id)
        }
        const files = event.target.files
        if (files) {
            const newFiles = Array.from(files);
            const newFile = newFiles[0]
            
            addFileMutation.mutate(newFile);
        }
    }

    const handleDeleteCLick = () => {
        if(file && file.id){
            deleteFileMutation.mutate(file.id)
        }
    }

    return (
        <div>

            {addFileMutation.isPending ? (
                <div className='loader'/>
            ) : (
                <>
                    <Stack direction={"row"} spacing={2}>
                        <Button 
                            onClick={handleChooseFileClick}
                            disabled={disabled}
                        >
                            <Stack sx={{display:"flex", alignItems:"center"}}>
                                <AttachFileIcon fontSize='large'/>   
                                <Typography variant='caption'>Choose file</Typography>
                            </Stack>
                        </Button>
                        {file && (
                            <div style={{display:"flex", justifyContent:"center"}}>
                                <LoadingButton 
                                    onClick={handleDeleteCLick}
                                    loading={deleteFileMutation.isPending}
                                    disabled={disabled}
                                >
                                    <DeleteForever/>
                                    Delete
                                </LoadingButton>
                            </div>
                        )}
                    </Stack>
                    <input
                        type="file"
                        accept={acceptedFileTypes}
                        ref={hiddenFileInput}
                        style={{display:"none"}}
                        onChange={handleFileChange}   
                    />
                </>
            )}
            {file && (
                <img
                    src={file.fileUrl}
                    alt={file.fileName}
                    loading='lazy'
                    style={{
                        width: "100%",
                        objectFit: 'cover',
                    }}
                />
            )}

        </div>
    )
}

export default SingleFileUpload