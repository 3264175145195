import { BottomNavigation, BottomNavigationAction, Paper } from '@mui/material'
import ChecklistIcon from '@mui/icons-material/Checklist';
import FeedOutlinedIcon from '@mui/icons-material/FeedOutlined';
import React from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { Article } from '@mui/icons-material';


type Props = {
    demoMode?: boolean
    journalAttractionId?: string;
    documentationUrl?: string;
}
function InspectionBottomNavigation({journalAttractionId, documentationUrl, demoMode = false}: Props) {
    const navigate = useNavigate()
    const {attractionId} = useParams()

    const redirectJournal = () => {
        if(demoMode)
            return
        if (journalAttractionId && !window.location.pathname.includes(journalAttractionId)) {
            navigate(
                `${window.location.pathname}${
                    window.location.pathname[window.location.pathname.length - 1] === "/"
                        ? ""
                        : "/"
                }${journalAttractionId}`
            );
        }
    };

    const redirectChecklist = () => {
        if (attractionId && window.location.pathname.includes(attractionId)) {
            navigate(
                `${window.location.pathname.replace(`/${attractionId}`, "")}`
            );
        }
    };

    const handleNavigate = (buttonIndex: number) => {
        if (buttonIndex === 0) {
            redirectJournal();
        } else {
            redirectChecklist();
        }
    };

    return (
        <Paper
            sx={{ position: "fixed", bottom: 0, left: 0, right: 0 }}
            elevation={3}

        >
            <BottomNavigation showLabels onChange={(_, value) => handleNavigate(value)}>
                <BottomNavigationAction
                    label="Journal"
                    icon={<FeedOutlinedIcon />}
                />
                <BottomNavigationAction
                    label="Checklist"
                    icon={<ChecklistIcon />}
                />
                {documentationUrl && (
                    <BottomNavigationAction
                        label="Documentation"
                        icon={<Article />}
                        component="a"
                        href={documentationUrl}
                        target="_blank"
                        rel="noreferrer"
                    />

                )}
            </BottomNavigation>
        </Paper>
    );
}

export default InspectionBottomNavigation