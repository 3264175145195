
export interface CompletedTraining {
    label: string;
    completionDate: Date;
    status: CompletedLearningStatus;
    type: CompletedLearningType;
    expiry?: Date;
    currencyStatus: CurrencyStatus;
    trainingSessionId?: string;
    learningDocumentResponseId?: string;
    learningItemResponseId?: string;
    trainingManualEvidenceId?: string
}

export enum CompletedLearningType {
    trainingDocumentNTM,
    trainingDocumentRTM,
    competencyDocument,
    attendanceSheet,
    onlineLearning
}

export enum CompletedLearningStatus {
    passed,
    failed
}

export enum CurrencyStatus {
    current,
    superseded,
    expired
}

export const CurrencyStatusString = [
    "Current",
    "Superseded",
    "Expired"
]

export const CompletedLearningStatusString: string[] = [
    "Completed Successfully",
    "Unsuccessful Attempt"
]

export interface TeamMemberCompletedTraining {
    completedTraining: CompletedTraining[];
    financialYears: string[]; //years the team member has completed training in
}