import React, { useState } from 'react'
import { Checkbox, IconButton, Paper, Skeleton, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import { useNavigate, useParams } from 'react-router-dom';
import LearningDocumentVersionService from 'src/Services/Training/LearningDocuments/LearningDocumentVersionService';
import ErrorHandlingSnackbar, { MessageItem } from 'src/components/errorHandlingSnackbar';
import TrainingBottomNavigation from 'src/pages/User Site/TrainingDocuments/TrainingBottomNavigation/TrainingBottomNavigation';
import { useMutation, useQuery } from '@tanstack/react-query';
import { guidRegex } from 'src/Utils/helperFunc';
import LearningDocumentResponseService from 'src/Services/Training/LearningDocuments/LearningDocumentResponseService';
import TrainingSessionService from 'src/Services/Training/TrainingSessionService';
import { AttendeeType } from 'src/dtos/Training/AttendanceSheetResponse.dto';
import { useFormik } from 'formik';
import TraineeRow from './Components/TraineeRow';
import { LoadingButton } from '@mui/lab';
import LearningDocumentService from 'src/Services/Training/LearningDocuments/LearningDocumentService';
import TrainerRow from './Components/TrainerRow';
import { LearningDocumentResponseStatus } from 'src/dtos/Training/LearningDocumentResponse.dto';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import assets from 'src/assets';
import { LearningDocumentType } from 'src/dtos/Training/LearningDocument.dto';
import textContent from 'src/config/textContent';

function AttendanceSheet() {

    const [messageItem, setMessageItem] = useState<MessageItem>({})
    const {id, responseId} = useParams(); //id is trainingSessionId
    const navigate = useNavigate();

    //#region queries
    const documentResponseQuery = useQuery({
        queryKey: ["documentResponse", responseId],
        queryFn: async () => {
            if(responseId && guidRegex.test(responseId)){
                return await LearningDocumentResponseService.Get(responseId)
            }
            return null
        }
    })

    const trainingSessionQuery = useQuery({
        queryKey: ["trainingSession", id],
        queryFn: async () => {
            if(id && guidRegex.test(id)){
                return await TrainingSessionService.Get(id)
            }
            return null
        }
    })

    const learningDocumentVersionQuery = useQuery({
        queryKey: ["learningDocumentVersion", documentResponseQuery.data?.learningDocumentVersionId],
        queryFn: async () => {
            if(documentResponseQuery.data){
                return await LearningDocumentVersionService.Get(documentResponseQuery.data.learningDocumentVersionId)
            }
            return null
        }
    })

    const learningDocumentQuery = useQuery({
        queryKey: ["learningDocument", learningDocumentVersionQuery.data?.learningDocumentId],
        queryFn: async () => {
            if(learningDocumentVersionQuery.data){
                return await LearningDocumentService.Get(learningDocumentVersionQuery.data.learningDocumentId)
            }
            return null
        }
    })

    const initialLoading = trainingSessionQuery.isLoading || documentResponseQuery.isLoading || learningDocumentVersionQuery.isLoading

    const formik = useFormik({
        enableReinitialize: true, 
        validateOnChange: false,
        initialValues: documentResponseQuery.data?.attendanceSheetResponse ?? LearningDocumentResponseService.GetDefaultValues().attendanceSheetResponse,
        onSubmit: () => {
            
        }
    })

    const submitMutation = useMutation({
        mutationFn: (id: string) => {
            return LearningDocumentResponseService.SubmitDocument(id)
        },
        onSuccess: () => {
            setMessageItem({successMessage: "Document Submitted Successfully"})
        },
        onError: (error) => {
            setMessageItem({error: error})
        }
    })
    
    //#endregion
     
    const handleSubmitClick = () => {
        if(documentResponseQuery.data)
            submitMutation.mutate(documentResponseQuery.data.id)
    }

    const handleBackClick = () => {
        navigate({ pathname: window.location.pathname.replace(new RegExp(`/${responseId}/Attendance`), '') });
    }
    
    const trainees = formik.values.attendees.filter(a => a.type === AttendeeType.Trainee)
    const trainers = formik.values.attendees.filter(a => a.type === AttendeeType.Trainer)

    const RetrainTrainees = () => {
        const [teamMemberIdsToRetrain, setTeamMembersToRetrain] = useState<string[]>([])

        const retrainMutation = useMutation({
            mutationFn: async (responseId: string) => {
                return await LearningDocumentResponseService.RetrainDocument(responseId, teamMemberIdsToRetrain)
            },
            onSuccess: (data) => {
                const newSessionId = data.data
                navigate(`/user/training/sessions/${newSessionId}`)
            },
            onError: (error) => {
                setMessageItem({error: error})
            }
        })

        const handleRetrainClick = () => {
            if(teamMemberIdsToRetrain.length === 0){
                setMessageItem({errorMessage: "No Team Members Selected to Retrain!"})
                return
            }
            if(responseId){
                retrainMutation.mutate(responseId)
            }
        }

        const handleCheckboxClick = (teamMemberId: string) => {
            if(teamMemberIdsToRetrain.includes(teamMemberId)){
                setTeamMembersToRetrain(teamMemberIdsToRetrain.filter(tmId => tmId !== teamMemberId))
            } else {
                setTeamMembersToRetrain([...teamMemberIdsToRetrain, teamMemberId])
            }
        }

        const traineesNotReady = trainees.filter(trainee => trainee.readyForAssessment === false)
        return (
            <>
                {traineesNotReady.length > 0 && (
                    <>
                        <Typography variant='h6'>
                            {`Retrain Trainees who are ${learningDocumentQuery.data?.type === LearningDocumentType.trainingDocumentRTM ? "Not Yet Competent" : "Not Ready For Assessment"}`}
                        </Typography>
                        <Typography>Clicking retrain will automatically create an additonal training session with the below team members: </Typography>
                        <TableContainer component={Paper} sx={{width:"20rem", marginBottom:"1rem"}}> 
                            <Table size='small'>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>
                                            Trainees
                                        </TableCell>
                                        <TableCell>
                                            Retrain?
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {traineesNotReady.map((trainee, index) => (
                                        <TableRow key={index}>
                                            <TableCell>
                                                <Typography>{`${trainee.teamMember.prefferedFirstName} ${trainee.teamMember.lastName}`}</Typography>
                                            </TableCell>
                                            <TableCell>
                                                <Checkbox
                                                    checked={teamMemberIdsToRetrain.includes(trainee.teamMemberId)}
                                                    onClick={() => handleCheckboxClick(trainee.teamMemberId)}
                                                />
                                            </TableCell>
                                        </TableRow>
                                    ))}

                                </TableBody>
                            </Table>

                        </TableContainer>
                        <LoadingButton 
                            variant='contained' 
                            loading={retrainMutation.isPending}
                            onClick={handleRetrainClick}
                        >
                            Retrain
                        </LoadingButton>
                    </>
                )}
            </>
        )
    }

    if(initialLoading) {
        return (
            <Stack>
                {/* For title */}
                <div style={{width:"100%", display:"flex", justifyContent:"center", height:"5rem"}}>
                    <Skeleton animation="wave" variant="text" sx={{width:"80%", display:"flex"}}/>

                </div>
                {/* For Body */}
                <div style={{width:"100%", padding:"1rem"}}>
                    <Skeleton animation="wave" variant="rectangular" height={500} />
                </div>  
            </Stack>
        )
    }

    const completedStatuses = [LearningDocumentResponseStatus.Competent, LearningDocumentResponseStatus.NotYetCompetent, LearningDocumentResponseStatus.Completed,]
    const editingLocked = documentResponseQuery.data ? completedStatuses.includes(documentResponseQuery.data.status) : false
    return (
    <>
        <Stack spacing={2} sx={{marginBottom:"4rem"}}>
            <Stack direction="row" sx={{width:"100%", padding:"1rem"}}>
                <img alt="AW LOGO" src ={assets.images.logo} style={{ width: "5rem", height: "5rem" }}/>
                <Typography variant='h5' sx={{width: "100%", paddingLeft:"1rem", paddingRight:"1rem", display:"flex", justifyContent:"center", alignItems:"center", textAlign:"center"}}>{`Training Outcome - ${learningDocumentQuery.data?.label}`}</Typography>
                <div style={{display:"flex", alignItems:"center", justifyContent:"center"}} >
                    <IconButton onClick={handleBackClick}>
                        <ArrowBackIcon fontSize='large'/>
                    </IconButton>
                </div>
            </Stack>
            <Paper sx={{padding: "1rem"}}>
                <Typography variant='h6'>Training Topics</Typography>
                <div style={{display:"flex", flexDirection: "column", flexWrap:"wrap", gap:"0.5rem", width:"80%", maxHeight:"10rem"}}>
                    {learningDocumentVersionQuery.data && learningDocumentVersionQuery.data.topics.map((topic, index) => (
                        <Typography key={index}>{`Topic ${index+1}: ${topic.label}`}</Typography>   
                    ))}
                </div>

                <Typography variant='h6'>Trainees</Typography>
                {learningDocumentQuery.data?.type === LearningDocumentType.trainingDocumentRTM ? (
                    <Typography>
                        {textContent.learningDocument.RTMtraineeSignOff}
                    </Typography>
                ) : (
                    <Typography>
                        {textContent.learningDocument.NTMtraineeSignOff}
                    </Typography>
                )}
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell>Name</TableCell>
                                <TableCell>Signature</TableCell>
                                <TableCell>Time Signed</TableCell>
                                <TableCell>{learningDocumentQuery.data?.type === LearningDocumentType.trainingDocumentRTM ? "Competent / Not Yet Competent" : "Ready For Assessment"}</TableCell>
                                <TableCell>Notes/Feedback</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {trainees.map((trainee, index) => (
                                <TraineeRow 
                                    key={index} 
                                    formik={formik} 
                                    trainee={trainee}
                                    setMessageItem={setMessageItem}
                                    editingLocked={editingLocked}
                                    type={learningDocumentQuery.data?.type ?? LearningDocumentType.trainingDocumentNTM}
                                />
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
               

                <Typography variant='h6' sx={{marginTop: "1rem"}}>Trainers</Typography>
                {learningDocumentQuery.data?.type === LearningDocumentType.trainingDocumentRTM ? (
                    <Typography>{textContent.learningDocument.RTMtrainerSignOff}</Typography>
                ) : (
                    <Typography>{textContent.learningDocument.NTMtrainerSignOff}</Typography>
                )}
                <TableContainer component={Paper}  sx={{ minWidth: "30rem", width: "30rem" }}>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell>Name</TableCell>
                                <TableCell>Signature</TableCell>
                                <TableCell>Time Signed</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {trainers.map((trainer, index) => (
                                <TrainerRow
                                    key={index}
                                    trainer={trainer}
                                    formik={formik}
                                    setMessageItem={setMessageItem}
                                    editingLocked={editingLocked}
                                />
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <Typography sx={{marginTop:"1rem"}}>{textContent.learningDocument.TrainersClause(learningDocumentQuery.data?.department?.label??"")}</Typography>
                <LoadingButton sx={{marginTop:"1rem"}} variant='contained' color='success' loading={submitMutation.isPending} onClick={handleSubmitClick}>Submit</LoadingButton>

                {documentResponseQuery.data?.status === LearningDocumentResponseStatus.Completed && (
                    <RetrainTrainees/>
                )}


            </Paper>
                        
        </Stack>
        <TrainingBottomNavigation/>
        <ErrorHandlingSnackbar messageItem={messageItem}/>
    </>

  )
}

export default AttendanceSheet