import { Box, IconButton, InputBase, List, ListItem, Paper, Typography } from '@mui/material'
import React, { useState } from 'react'
import { Draggable, Droppable } from 'react-beautiful-dnd'
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { NameDto } from 'src/dtos/NameDto.dto';
import { DeleteForever } from '@mui/icons-material';
import SearchIcon from '@mui/icons-material/Search';


type Props = {
    title: string;
    droppableId: string;
    roles: NameDto[];
    draggingRole?: NameDto | null;
    removeRole: (roleId: string) => void;
}
function RolesList({title, droppableId, roles, draggingRole, removeRole}: Props) {
    const isDropDisabled = draggingRole ? roles.some(role => role.id === draggingRole.id) : false;
    const [searchTerm, setSearchTerm] = useState("")

    return (
        <Box component={Paper} sx={{paddingRight:"4px", height:"calc(100vh - 13rem)"}} >
            <Typography variant='h6' sx={{height:"5rem", textAlign:"center", alignContent:"center"}}>
                {title}
            </Typography>
            <Paper
                component="form"
                sx={{p: '2px 1px', marginLeft:"1rem", display: 'flex', alignItems: 'center', width:"13rem" }}
            >
                <SearchIcon color="disabled"/>
                <InputBase
                    sx={{ml: 1, flex: 1}}
                    placeholder='Search'
                    value={searchTerm}
                    onChange={e => setSearchTerm(e.target.value)}
                />

            </Paper>
            <Droppable 
                droppableId={droppableId}
                type={"roles"}
                isDropDisabled={isDropDisabled}
            >
                {(provided) => (
                    <List ref={provided.innerRef} {...provided.droppableProps} sx={{marginTop:"0.5rem", height:"calc(100vh - 21rem)", overflowY:"scroll", width:"15rem"}}>
                        {roles.filter(role => role.label.toLowerCase().includes(searchTerm.toLowerCase())).map((role, index) => (
                            <Draggable
                                key={`${droppableId}_${role.id}`}
                                draggableId={`${droppableId}_${role.id}`}
                                index={index}
                            >
                                {(provided, snapshot) => (
                                    <ListItem
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        sx={snapshot.isDragging ? {background: 'rgb(235,235,235)' } : {}}
                                    >
                                        <div
                                            {...provided.dragHandleProps}
                                            style={{ display: "flex", alignItems: "center", marginRight: '8px', width:"100%" }}
                                        >
                                            <DragIndicatorIcon/>
                                            <Typography sx={{userSelect:"none"}}>{role.label}</Typography>
                                        </div>
                                        <IconButton onClick={() => removeRole(role.id)}>
                                            <DeleteForever/>
                                        </IconButton>
                                    </ListItem>
                                )}
                            </Draggable>
                        ))}
                        {provided.placeholder}
                    </List>
                )}
            </Droppable>
        </Box>
    )
}

export default RolesList