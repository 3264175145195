import { Avatar, IconButton, ListItem, ListItemAvatar, ListItemIcon, ListItemText, Typography } from '@mui/material'
import React, { useState } from 'react'
import { shortDateOptions } from 'src/config/constants'
import { EmailToInitials, EmailToNameString } from 'src/Utils/helperFunc'
import EditIcon from '@mui/icons-material/Edit';
import EditComment from './EditComment'
import { MessageItem } from 'src/components/errorHandlingSnackbar'
import { CommentDto } from 'src/dtos/Comment.dto';
import { AxiosResponse } from 'axios';

type Props = {
    comment: CommentDto
    setMessageItem: React.Dispatch<React.SetStateAction<MessageItem>>
    editCommentService: (commentId: string, comment: CommentDto) => Promise<AxiosResponse<CommentDto>>
    refetchComments: () => void;
    currentUserName: string;
}
function Comment({comment, setMessageItem, refetchComments, currentUserName, editCommentService}:Props) {
    const [editing, setEditing] = useState(false)
    const lastActivity = (comment.modifiedOn ?? comment.createdOn) 
    ? new Date(comment.modifiedOn ?? comment.createdOn!).toLocaleTimeString(undefined, shortDateOptions)
    : "";

    const twelveHoursAgo = new Date(Date.now() - 12 * 60 * 60 * 1000);
    const editable = (comment.createdOn ? new Date(comment.createdOn) > twelveHoursAgo : false) && currentUserName === comment.createdBy
    
    return (
    <>
        <ListItem>
            <ListItemAvatar>
                <Avatar>
                    <Typography variant='h5'>{EmailToInitials(comment.createdBy)}</Typography>
                </Avatar>
            </ListItemAvatar>
            {editing ? (
                <>
                    <EditComment
                        commentValues={comment}
                        editCommentService={editCommentService}
                        setMessageItem={setMessageItem}
                        refetchJournalComments={refetchComments}
                        stopEditing={() => setEditing(false)}

                    />
                </>
            ) : (
                <>
                    <ListItemText 
                        primary={comment.description} 
                        secondary={`${EmailToNameString(comment.createdBy)} | ${lastActivity} ${comment.modifiedBy ? "EDITED" : ""}`}
                    />
                    {editable && (
                        <ListItemIcon>
                            <IconButton onClick={() => setEditing(true)}>
                                <EditIcon/>
                            </IconButton>
                        </ListItemIcon>
                    )}
                </>
            )}        
            
        </ListItem>
    </>
  )
}

export default Comment