import { NameNumberDto } from "src/dtos/NameDto.dto";
import { InspectedComponentStatus } from "./ChecklistResponse.dto";

export interface AttractionInspectionsStatusDto
{
    attractionLabel: string
    inspections: AttractionInspectionDto[]
}

export enum AttractionInspectionStatus {
    Passed,
    Failed,
    Inprogress,
    Expired,
    Unanswered,
    Outstanding
}

export const AttractionInspectionStatusString: string[] = [
    "Passed",
    "Failed",
    "In Progress",
    "Expired",
    "Unanswered",
    "Outstanding"
]

export interface AttractionInspectionDto {
    inspectionChecklistId: string
    inspectionChecklistLabel: string
    publishedVersionId: string
    expiry?: Date
    status?: AttractionInspectionStatus
    allComponents: ResponseComponentDto[]
    inspectionResponses: InspectionResponseDto[]
    permittedToInspect: boolean
}

export interface InspectionResponseDto {
    inspectionChecklistResponseId?: string
    startedBy?: string
    startedTime?: Date
    inspectionStatus?: AttractionInspectionStatus
    completedBy?: string
    completedTime?: Date
    expiry?: Date
    components: ResponseComponentDto[]
}

export interface ResponseComponentDto {
    component?: NameNumberDto
    componentStatus?: InspectedComponentStatus
    expiry?: Date
}