import { TableRow, TableCell } from '@mui/material'
import { useMutation } from '@tanstack/react-query'
import React from 'react'
import CustomSignaturePad from 'src/components/SignaturePad/SignaturePad'
import { dateTimeOptions } from 'src/config/constants'
import { TrainingMemoAcceptanceDto } from 'src/dtos/Training/TrainingMemo.dto'
import TrainingMemoService from 'src/Services/Training/TrainingMemoService'
type Props = {
    id: string;
    acceptance: TrainingMemoAcceptanceDto
    updateAcceptance: (acceptance: TrainingMemoAcceptanceDto) => void;
}
function AcceptanceRow({id, acceptance, updateAcceptance}: Props) {

    const updateAcceptanceMutation = useMutation({
        mutationFn: async (newAcceptance: TrainingMemoAcceptanceDto) => {
            return await TrainingMemoService.UpdateAcceptance(id, newAcceptance)
        }
    })

    const handleSignatureChange = (signature: string | undefined, teamMemberId: string) => {
        const newAcceptance = signature ?
            {...acceptance, signature: signature, timeSigned: new Date()}
            : 
            {...acceptance, signature: signature, timeSigned: undefined}
        updateAcceptance(newAcceptance)
        updateAcceptanceMutation.mutate(newAcceptance)
    }
    return (
    <TableRow>
        <TableCell>
            {acceptance.teamMember.label}
        </TableCell>
        <TableCell>
            {updateAcceptanceMutation.isPending ? (
                <div className="loader"/>
            ) : (
                <CustomSignaturePad
                    person={{name: acceptance.teamMember.label, signature: acceptance.signature}}
                    setSignature={(signature) => handleSignatureChange(signature, acceptance.teamMember.id)}
                />
            )}
        </TableCell>
        <TableCell>
            {acceptance.timeSigned && new Date(acceptance.timeSigned).toLocaleDateString(undefined, dateTimeOptions)}
        </TableCell>
    </TableRow>
  )
}

export default AcceptanceRow