import React, { useEffect, useState } from 'react'
import Comment from './Components/Comment'
import { Avatar, LinearProgress, List, ListItem, ListItemAvatar, Pagination, Skeleton } from '@mui/material'
import NewComment from './Components/NewComment'
import { MessageItem } from 'src/components/errorHandlingSnackbar'
import { useQuery } from '@tanstack/react-query'
import { ListParameters } from 'src/Services/ListParameters'
import { GetCurrentUsername } from 'src/Utils/authUtils'
import { ListResponseDto } from 'src/dtos/ListResponse.dto'
import { CommentDto } from 'src/dtos/Comment.dto'
import { AxiosResponse } from 'axios'

type Props = {
    queryKey: string 
    addCommentService: (comment: CommentDto) => Promise<AxiosResponse<CommentDto>>
    editCommentService: (commentId: string, comment: CommentDto) => Promise<AxiosResponse<CommentDto>>
    getCommentsService: (listParams: ListParameters) => Promise<ListResponseDto<CommentDto>>
    setMessageItem: React.Dispatch<React.SetStateAction<MessageItem>>
}
function Comments({queryKey, addCommentService, editCommentService, getCommentsService, setMessageItem}:Props) {
    const initialListParams: ListParameters = {
        page: 1,
        pageSize: 10,
    }
    const [listParams, setListParams] = useState<ListParameters>(initialListParams);
    const currentUsername = GetCurrentUsername()
    //#region queries
    const commentsQuery = useQuery({
        queryKey: ["comments", queryKey, listParams],
        queryFn: async () => {
            return await getCommentsService(listParams)
        },
        placeholderData: (prev) => prev //used to keep previous data when fetching
    })
    
    useEffect(() => {
        if(commentsQuery.isError){
            setMessageItem({error: commentsQuery.error})
        }
    }, [setMessageItem, commentsQuery.error, commentsQuery.isError])
    //#endregion

    const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
        setListParams({...listParams, page:value}); 
    };

    const totalPages = Math.ceil((commentsQuery.data?.totalRecords??0) / listParams.pageSize)
    return (
        <>
            <NewComment
                addCommentService={addCommentService}
                setMessageItem={setMessageItem}
                refetchComments={commentsQuery.refetch}
            />
            {commentsQuery.isFetching ? (
                <LinearProgress/>
            ) : (
                <div style={{height:"4px"}}/> //spacer so list doesnt move
            )}
            <List>
                {(commentsQuery.data == null || commentsQuery.data.data.length === 0) && commentsQuery.isLoading ? (
                    <>
                        {Array.from({length: listParams.pageSize}).map((_, index) => (
                            <ListItem key={index}>
                                <ListItemAvatar>
                                    <Skeleton variant='circular'>
                                        <Avatar>
                                            
                                        </Avatar>
                                    </Skeleton>
                                </ListItemAvatar>
                                <Skeleton variant='text' width={"10rem"}/>
                            </ListItem>
                        ))}
                    </>
                ) : commentsQuery.data && commentsQuery.data.data.map((comment) => (
                    <Comment
                        key={comment.id}
                        comment={comment}
                        editCommentService={editCommentService}
                        setMessageItem={setMessageItem}
                        refetchComments={commentsQuery.refetch}
                        currentUserName={currentUsername}
                    />
                ))}
                {commentsQuery.data && commentsQuery.data.totalRecords === 0 && (
                    <ListItem>No Comments yet!</ListItem>
                )}
            </List>
            {totalPages > 1 && (
                <Pagination
                    count={totalPages}
                    page={listParams.page}
                    onChange={handlePageChange}
                    sx={{marginTop:"0.5rem"}}
                />
            )}
        </>
    );
}

export default Comments